<div id="modal-content-wrapper">

  <header id="modal-header">
    <div id="modal-title">{{ modalData.title }}</div>
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <section id="modal-body">
    <p>{{ modalData.description }}</p>
  </section>
  <footer id="modal-footer">
    <div *ngIf="modalData.hasCancelButton" class="button-width">
      <gdp-button [buttonText]="modalData.cancelButtonText"
                  [buttonType]="modalData.cancelButtonType"
                  [buttonCallback]="modalData.cancelButtonCallback">
      </gdp-button>
    </div>
    <div class="button-width">
      <gdp-button [buttonText]="modalData.okButtonText"
                  [buttonType]="modalData.okButtonType"
                  [buttonCallback]="modalData.okButtonCallback">
      </gdp-button>
    </div>
  </footer>
</div>
