<div class="begin-page">
<h1>Automated FEED</h1>
<p class="half-width">The Front End Engineering and Design (FEED) primary scopes of work within the FEED process 
    have been categorized in the optimization steps shown below. As each step is completed, the 
    optimized outputs can be used as inputs for subsequent steps in the process. At the completion of each step, 
    solution candidates are selected to be used in the subsequent steps. The scores of these selected 
    solution candidates will be aggregated up to provide a complete set of project scores which are indicated 
    on the Key Metrics page.
</p>
</div>

<div class="container-fluid grey-container">
<div class="row">
    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let process of tileInputs">
    <app-tile [tileInput]="process"
              [targetPath]="getApplicationLink(process.name)"></app-tile>
    </div>
</div>
</div>
  