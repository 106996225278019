<div class="container">
    <div class="title">{{ title }}</div>
    <table class="slider-table">
        <tr>
            <td>{{ minValue | number: '1.0-0' }}</td>
            <td class="custom-slider" [ngClass]="{ 'no-high': lowValue === minValue, 'no-low': highValue === maxValue }">
                <ngx-slider [(value)]="lowValue" [(highValue)]="highValue" 
                    (userChangeEnd)="sliderChange($event)" [options]="{'floor': minValue, 'ceil': maxValue}">
                </ngx-slider>
            </td>
            <td>{{ maxValue | number: '1.0-0' }}</td>
        </tr>
    </table>
</div>