import { Component, OnInit } from '@angular/core';

import { ProjectService } from '../../../services/project.service';
import { Project } from '../../../models/project';
import { TileInput } from '../../../models/tile.input';

@Component({
  selector: 'app-list-projects-page',
  templateUrl: './list-projects-page.component.html',
  styleUrls: ['./list-projects-page.component.scss'],
})
export class ListProjectsPageComponent implements OnInit {
  projects: TileInput[] = [];

  selectedProject: any;

  constructor(private projectService: ProjectService) {
    this.projects[0] = new TileInput();
    this.projects[0].name = 'NEW PROJECT';
    this.projects[0].description = '';
  }

  ngOnInit(): void {
    this.getProjects();
  }

  getProjects(): void {
    this.projectService.getProjects().subscribe({
      next: (response: Project[]) => {
        if (!response) { // response is empty
          return
        }
        for (let i = 0; i < response.length; i++) {
          this.projects[i + 1] = new TileInput();
          this.projects[i + 1].name = response[i].ProjectName.toUpperCase();
          this.projects[i + 1].description = {
            'text': response[i].ProjectDetails,
            'startDate': response[i].StartDate,
            'endDate': response[i].EndDate,
            'createTime': response[i].CreateTimeStamp,
          }
          this.projects[i + 1].id = response[i].ProjectId;
        }
      },
      error: (err) => { }
    });
  }

}
