<div class="begin-page">
  <h1>Create New Project</h1>
  <p class="half-width">To start this process, output from the layout optimization model, and the list of detailed activities and resources, 
    AKA Recipes, will be imported to the Scheduling application. The Recipes are generated automatically based on the 
    configuration of objects on the layout, and some resource constraints.
  </p>
</div>

<app-toaster-container></app-toaster-container>

<div class="container-fluid grey-container">
  <h3>PROJECT DETAILS</h3>
  <hr class="green">

<form id="projectForm" [formGroup]="projectForm" (ngSubmit)="submitForm(projectForm.value)">
  <table class="create-project">
    <tbody>
      <tr class="d-flex">

        <td class="col-4">
          <label class="gdp-disabled no-bottom" for="name">Project Name</label>
          <span class="required-red">*</span>
          <mat-form-field floatLabel="never">
            <input matInput id="name" type="text" formControlName="name" maxlength="50"
              placeholder="Please type here..."  autocomplete="off" />
          </mat-form-field>
        </td>

        <td class="col-2">
          <label class="gdp-disabled no-bottom" for="country">Country</label>
          <span class="required-red">*</span>
          <mat-form-field class="gdp-disabled">
            <mat-select id="country" name="country" formControlName="country"
              [disableOptionCentering]="true" panelClass="mat-select-dropdown-panel">
              <mat-option [value]="''" [disabled]="true">Please select...</mat-option>
              <mat-option *ngFor="let country of location.countries"
                [value]="country">{{ country }}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>

        <td class="col-2">
          <label class="gdp-disabled no-bottom" for="region">Region</label>
          <span class="required-red">*</span>
          <mat-form-field class="gdp-disabled">
            <mat-select id="region" name="region" formControlName="region"
              [disableOptionCentering]="true" panelClass="mat-select-dropdown-panel">
              <mat-option [value]="''" [disabled]="true">Please select...</mat-option>
              <mat-option *ngFor="let region of location.regions"
                [value]="region">{{ region }}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>

        <td class="col-2">
          <label class="gdp-disabled no-bottom" for="state">Province/State</label>
          <span class="required-red">*</span>
          <mat-form-field class="gdp-disabled">
            <mat-select id="state" name="state" formControlName="state"
              [disableOptionCentering]="true" panelClass="mat-select-dropdown-panel">
              <mat-option [value]="''" [disabled]="true">Please select...</mat-option>
              <mat-option *ngFor="let state of location.states"
                [value]="state">{{ state }}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>

        <td class="col-2">
          <label class="gdp-disabled no-bottom" for="city">City</label>
          <span class="required-red">*</span>
          <mat-form-field class="gdp-disabled">
            <mat-select id="state" name="city" formControlName="city"
              [disableOptionCentering]="true" panelClass="mat-select-dropdown-panel">
              <mat-option [value]="''" [disabled]="true">Please select...</mat-option>
              <mat-option *ngFor="let city of location.cities"
                [value]="city">{{ city }}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>


      <tr class="d-flex">
        <td class="col-3">
          <label class="gdp-disabled no-bottom" for="startDate">Start Date</label>
          <span class="required-red">*</span>
          <mat-form-field appearance="fill">
            <input matInput id="startDate" name="startDate" formControlName="startDate" [max]="projectForm.value.endDate"
              [matDatepicker]="pickerStart" autocomplete="off" placeholder="Please select..."
              (click)="pickerStart.open()" (keydown)="false">
            <mat-datepicker-toggle matSuffix [for]="pickerStart">
              <mat-icon matDatepickerToggleIcon>arrow_drop_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #pickerStart></mat-datepicker>
          </mat-form-field>
        </td>

        <td class="col-3">
          <label class="gdp-disabled no-bottom" for="endDate">End Date</label>
          <span class="required-red">*</span>
          <mat-form-field appearance="fill">
            <input matInput id="endDate" name="endDate" formControlName="endDate" [min]="projectForm.value.startDate"
              [matDatepicker]="pickerEnd" autocomplete="off" placeholder="Please select..."
              (click)="pickerEnd.open()" (keydown)="false"
              [ngClass]="{'gdp-disabled': projectForm.value.startDate === ''}">
            <mat-datepicker-toggle matSuffix [for]="pickerEnd">
              <mat-icon matDatepickerToggleIcon>arrow_drop_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #pickerEnd></mat-datepicker>
          </mat-form-field>
        </td>

        <td class="col-6">
          <label class="gdp-disabled no-bottom" for="description">Project Description</label>
          <mat-form-field floatLabel="never" class="no-underline">
            <textarea matInput id="description" name="description" formControlName="description"
              placeholder="Please type here..." autocomplete="off"
              rows="5" [maxlength]="descriptionLengthMax">
            </textarea>
            <span class="remaning gdp-disabled">{{ descriptionLength | async }}/{{ descriptionLengthMax }}</span>  
          </mat-form-field>
        </td>
      </tr>
    </tbody>
  </table>
</form>

<p></p>
<h3>APPLICATION</h3>
<hr class="green">

<div class="row">
  <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let app of tileInputs">
    <app-tile [tileInput]="app" 
              [targetPath]="''"
              [tileType]="'application'">
    </app-tile>
  </div>
</div>
</div>

<gdp-footer-buttons
  [isCancelButtonVisible]="true"
  [cancelButtonText]="'CANCEL'"
  [cancelButtonType]=""
  [cancelButtonCallback]="goBackToProjectList"
  [isSaveButtonVisible]="true"
  [saveButtonText]="'CREATE PROJECT'"
  [saveButtonType]="projectForm.valid ? 'new' : 'disabled'"
  [saveButtonCallback]="saveProjectData">
</gdp-footer-buttons>

