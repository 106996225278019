
<div *ngIf="processId === 'null' || state === 1">
  <pfd-files-upload></pfd-files-upload>
</div>

<div *ngIf="processId !== 'null'">
  <div *ngIf="state === 2">
    <pfd-data-integrity-check></pfd-data-integrity-check>
  </div>
  <div *ngIf="state === 3">
    <pfd-diagram-list></pfd-diagram-list>
  </div>
  <div *ngIf="state === 4">
    <pfd-diagram-list></pfd-diagram-list>
    <!--<pfd-diagram-view></pfd-diagram-view>-->
  </div>
</div>
