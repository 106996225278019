<div class="begin-page">
  <h1>{{ projectName }}</h1>
  <h2>{{ pfdName }}</h2>
  <p>
    This is the optimal layout for the present configuration. Click on each
    equipment to see the attributes. Navigate on to the left panel through Base,
    Summer and Winter case.
  </p>
</div>

<app-toaster-container></app-toaster-container>
<div class="container-fluid grey-container column-stretch">
  <div class="column-left">
    <h3>PFD DRAWING</h3>
    <hr class="green" />
    <div id="diagram" class="diagram-viewer" *ngIf="diagram" [innerHTML]="diagram"  (click)="getEquipmentTypeAttributes($event)"></div>
  </div>
  <div class="column-right equipmentArea">
    <h3>EQUIPMENT TYPE ATTRIBUTES</h3>

    <hr class="green" />

    <div class="attributes-content">
      <div class="row equipmentTypeSelector" [style.visibility]="attributes ? 'visible' : 'hidden'">
        <div class="col-sm-4 clickable"
             (click)="changeCase('base')"
             [ngClass]="{ blueBackground: caseType === 'base' }">
          BASE CASE
        </div>
        <div
          class="col-sm-4 clickable"
          (click)="changeCase('summer')"
          [ngClass]="{ blueBackground: caseType === 'summer' }"
        >
          SUMMER CASE
        </div>
        <div
          class="col-sm-4 clickable"
          (click)="changeCase('winter')"
          [ngClass]="{ blueBackground: caseType === 'winter' }"
        >
          WINTER CASE
        </div>
      </div>

      <div class="table-container table-wrapper-scroll-y my-custom-scrollbar">
        <table class="table table-striped" *ngIf="attributes">
          <thead>
            <tr class="row">
              <th class="col-sm-6">PROPERTY</th>
              <th class="col-sm-6">VALUE</th>
            </tr>
          </thead>
          <tbody>
            <tr class="row" *ngFor="let attribute of attributes">
              <td class="col-sm-6">{{ attribute.Property }}</td>
              <td class="col-sm-6">{{ attribute.Value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<gdp-bottom-navigation
  [maxStages]="maxPfdStages"
  [currentStage]="maxPfdStages"
  [isBackButtonVisible]="true"
  [backButtonText]="'BACK TO GENERATE'"
  [backButtonType]="'new'"
  [backButtonCallback]="goBackToPfdDrawingList"
  [isCancelButtonVisible]="true"
  [cancelButtonText]="'CANCEL'"
  [cancelButtonType]="'disabled'"
  [cancelButtonCallback]="goBackToGeneralPfdList"
  [isSaveButtonVisible]="true"
  [saveButtonText]="'SAVE'"
  [saveButtonType]="'enabled'"
  [saveButtonCallback]="savePfdDiagramData"
  [isNextButtonVisible]="true"
  [nextButtonText]="'APPROVE'"
  [nextButtonType]="'enabled'"
  [nextButtonCallback]="setPfdDiagramStatusApproved"
>
</gdp-bottom-navigation>
