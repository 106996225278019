<table class="map-container">
  <tbody>
    <tr class="d-flex">
      <td rowspan="2" class="col-4"></td>
      <td class="col-8">
        <div class="controls">
          <input id="search" type="text" placeholder="Search location" />
          <button (click)="switchLayer('streets')">Streets</button>
          <button (click)="switchLayer('satellite')">Satellite</button>
          <button (click)="deleteAllDrawings()">Delete All</button>
          <button (click)="captureMap()">Capture Map</button>
        </div>
        <div class="search-input-container">
          <label class="gdp-disabled no-bottom" for="name">Search Location</label>
          <mat-form-field floatLabel="never">
            <input matInput id="location-input" type="text" formControlName="name"
              placeholder="Enter Location..."  autocomplete="off" />
          </mat-form-field>
          <button mat-raised-button>CONFIRM TO CONTINUE</button>
      </div>
      </td>
    </tr>
    <tr class="d-flex" >
      <td class="col-4">
        <mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <u>PREFFERRED EV CHARGING AREA</u>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="add-preferred-area-container">
            <div>Add Preferred Area</div>
            <div class="add-preferred-area" (click)="enablePolygonDraw()"><mat-icon matDatepickerToggleIcon>add_box</mat-icon></div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <u>EXISTING SCE LIVE FRONT SWITCH</u>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="add-preferred-area-container">
            <div>Add SCE Live Front Switch Location</div>
            <div class="add-preferred-area" (click)="enablePolygonDraw()"><mat-icon matDatepickerToggleIcon>add_box</mat-icon></div>
          </div>
        </mat-expansion-panel>
      </td>
      <td class="col-8">
        <div id="map" class="map-container"></div>
      </td>
    </tr>
  </tbody>
</table>

<link rel="stylesheet" href="https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.4.3/mapbox-gl-draw.css">
<script src="https://unpkg.com/@turf/turf@6/turf.min.js"></script>
<script src="https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.4.3/mapbox-gl-draw.js"></script>
<script src='https://unpkg.com/maplibre-gl@4.3.2/dist/maplibre-gl.js'></script>