/**
 * Displays global Key Metrics on interactive spider/radar chart; also 
 * shows the values of a selected dataset in a details table.
 * A dataset is either the 'Client desired outcome' or one 'Optimization scenario' (represented currently by a PFD process).
 * The chart is interactive in the sense that the user can control which datasets to display/disable and show it's details.
 * 
 * To display the chart the Chart.js lib was used: https://www.chartjs.org/docs/latest/.
 */
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Chart } from 'chart.js';

import { GlobalKPI } from '../../../models/project';
import { ProjectService } from '../../../services/project.service';
import { ToasterService } from 'src/app/services/toaster.service';


@Component({
  selector: 'app-key-metrics',
  templateUrl: './key-metrics.component.html',
  styleUrls: ['./key-metrics.component.scss']
})
export class KeyMetricsComponent implements OnInit, OnDestroy {
  private projectId: string;
  private sub: any;
  private radarChart: Chart; // spider chart instance

  public chartConfig: any;
  public chartColors: string[];

  public globalKeyMetricNames: string[];
  public globalKeyMetricValues: { label: string, data: number[] }[];

  // tells which dataset ('client desired' or an 'optimization scenario') is selected on the chart
  // selectedIndex === -1 ==> all datasets were disabled 
  public selectedIndex: number;

  // indexing array to tell which dataset is disabled and not shown on the chart
  public deletedIndexes: boolean[];


  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private toaster: ToasterService) {
    this.selectedIndex = 0;
  }


  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.projectId = params['projectid'];
    });
    this.getGlobalKeyMetricsAndChartOptions();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.toaster) {
      this.toaster.unsubscribe();
    }
  }

  /**
   * Retrieves the global key metric data from DB, and uses them to define the datasets that will be shown on the spider chart.
   */
  getGlobalKeyMetricsAndChartOptions() {
    this.projectService.getProjectKeyMetrics(this.projectId).then(
      (response) => {

        // First, find the 'Client desired outcome' metrics.
        let clientDesiredOutcome = response.find(x =>
        (x['OptimizationId'] === '00000000-0000-0000-0000-000000000000'  // hardcoded from backend
          && x['OptimizationName'].toLocaleLowerCase() === 'client desired outcome'));

        // get key metric names list
        this.globalKeyMetricNames = this.getKPInamesInOrder(clientDesiredOutcome['KPIValues']);

        this.globalKeyMetricValues = [];
        this.globalKeyMetricValues.push({ // first item willbe the client desired values
          label: clientDesiredOutcome['OptimizationName'],
          data: this.getKPIvaluesInNamesOrder(this.globalKeyMetricNames, clientDesiredOutcome['KPIValues'])
        });


        // Second, loop through optimization scenarios (PFD process) metrics list.
        for (let i = 0; i < response.length; i++) {
          let optScen = response[i];
          if (optScen['OptimizationName'] && optScen['OptimizationName'].toLocaleLowerCase() !== 'client desired outcome') {
            this.globalKeyMetricValues.push({
              label: optScen['OptimizationName'],
              data: this.getKPIvaluesInNamesOrder(this.globalKeyMetricNames, optScen['KPIValues'])
            });
          }
        }

        this.globalKeyMetricNames = this.globalKeyMetricNames.map(x => x.toUpperCase());
        this.deletedIndexes = Array(this.globalKeyMetricValues.length).fill(false);

        // define the key metrics chart options using the data above
        this.chartColors = this.getChartColors(this.globalKeyMetricValues.length);
        this.defineChartConfig();
      }
    ).catch((err) => { });
  }


  /**
   * Callback function for the legend of the spider chart.
   * @param event the click event
   * @param index the index of the dataset to be selected
   */
  legendClickCallback = (event: any, index: number) => {
    if (index === this.selectedIndex || event.srcElement.localName === 'mat-icon' ||
      this.deletedIndexes[index]) {
      // do not allow click if dataset is already selected, or the 'close' icon is clicked, or dataset was deleted
      return
    }
    this.selectedIndex = index;
    this.legendHoverCallback(event, -1, 0); // index -1 means that all datasets will be shown
  }


  /**
   * Callback function for legend hover event; alters the temporary visibility of the datasets on the spider chart.
   * @param event mouse hover event
   * @param index index of the dataset
   * @param hide switch for hiding/showing the dataset. if true, then hide
   */
  legendHoverCallback = (event: any, index: number, hide: number) => {
    if (this.deletedIndexes[index]) { // do not alter dataset if it was deleted
      return
    }
    // get current chart
    let charts = Chart.instances;
    let k = Object.keys(charts);
    this.radarChart = charts[k[0]]; // we know it is the first one, since there is only one chart

    for (let i = 0; i < this.radarChart.data.datasets.length; i++) {
      if (this.deletedIndexes[i]) { // if it was deleted, leave it alone
        continue
      }
      let meta = this.radarChart.getDatasetMeta(i);
      if (i !== index) {
        meta.hidden = !!hide; // hide or show dataset depending on the variable
      }
    }
    this.radarChart.update();
  }


  /**
   * Callback function for the legend close event; alters the permanent visibility of the datasets on the spider chart.
   * If dataset is visible, removes it from chart, and vice-versa.
   * @param event mouse click event
   * @param index index of the dataset to be hidden (deleted from the chart)
   */
  closeClickCallback = (event: any, index: number) => {
    if (event.srcElement.localName !== 'mat-icon') {
      return
    }
    if (this.selectedIndex === -1 && this.deletedIndexes[index]) { // none of the datasets is selected
      // we are adding the dataset back now
      this.selectedIndex = index;
    } else {
      if (index === this.selectedIndex) {
        this.selectedIndex = -1; // we are removing the selected dataset
      }
    }
    // get current chart
    let charts = Chart.instances;
    let k = Object.keys(charts)
    this.radarChart = charts[k[0]]; // we know it is the first one, since there is only one chart

    this.deletedIndexes[index] = !this.deletedIndexes[index];
    for (let i = 0; i < this.radarChart.data.datasets.length; i++) {
      let meta = this.radarChart.getDatasetMeta(i);
      meta.hidden = this.deletedIndexes[i]; // hide/show based on the deletedIndexes
    }
    this.radarChart.update();
  }


  /**
   * Defines the spider chart configuration object, which determines the styling and appearance.
   */
  defineChartConfig() {
    this.chartConfig = {
      type: 'radar',
      labels: this.globalKeyMetricNames,
      datasets: this.getChartDatasets(),
      colors: this.chartColors,
      options: {
        startAngle: 30,
        scale: {
          gridLines: { // these are the circle/polygon gridlines
            circular: true,
            color: '#525460',
            lineWidth: 1,
            zeroLineColor: 'red'
          },
          angleLines: { // these are the radius gridlines
            color: '#525460',
            lineWidth: 1,
          },
          pointLabels: {
            fontColor: '#01f1ff',
            fontSize: 11,
            fontFamily: 'Saira'
          },
          ticks: {
            beginAtZero: false,
            display: false,
            maxTicksLimit: 11,
            min: 0,
            max: 100,
            stepSize: 10,
          },

        }, // end of scale
        legend: {
          display: false // hide legend, since buttons are used to control the chart
        },
        tooltips: {
          // Disable the on-canvas tooltip
          enabled: false,
          callbacks: {
            title: function (tooltipItem, data) {
              return data['labels'][tooltipItem[0]['index']];
            },
          },
          custom: function (tooltipModel) {
            // Source: https://www.chartjs.org/docs/latest/configuration/tooltip.html#external-custom-tooltips
            var tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = '<table></table>';
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = '0';
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            tooltipEl.classList.add('above');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              var titleLines = tooltipModel.title || [];
              var bodyLines = tooltipModel.body.map(getBody);
              var innerHtml = '<thead>';
              titleLines.forEach(function (title) {
                innerHtml += '<tr><th>' + title + '</th></tr>';
              });
              innerHtml += '</thead><tbody>';

              bodyLines.forEach(function (body, i) {
                var colors = tooltipModel.labelColors[i];
                var style = 'background:' + colors.backgroundColor;
                style += '; border-color:' + colors.borderColor;
                style += '; border-width: 2px';
                var span = '<span style="' + style + '"></span>';
                innerHtml += '<tr><td>' + span + body + '</td></tr>';
              });
              innerHtml += '</tbody>';

              var tableRoot = tooltipEl.querySelector('table');
              tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.classList.add('chart-tooltip');
            tooltipEl.style.opacity = '0.8';
            tooltipEl.style.backgroundColor = '#000';
            tooltipEl.style.borderRadius = '10px';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = -position.left + window.pageXOffset + tooltipModel.caretX - 80 + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 50 + 'px';
            //tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          }
        } // end of tooltips

      } // end of options
    }
  }


  /**
   * Defines the datasets and their options, that will be displayed on the spider chart.
   */
  getChartDatasets() {
    let Color = Chart.helpers.color;
    let chartData = [];
    for (let i = 0; i < this.globalKeyMetricValues.length; i++) {
      chartData.push({
        data: this.globalKeyMetricValues[i].data,
        label: this.globalKeyMetricValues[i].label,
        order: -i,
        borderColor: Color(this.chartColors[i]).alpha(0.9).rgbString(),
        backgroundColor: Color(this.chartColors[i]).alpha(0.5).rgbString(),
        borderWidth: 2,
        pointRadius: 2,
        pointBorderWidth: 2,
        pointBorderColor: this.chartColors[i],
        pointBackgroundColor: this.chartColors[i],
        pointHoverRadius: 8,
        pointHoverBorderWidth: 3,
        pointHoverBorderColor: 'white',
        pointHoverBackgroundColor: this.chartColors[i],
        hoverBackgroundColor: 'black',
      });
    }
    return chartData
  }


  /**
   * Defines the colors that will represent the distinct datasets on the spider chart.
   * Currently a hardcoded list contains 20 colors.
   * TODO: generate a predefined number of non-random colors.
   * @param num_colors how many colors to retrieve
   */
  getChartColors(num_colors: number) {
    // define random distinct colors, where first 3 are the ones from the UX design
    // source: https://apexcharts.com/docs/options/theme/
    let COLORS = [
      '#016aff', '#188659', '#fd4654', '#FEB019', '#A5978B',
      '#775DD0', '#03A9F4', '#4CAF50', '#D4526E', '#546E7A',
      '#A5978B', '#81D4FA', '#C7F464', '#FD6A6A', '#A300D6',
      '#8D5B4C', '#E2C044', '#5653FE', '#C4BBAF', '#F86624'
    ];
    return COLORS.slice(0, num_colors);
  }


  /**
   * Retrieves the key metric names from the input list in the order defined in the GlobalKPI enum.
   * This ensures, that metrics are always displayed in the same order, and not confusing for the user.
   * @param kpiValues object that contains KPI types and values
   */
  getKPInamesInOrder(kpiValues: { 'KPIType': string, 'KPIValue': number }[]): string[] {
    let s: string[] = [];
    // get KPI keys and sort them alphabetically
    let kpiKeys = Object.keys(GlobalKPI).sort();
    kpiKeys.forEach((kpiKey) => {
      let kpiName = GlobalKPI[kpiKey];
      let x = kpiValues.find(x => x['KPIType'].toLowerCase() === kpiName.toLowerCase());
      s.push(x['KPIType']);
    });
    return s
  }


  /**
   * Retrieves the key metric values from an object, in the order predefined by a list of key metric names.
   * This ensures that the order of the metric values is not confused.
   * @param orderedNames key metric name list
   * @param kpiValues object containing metric types and values
   */
  getKPIvaluesInNamesOrder(orderedNames: string[],
    kpiValues: { 'KPIType': string, 'KPIValue': number }[]): number[] {
    let v: number[] = [];
    orderedNames.forEach((kpiKey) => {
      let x = kpiValues.find(x => x['KPIType'].toLowerCase() === kpiKey.toLowerCase());
      v.push(x['KPIValue']);
    });
    return v
  }

}
