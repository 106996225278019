import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { CacheService } from '../../../services/cache.service';
import { ProjectService } from '../../../services/project.service';
import { ProcessService } from '../../../services/process.service';
import { Project } from '../../../models/project';
import { TileInput } from '../../../models/tile.input';

@Component({
  selector: 'app-project-page',
  templateUrl: './project-page.component.html',
  styleUrls: ['./project-page.component.scss']
})
export class ProjectPageComponent implements OnInit, OnDestroy {
  tileInputs: TileInput[];
  projectId : string;
  project: Project;
  subscription: any;

  constructor(
    private cacheService: CacheService,
    private projectService: ProjectService,
    private processService: ProcessService, 
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.subscription = this.route.params.subscribe(params => {
      this.projectId = params['projectid'];
    });

    this.getAPPS();
    this.getProjectById(this.projectId);
  }
  
  private getAPPS(): void {
    this.processService.getAPPS().subscribe(processes => {
      this.tileInputs = processes;
      this.tileInputs.map(t => t.name = t.name.toLocaleUpperCase());
    });
  }

  private getProjectById(id: string): void {
    this.projectService.getProjectById(id).subscribe({
      next: (response: Project) => {
        this.project = response;
        this.cacheService.saveToCache(response.ProjectId, response.ProjectName);
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
