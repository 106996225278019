import { Component, OnInit } from '@angular/core';

import { TileInput } from '../../../models/tile.input';

@Component({
  selector: 'app-value-lever',
  templateUrl: './value-lever.component.html',
  styleUrls: ['./value-lever.component.scss', '../industry.styles.scss'],
})
export class ValueLeverComponent implements OnInit {
  valueLeverData: TileInput[];

  constructor() {}

  ngOnInit(): void {
    this.getValueLeverData();
  }

  /**
   * Get the Value Lever data from the JSON file and convert to a list of TileInput.
   */
  getValueLeverData() {
    let jsonContent = require('../../../../assets/resources/valuelever.json');
    this.valueLeverData = [];
    jsonContent['valuelever'].map((valueleverCategory: any) => {
      let tileInput = new TileInput();
      tileInput.name = valueleverCategory['name'].toUpperCase();
      tileInput.description = {
        imgPath: 'assets/imgs/valuelever/' + valueleverCategory['imageName'],
      };
      this.valueLeverData.push(tileInput);
    });
  }
}
