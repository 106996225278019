import { APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from 'ng2-charts';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';

import {
  MsalModule,
  MsalInterceptor,
  MsalRedirectComponent,
  MsalGuard,
  MsalGuardConfiguration,
  MsalService,
  MSAL_INSTANCE,
  MsalInterceptorConfiguration,
  MsalBroadcastService,
  MSAL_INTERCEPTOR_CONFIG,
  MSAL_GUARD_CONFIG
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  LogLevel,
  IPublicClientApplication,
  PublicClientApplication
} from '@azure/msal-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxUploaderModule } from 'ngx-uploader';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AppConfig } from './../app.config';
import { AfeedProcessListPageComponent } from './components/AFEED/afeed-process-list/afeed-process-list-page.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { BottomNavigationComponent } from './components/ReusableComponents/bottom-navigation/bottom-navigation.component';
import { CreatePfdComponent } from './components/AFEED/PFD/create-pfd/create-pfd.component';
import { CreateProjectComponent } from './components/Project/create-project/create-project.component';
import { DataIntegrityCheckComponent } from './components/AFEED/PFD/create-pfd/data-integrity-check/data-integrity-check.component';
import { FilesUploadComponent } from './components/AFEED/PFD/create-pfd/files-upload/files-upload.component';
import { FooterButtonsComponent } from './components/ReusableComponents/footer-buttons/footer-buttons.component';
import { HeaderComponent } from './components/header/header.component';
import { HasPermissionDirective } from './directives/hasPermission.directive';
import { KeyMetricsComponent } from './components/KeyMetrics/key-metrics/key-metrics.component';
import { ListPfdPageComponent } from './components/AFEED/PFD/list-pfd-page/list-pfd-page.component';
import { ListProjectsPageComponent } from './components/Project/list-projects-page/list-projects-page.component';
import { ModalComponent as ModalComponent } from './components/ReusableComponents/modal/modal.component';
import { PfdDiagramListComponent } from './components/AFEED/PFD/create-pfd/pfd-diagram-list/pfd-diagram-list.component';
import { PfdDiagramViewComponent } from './components/AFEED/PFD/create-pfd/pfd-diagram-view/pfd-diagram-view.component';
import { ProjectPageComponent } from './components/Project/project-page/project-page.component';
import { IndustryListComponent } from './components/Industry/industry-list/industry-list.component';
import { ValueLeverComponent } from './components/Industry/value-lever/value-lever.component';
import { IndustryListExtendedComponent } from './components/Industry/industry-list-extended/industry-list-extended/industry-list-extended.component';
import { UsecaseModalComponent } from './components/ReusableComponents/usecase-modal/usecase-modal.component';
import { ShowMapComponent } from './components/NDP/show-map/show-map.component';
import { ReusableComponentsModule } from './components/ReusableComponents/reusable-components.module';
import { UnauthorizedComponent } from './components/ReusableComponents/unauthorized/unauthorized.component';
import { EvLayoutComponent } from './components/EVLayout/ev-layout/ev-layout.component';
import { EVProjectsComponent } from './components/evprojects/evprojects.component';
import { EvMaplArcgisComponent } from './components/ev-mapl-arcgis/ev-mapl-arcgis.component';
import { EvMaplArcgisV2Component } from './components/ev-mapl-arcgis-v2/ev-mapl-arcgis-v2.component';
import { EvLayoutV2Component } from './components/EVLayout/ev-layout-v2/ev-layout-v2.component';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: AppConfig.configurations.auth.clientId,
      redirectUri: AppConfig.configurations.auth.redirectUri,
      authority: AppConfig.configurations.auth.authority,
      postLogoutRedirectUri: AppConfig.configurations.auth.postLogoutRedirectUri,
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
    system: {
      loggerOptions: {
        loggerCallback: (_: LogLevel, message: string) => {
          // uncomment line below in case of logging error, for debugging purposes
          // console.log(message);
        },
        logLevel: LogLevel.Warning, // change log level to see more granularity with logging issues
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read', 'mail.read']);
  protectedResourceMap.set(
    AppConfig.configurations.resources.GDPApi.resourceUri,
    [AppConfig.configurations.resources.GDPApi.resourceScope]
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [AppConfig.configurations.resources.GDPApi.resourceScope, 'user.read']
    }
  };
}

@NgModule({
    declarations: [
        AfeedProcessListPageComponent,
        AppComponent,
        BottomNavigationComponent,
        CreateProjectComponent,
        CreatePfdComponent,
        DataIntegrityCheckComponent,
        FilesUploadComponent,
        FooterButtonsComponent,
        HeaderComponent,
        IndustryListComponent,
        KeyMetricsComponent,
        ListPfdPageComponent,
        ListProjectsPageComponent,
        ModalComponent,
        PfdDiagramListComponent,
        PfdDiagramViewComponent,
        ProjectPageComponent,
        ValueLeverComponent,
        IndustryListExtendedComponent,
        UsecaseModalComponent,
        ShowMapComponent,
        HasPermissionDirective,
        UnauthorizedComponent,
        EvLayoutComponent,
        EVProjectsComponent,
        EvMaplArcgisComponent,
        EvMaplArcgisV2Component,
        EvLayoutV2Component
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        ChartsModule,
        FormsModule,
        HttpClientModule,
        MatButtonModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatSelectModule,
        MatExpansionModule,
        MsalModule,
        NgxPowerBiModule,
        NgxUploaderModule,
        NgxSpinnerModule,
        ReactiveFormsModule,
        ReusableComponentsModule,
        DragDropModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
            provide: APP_INITIALIZER,
            useFactory: (appConfig: AppConfig) => { return () => appConfig.load(); },
            deps: [AppConfig],
            multi: true
        },
        AppConfig,
        MatDatepickerModule,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}

// checks if the app is running on IE
export const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
