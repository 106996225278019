<nav [ngClass]="currentPage !== 'gbom' ? 'gdp-header navbar navbar-light' : 'gbom-header navbar navbar-light'">
    <!-- <a class="logo breadcrumb-background"></a> -->
    <a [ngClass]="{'logo accenture-background': currentPage !== 'gbom'}"></a> 

    <div id="navbarSupportedContent">
        <div *ngIf="projectId && currentPage !== 'gbom'">
            <ul class="navbar-nav mr-auto inlineBlock">
                <li routerLinkActive='active' class="nav-item inlineBlock">
                    <a class="nav-link" 
                        [routerLink]="['/project', projectId, 'keymetrics']" 
                        [ngClass]="{'gdp-disabled': !projectId, 'gdp-header-highlight': currentPage === 'keymetrics'}">
                        KEY METRICS
                    </a>
                </li>
                <li routerLinkActive='active' class="nav-item inlineBlock">
                    <a class="nav-link" 
                        [routerLink]="['/project', projectId, 'automatedfeed']"
                        [ngClass]="{'gdp-disabled': !projectId, 'gdp-header-highlight': currentPage === 'automatedfeed'}">
                        AUTOMATED FEED
                    </a>
                </li>
            </ul>
        </div>

        <div *ngIf="currentPage === 'gbom'">
            <ul class="navbar-nav mr-auto inlineBlock">
                <li >
                    <img class="breadcrumb-icon" 
                        src="./../../../../../assets/imgs/breadcrumb-icon.svg"/>
                    <a [routerLink]="['/']">
                        <img class="gdp-logo" 
                        src="./../../../../../assets/imgs/gbom/gdp-logo.png"/>
                    </a>
                    <a [routerLink]="['/']">
                        <span class="gdp-title"> GENERATIVE DESIGN AND PLANNING </span>
                    </a>
                    <span class="project-title">
                        {{ headerTitle }}
                    </span>
                    <img class="dropdown-close-icon" 
                        src="./../../../../../assets/imgs/dropdown-close-icon.svg"/>
                    <label class="switch">
                        <input type="checkbox" checked>
                        <span class="slider round"></span>
                    </label>
                    <img class="bell-icon" 
                        src="./../../../../../assets/imgs/bell-icon.svg"/>
                    <img class="profile-pic" 
                        src="./../../../../../assets/imgs/profile-pic.png"/>
                </li>
            </ul>
        </div>

        <!-- <div *ngIf="currentPage === 'industry' || currentPage === 'valuelever'">
            <ul class="navbar-nav mr-auto inlineBlock">
                <li routerLinkActive='active' class="nav-item inlineBlock">
                    <a class="nav-link" 
                        [routerLink]="['/']" 
                        [ngClass]="{'gdp-header-highlight': currentPage === 'industry'}">
                        INDUSTRY
                    </a>
                </li>
                <li routerLinkActive='active' class="nav-item inlineBlock">
                    <a class="nav-link" 
                        [routerLink]="['/valuelever']"
                        [ngClass]="{'gdp-header-highlight': currentPage === 'valuelever'}">
                        VALUE LEVER
                    </a>
                </li>
            </ul>
        </div> -->
    </div>
    
    <!-- <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id="switchThemes" checked disabled>
        <label class="custom-control-label margin" for="switchThemes"></label>
    </div>

    <a class="logo search-background"></a>
    <a class="logo notification-background"></a>
    <a class="logo profile-background"></a> -->
</nav>

<div class="subheader">
    <div *ngIf="currentPage !== 'industry' && currentPage !== 'valuelever' && currentPage !== 'gbom'">
        <nav class="gdp-subheader navbar navbar-expand-lg navbar-light left-nav">
            <a [routerLink]="['/']">
                <span class="leftArrow-background"></span>
                <span class="backToHome"> BACK TO HOME</span>
            </a>
        </nav>
        
        <!-- <nav class="gdp-subheader navbar navbar-expand-lg navbar-light right-nav">
            <div>
                <span class="activityMonitor"> ACTIVITY MONITOR</span>
                <span class="downArrow-background"></span>
            </div>
        </nav> -->
    </div>
    <div *ngIf="currentPage === 'industry' || currentPage === 'valuelever'">
        <nav class="gdp-subheader navbar navbar-expand-lg navbar-light"></nav>
    </div>
</div>