import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TileInput } from '../../../models/tile.input';

@Component({
  selector: 'app-industry-list',
  templateUrl: './industry-list.component.html',
  styleUrls: ['./industry-list.component.scss', '../industry.styles.scss']
})
export class IndustryListComponent implements OnInit {

  industryData: TileInput[];
  selectedCategory: number;
  subscription: any;
  category: string;

  constructor(private route: ActivatedRoute) {
    this.industryData = [];
    this.selectedCategory = -1;
  }

  ngOnInit(): void {
    this.subscription = this.route.params.subscribe(params => {
      this.category = params['category'];
    });

    this.getIndustryData();
  }

  getIndustryData() {
    let jsonContent = require('../../../../assets/resources/industryViewData.json');
    this.industryData = [];
    jsonContent['industry'].map((industryCategory: any, ind: number) => {
      let tileInput = new TileInput();
      tileInput.name = industryCategory['name'].toUpperCase();
      let isOpen = industryCategory['key'] === this.category;
      if (isOpen) {
        this.selectedCategory = ind;
      }
      tileInput.description = {
        isOpen: isOpen,
        subcategories: industryCategory['subcategories'],
        imgPath: 'assets/imgs/industry/' + industryCategory['imageName'],
        key: industryCategory['key']
      }
      this.industryData.push(tileInput);
    });
  }

  openIndustry(ind: number) {
    if (this.selectedCategory !== -1) {
      this.industryData[this.selectedCategory].description.isOpen = false;
    }
    this.industryData[ind].description.isOpen = true;
    this.selectedCategory = ind;
  }

}
