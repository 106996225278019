import { IAppConfig } from './app/models/app-config.model';
import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';

@Injectable()
export class AppConfig {
    static configurations: IAppConfig;
    private httpClient: HttpClient;

    constructor( handler: HttpBackend ) { this.httpClient = new HttpClient(handler); }

     load(): Promise<boolean> {
        const jsonFile = `assets/config/config.json`;
         return  new Promise<boolean>((resolve, reject) => {
             this.httpClient.get(jsonFile).toPromise().then((response : IAppConfig) => {
               AppConfig.configurations = <IAppConfig>response;
               resolve(true);
            }).catch((response: any) => {
               reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }
} 
