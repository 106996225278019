<div class="begin-page">
    <h1>Key Metrics</h1>
    <p class="half-width">
        Here is where you can see the key metrics, and also 
        this is a good explanation why this is valuable for you as a user and how it works.
    </p>
</div>

<app-toaster-container></app-toaster-container>

<div class="container-fluid grey-container column-stretch">
    <div class="column-left">
        <h3>SUCCESS METRICS</h3>
        <hr class="green">
        <div class="chart-legend">
            <div *ngFor="let metric of globalKeyMetricValues; let i = index"
                (click)="legendClickCallback($event, i)"
                (mouseover)="legendHoverCallback($event, i, 1)"
                (mouseleave)="legendHoverCallback($event, i, 0)"
                [style.border-color]="chartColors[i]"
                [style.border-width]="(selectedIndex === i && !deletedIndexes[i]) ? '2px' : '1px'"
                [style.font-weight]="(selectedIndex === i && !deletedIndexes[i]) ? 'bold' : 'normal'"
                [style.text-decoration]="deletedIndexes[i] ? 'line-through' : 'none'">
                {{metric.label | uppercase}}
                <button>
                    <mat-icon inline=true (click)="closeClickCallback($event, i)">close</mat-icon>
                </button>
            </div>
        </div>
        <div class="chart-viewer">
            <canvas *ngIf="chartConfig" baseChart #myChart id="chart"
                [chartType]="chartConfig.type"
                [labels]="chartConfig.labels"
                [datasets]="chartConfig.datasets"
                [options]="chartConfig.options"
            ></canvas>
        </div>
    </div>

    <div class="column-right">
        <h3>DETAILS: 
            <span *ngIf="(globalKeyMetricValues) && (selectedIndex !== -1); else elseBlock">
                {{ globalKeyMetricValues[selectedIndex].label | uppercase }}
            </span>
            <ng-template #elseBlock>&#9472;</ng-template>
        </h3>
        <hr class="green">
        <div class="attributes-content">
            <table class="metrics">
                <tr *ngFor="let name of globalKeyMetricNames; let i=index">
                    <td>{{ name }}</td>
                    <td>
                        <div *ngIf="selectedIndex !== -1; else elseBlock">
                            {{ globalKeyMetricValues[selectedIndex].data[i] | number: '1.0-0' }}
                        </div>
                        <ng-template #elseBlock>&#9472;</ng-template>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>