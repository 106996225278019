import { CaseType, Attributes } from './../../../../../models/diagramEquipment';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { CacheService } from '../../../../../services/cache.service';
import { Pfd } from '../../../../../models/process';
import { ProcessService } from './../../../../../services/process.service';
import { PfdService } from '../../../../../services/pfd.service';
import { ModalComponent } from 'src/app/components/ReusableComponents/modal/modal.component';
import { ToasterService } from 'src/app/services/toaster.service';


@Component({
  selector: 'pfd-diagram-view',
  templateUrl: './pfd-diagram-view.component.html',
  styleUrls: ['./pfd-diagram-view.component.scss']
})
export class PfdDiagramViewComponent implements OnInit, OnDestroy {
  private projectId: string;
  private processId: string;
  private diagramId: string;
  private sub: Subscription;
  projectName: string;

  allEquipmentTypeAttributes: any;
  selectedEquipment = "";
  equipmentTypeAttributes: any;
  attributes: Attributes[];
  caseType: CaseType = "base";

  readonly maxPfdStages: number = Pfd.MAXSTAGES;
  diagram: any;
  pfdStatus: any;
  pfd: Pfd;
  pfdName: string;
  equipmentId: string = "";

  constructor(
    private cacheService: CacheService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private pfdService: PfdService,
    private processService: ProcessService,
    public matDialog: MatDialog,
    private toaster: ToasterService
  ) { }

  modalDialog: MatDialogRef<ModalComponent>;

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.projectId = params['projectid'];
      this.processId = params['processid'];
      this.diagramId = params['diagramid'];
    });

    this.cacheService.getFromCacheObs(this.projectId, 'projectName').subscribe({
      next: (response: string) => {
        this.projectName = response;
      },
      error: (err) => { }
    });

    this.getProcessProperties();
    this.getPfdDiagram();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.toaster) {
      this.toaster.unsubscribe();
    }
  }

  private getProcessProperties(): void {
    this.processService.getProcessById(this.projectId, this.processId).subscribe({
      next: (response: Pfd) => {
        this.pfd = response;
        this.pfdName = this.pfd.ProcessName;
      }
    });
  }

  getPfdDiagram() {
    this.pfdService.getPfdDiagramById(this.projectId, this.projectId, this.diagramId).subscribe({
      //this.pfdService.getPfdDiagramByIDMock().subscribe({
      next: (response: any) => {
        let svgData = response.svg;
        ///remove XML prolog section (must come first in a document, does not allow to render SVG)
        ///svgData = svgData.replace(/<\?xml.*?>/, '');
        ///replace every # character (they are unsafe)
        ///svgData = svgData.replaceAll('#', '$');
        this.diagram = this.sanitizer.bypassSecurityTrustHtml(svgData);
        this.pfdStatus = response.status;
      }
    });
  }

  goBackToPfdDrawingList = (): void => {
    let url = this.router.url;
    url = url.substr(0, url.lastIndexOf('/'));
    this.setPfdState(3).then(() => {
      this.ngOnDestroy();
      this.router.navigateByUrl(url);
    });
  }

  goBackToGeneralPfdList = (): void => {
    let url = this.router.url;
    url = url.substr(0, url.lastIndexOf('/'));
    url = url.substr(0, url.lastIndexOf('/'));
    this.ngOnDestroy();
    this.router.navigateByUrl(url);
  }

  async setPfdState(stateval: number) {
    let result = await this.processService.setProcessState(this.projectId, this.processId, stateval);
  }

  closeDialog = (): void => {
    this.modalDialog.close();
  }

  openConfirmationDialog(newValue) {
    let dialogConfig = ModalComponent.defaultConfig();
    dialogConfig.data.title = 'INFORMATION';
    dialogConfig.data.description = newValue;
    dialogConfig.data.hasCancelButton = false;
    dialogConfig.data.okButtonText = 'OK';
    dialogConfig.data.okButtonCallback = this.closeDialog;
    this.modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }

  savePfdDiagramData = (): void => {
    this.processService.setPfdDiagramStatus(this.projectId, this.processId, this.diagramId, this.pfdStatus).then(() => {
      this.openConfirmationDialog('PFD data was saved.');
    });
  }

  setPfdDiagramStatusApproved = (): void => {
    this.processService.setPfdDiagramStatus(this.projectId, this.processId, this.diagramId, 'Approved').then(() => {
      this.pfdStatus = 'Approved';
      this.toaster.show('success', 'The status has been changed into Approved.', '');
    }).catch(() => { });
  }

  changeCase(newCase: CaseType) {
    this.caseType = newCase;

    for (let i = 0; i < this.equipmentTypeAttributes.length; i++) {
      if (this.equipmentTypeAttributes[i].Case == newCase) {
        this.attributes = this.equipmentTypeAttributes[i].Attributes;
      }
    }
  }

  getEquipmentTypeAttributes(event) {
    if (event.target.localName == "text" && !event.target.innerHTML.includes("|")) {
      this.equipmentId = event.target.innerHTML;
    } else {
      this.equipmentId = event.target.parentElement.id;
    }
    this.processService.getPFDEquipmentDetails(this.projectId, this.processId, this.diagramId, this.equipmentId).subscribe({
      next: (response) => {
        if (response !== null) {
          this.equipmentTypeAttributes = response['EquipmentAttributes'];
          if (this.equipmentTypeAttributes !== undefined) {
            this.changeCase("base");
          }
        }
      }
    });
  }

}
