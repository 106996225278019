import { Component, OnInit } from '@angular/core';
import maplibregl from 'maplibre-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

@Component({
  selector: 'app-ev-layout-v2',
  templateUrl: './ev-layout-v2.component.html',
  styleUrls: ['./ev-layout-v2.component.scss']
})
export class EvLayoutV2Component implements OnInit {

  panelOpenState = false;
  map: maplibregl.Map;
  draw: MapboxDraw;
  mapWidth: any;
  mapHeight: any;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.initializeMap();
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove();
    }
  }

  private initializeMap(): void {
    this.map = new maplibregl.Map({
      container: 'map',
      style: 'https://api.maptiler.com/maps/hybrid/style.json?key=weOikShVu5TTyIHqwys7', // Replace with your MapTiler API key
      center: [-117.28795104202172, 34.06800975],
      zoom: 17.5,
      interactive: false // Disable default interactivity
    });

    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true
      }
    });

    this.map.addControl(this.draw);

    this.map.on('load', () => {
      this.map.resize();
      this.logMapWidth();
    });

    this.map.addControl(new maplibregl.NavigationControl({ showZoom: false, showCompass: false }));
    this.map.addControl(new maplibregl.ScaleControl());

    // Add event listeners for drawing
    this.map.on('draw.create', this.updateArea);
    this.map.on('draw.delete', this.updateArea);
    this.map.on('draw.update', this.updateArea);

    // Initialize search functionality
    const searchInput = document.getElementById('search') as HTMLInputElement;
    searchInput.addEventListener('keyup', (event) => {
      if (event.key === 'Enter') {
        this.searchLocation(searchInput.value);
      }
    });
  }

  private searchLocation(query: string): void {
    fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${query}`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          const result = data[0];
          const [lon, lat] = [result.lon, result.lat];
          this.map.flyTo({ center: [lon, lat], zoom: 14 });
        }
      });
  }

  private updateArea = (e: any) => {
    const data = this.draw.getAll();
    if (data.features.length > 0) {
      const coordinates = data.features[0].geometry.coordinates[0];
      const vertices = coordinates.slice(0, -1);
      console.log('Polygon coordinates:', vertices);
      this.localRecCoordinates(vertices);
    } else {
      console.log('No polygon drawn');
    }
  };

  switchLayer(layer: string): void {
    const styles = {
      streets: 'https://api.maptiler.com/maps/streets/style.json?key=weOikShVu5TTyIHqwys7',
      satellite: 'https://api.maptiler.com/maps/hybrid/style.json?key=weOikShVu5TTyIHqwys7'
    };
    this.map.setStyle(styles[layer]);
  }

  enablePolygonDraw(): void {
    this.draw.changeMode('draw_polygon');
  }

  deleteAllDrawings(): void {
    this.draw.deleteAll();
  }

  logMapWidth(): void {
    const mapContainer = this.map.getContainer();
    this.mapWidth = mapContainer.clientWidth;
    this.mapHeight = mapContainer.clientHeight;
    console.log('Map width:', this.mapWidth);
  }

  localRecCoordinates(coor: any): void {
    var coords = coor.map(coord => {
      const longitude = coord[0];
      const latitude = coord[1];

      const bounds = this.map.getBounds();
      const minLongitude = bounds.getWest();
      const maxLongitude = bounds.getEast();
      const minLatitude = bounds.getSouth();
      const maxLatitude = bounds.getNorth();

      // Normalize longitude and latitude
      const normalizedX = (longitude - minLongitude) / (maxLongitude - minLongitude);
      const normalizedY = (latitude - minLatitude) / (maxLatitude - minLatitude);

      // Map to picture coordinates
      const x = normalizedX * this.mapWidth;
      const y = normalizedY * this.mapHeight;

      return { x, y };
    });
    
    console.log('Converted Local Coordinates:', coords);
  }

  captureMap(): void {
    const canvas = this.map.getCanvas();
    const image = canvas.toDataURL('image/png');

    // Create a link element
    const link = document.createElement('a');
    link.href = image;
    link.download =  'map.png';
    link.click();
  }

}
