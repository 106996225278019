import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Breadcrumb } from '../model/breadcrumb.model';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { ModalComponent } from '../../modal/modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'gdp-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() wasModified: boolean;
  url: string;
  breadcrumbs$: Observable<Breadcrumb[]>;
  modalDialog: MatDialogRef<ModalComponent>;

  constructor(
    breadcrumbService: BreadcrumbService,
    public matDialog: MatDialog,
    private router: Router
  ) {
    this.breadcrumbs$ = breadcrumbService.breadcrumbs$;
  }

  ngOnInit(): void {
  }

  handleBreadCrumb(breadcrumb: string) {
    this.url = breadcrumb;
    if (this.wasModified == true) {
      this.openQuitProhibitDialog();
    } else {
      this.navigateByUrl();
    }
  }

  navigateByUrl = (): void => {
    this.router.navigateByUrl(this.url);
    this.closeDialog();
  }

  /**
  * Opens dialog for prohibiting to leave page without saving changes.
  * */
  openQuitProhibitDialog() {
    let dialogConfig = ModalComponent.defaultConfig();
    dialogConfig.data.description = `
        You modified the data for this current Process Layout.
        Are you sure you want to leave this page without saving your changes first?
      `;
    dialogConfig.data.hasCancelButton = true;
    dialogConfig.data.cancelButtonText = 'NO, STAY HERE';
    dialogConfig.data.cancelButtonCallback = this.closeDialog;
    dialogConfig.data.okButtonText = 'YES, QUIT ANYWAY';
    dialogConfig.data.okButtonCallback = this.navigateByUrl;
    this.modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }

  /**
  * Closes the dialog that is currently opened.
  * */
  closeDialog = (): void => {
    if (this.modalDialog) {
      this.modalDialog.close();
    }
  }
}
