/**
 * Navigation to external URL, open in new tab.
 * Source 1: https://stackoverflow.com/questions/40150393/how-to-redirect-to-an-external-url-from-angular2-route-without-using-component
 * Source 2: https://stackoverflow.com/questions/42775017/angular-2-redirect-to-an-external-url-and-open-in-a-new-tab
 * Source 3: https://angular.io/api/router/Resolve
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ExternalLinkResolver implements Resolve<any> {
  constructor(private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): any {
    let url = state.url;
    url = url.substr(0, url.lastIndexOf('/'));
    this.router.navigateByUrl(url).then(() => {
        window.open((route.data as any).externalUrl, "_blank");
    });
    return
  }
}