<app-header></app-header>

<app-toaster-container *ngIf="toasterIsDisplayed"></app-toaster-container>

<gdp-loading></gdp-loading>

<div>
    <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    <router-outlet *ngIf="!isIframe"></router-outlet>

    <h1 *ngIf="isIframe"> Unauthorized !</h1>
</div>

