import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Project } from '../models/project';
import { ProjectService } from './project.service';


@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor(private projectService: ProjectService) { }

  saveToCache(id: string, name: string) {
    let myCache = sessionStorage;
    myCache.setItem(id, name);
  }

  
  getFromCache(id: string): string {
    let myCache = sessionStorage;
    return myCache.getItem(id);
  }

  /** 
   * Returns data from cache as observable, and it saves variable into cache if it is not saved yet.
   */
  getFromCacheObs(id: string, dataType: string) {
    let myCache = sessionStorage;
    let name = myCache.getItem(id);
    if (name !== null) {
      return of(name);
    }
    switch (dataType) {
      case 'projectName': {
        return this.projectService.getProjectById(id).pipe(
          map(response => response.ProjectName),
          tap(name => this.saveToCache(id, name))
        )
      }
      default:
        return of(null)
    }
  }

  clearCache() {
    sessionStorage.clear();
  }
}
