import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

import { CacheService } from '../../../../../services/cache.service';
import { DataIntegrityCheck } from '../../../../../models/data-integrity-check';
import { DataIntegrityCheckService } from './../../../../../services/data-integrity-check.service';
import { Pfd } from 'src/app/models/process';
import { ProcessService } from './../../../../../services/process.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'pfd-data-integrity-check',
  templateUrl: './data-integrity-check.component.html',
  styleUrls: ['./data-integrity-check.component.scss']
})
export class DataIntegrityCheckComponent implements OnInit, OnDestroy {
  projectId: string;
  processId: string;
  sub: any;
  projectName: string;
  dataIntegrityCheckResult: DataIntegrityCheck[] = [];
  isValidationEnded: boolean = false;
  interval: any;
  AllResultsAreValid = false;

  pfd: Pfd;
  readonly maxPfdStages: number = Pfd.MAXSTAGES;
  currentPfdStage: number = 2;
  isCancelButtonVisible: boolean = true;
  createButtonText: string = 'GENERATE';
  createButtonType: string = 'new';
  isBackButtonVisible: boolean = true;
  backButtonText: string = 'BACK TO CREATE';

  constructor(
    private cacheService: CacheService,
    private dataIntegrityCheckService: DataIntegrityCheckService,
    private processService: ProcessService,
    private route: ActivatedRoute,
    private toaster: ToasterService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.projectId = params['projectid'];
      this.processId = params['processid'];
    });

    this.cacheService.getFromCacheObs(this.projectId, 'projectName').subscribe({
      next: (response: string) => {
        this.projectName = response;
      },
      error: (err) => {
        // TODO: FE logging
      }
    });

    this.getProcessProperties();
    this.getValidationStatusAndLoadData();
  }

  private getProcessProperties(): void {
    this.processService.getProcessById(this.projectId, this.processId).subscribe({
      next: (response: Pfd) => {
        this.pfd = response;
      }
    });
  }

  getValidationStatusAndLoadData() {
    this.spinner.show();

    this.interval = setInterval(() => {
      this.processService.getDataIntegrityValidationStatus(this.projectId, this.processId).then((result) => {
        if (result["runtimeStatus"] == "Completed") {
          clearInterval(this.interval);
          if (result['output'] === 'Error') {
            this.spinner.hide();
            this.toaster.show('error', 'GENERATION FAILED: Something went wrong on the server side. Please try again later.', '');
          } else {
            this.getTableOfPPipes();
          }
        }
      });
    }, 1000);
  }

  getTableOfPPipes = (): void => {
    this.dataIntegrityCheckService.getTableOfPPipes(this.projectId, this.processId).then((result) => {
      this.dataIntegrityCheckResult = result;
      this.AllResultsAreValid = this.dataIntegrityCheckResult[0].AllResultsAreValid;

      this.spinner.hide();

      this.AllResultsAreValid ?
        this.toaster.show('success', 'CONFIGURATION SUCCESSFUL: “' + this.pfd.ProcessName + '” has been configured successfully.', '') :
        this.toaster.show('error', 'CONFIGURATION FAILED: “' + this.pfd.ProcessName + '” Has missing components. Please revise the simulation.', '');
    })
  }

  goBackToGeneralPfdList = (): void => {
    let url = this.router.url;
    url = url.substr(0, url.lastIndexOf('/'));
    this.router.navigateByUrl(url);
  }

  saveButtonCallback = (): void => {
    //Submitting form...
  }

  startGeneration = (): void => {
    this.processService.startPfdGeneration(this.projectId, this.processId)
      .then(() => {
        this.setPfdState(3)
          .then(() => {
            let url = this.router.url.substr(0, this.router.url.lastIndexOf('/')) + '/' + this.processId;
            this.router.navigateByUrl(url);
          })
          .catch((err) => {
            this.toaster.show('error', 'Error:' + err.error.Message, '');
          });
      })
      .catch((err) => {
        this.toaster.show('error', 'Error during Generation:' + err.error.Message, '');
      });
  }

  backButtonCallback = (): void => {
    this.ngOnDestroy();
    this.setPfdState(1).then(() => {
      let url = this.router.url;
      url = url.substr(0, url.lastIndexOf('/')) + '/' + this.processId;
      this.router.navigateByUrl(url);
    });
  }

  private async setPfdState(stateval: number) {
    let result = await this.processService.setProcessState(this.projectId, this.processId, stateval);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.toaster) {
      this.toaster.unsubscribe();
    }
  }
}
