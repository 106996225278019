import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AfeedProcessListPageComponent } from '../components/AFEED/afeed-process-list/afeed-process-list-page.component';
import { CreatePfdComponent } from '../components/AFEED/PFD/create-pfd/create-pfd.component';
import { CreateProjectComponent } from '../components/Project/create-project/create-project.component';
import { ExternalLinkResolver } from './external-link-resolver';
import { IndustryListComponent } from '../components/Industry/industry-list/industry-list.component';
import { IndustryListExtendedComponent } from './../components/Industry/industry-list-extended/industry-list-extended/industry-list-extended.component';
import { KeyMetricsComponent } from './../components/KeyMetrics/key-metrics/key-metrics.component';
import { ListPfdPageComponent } from '../components/AFEED/PFD/list-pfd-page/list-pfd-page.component';
import { ListProjectsPageComponent } from '../components/Project/list-projects-page/list-projects-page.component';
import { ProjectPageComponent } from '../components/Project/project-page/project-page.component';
import { PfdDiagramViewComponent } from '../components/AFEED/PFD/create-pfd/pfd-diagram-view/pfd-diagram-view.component';
import { ShowMapComponent } from '../components/NDP/show-map/show-map.component';
import { UnauthorizedComponent } from './../components/ReusableComponents/unauthorized/unauthorized.component';
import { ValueLeverComponent } from '../components/Industry/value-lever/value-lever.component';
import { EvLayoutComponent } from '../components/EVLayout/ev-layout/ev-layout.component';
import { EvLayoutV2Component } from '../components/EVLayout/ev-layout-v2/ev-layout-v2.component';
import { EVProjectsComponent } from '../components/evprojects/evprojects.component';
import { EvMaplArcgisComponent } from '../components/ev-mapl-arcgis/ev-mapl-arcgis.component';
import { EvMaplArcgisV2Component } from '../components/ev-mapl-arcgis-v2/ev-mapl-arcgis-v2.component';

import { Role } from './../models/role';
import { AuthGuard } from './auth.guard';
import { MsalGuard } from '@azure/msal-angular';

const appRoutes: Routes = [
  {
    path: '',
    component: IndustryListComponent,
    pathMatch: 'full',
    canActivate: [MsalGuard]
  },
  // {
  //   path: 'valuelever',
  //   component: ValueLeverComponent,
  //   pathMatch: 'full',
  // },
  {
    path: 'industry/:category',
    component: IndustryListComponent,
    pathMatch: 'full',
    canActivate: [MsalGuard]
  },
  {
    path: 'industry/:category/:subCategory',
    component: IndustryListExtendedComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'projects',
    component: ListProjectsPageComponent,
    pathMatch: 'full',
    canActivate: [MsalGuard]
    //canActivate: [AuthGuard],
    //data: { roles: [Role.GDP_SystemAdmin, Role.GDP_ReadOnly] },
  },
  {
    path: 'evlayout',
    component: EvLayoutComponent,
    pathMatch: 'full',
    canActivate: [MsalGuard]
    //canActivate: [AuthGuard],
    //data: { roles: [Role.GDP_SystemAdmin, Role.GDP_ReadOnly] },
  },
  {
    path: 'evlayoutv2',
    component: EvLayoutV2Component,
    pathMatch: 'full',
    canActivate: [MsalGuard]
    //canActivate: [AuthGuard],
    //data: { roles: [Role.GDP_SystemAdmin, Role.GDP_ReadOnly] },
  },
  {
    path: 'evprojects',
    component: EVProjectsComponent,
    pathMatch: 'full',
    canActivate: [MsalGuard]
    //canActivate: [AuthGuard],
    //data: { roles: [Role.GDP_SystemAdmin, Role.GDP_ReadOnly] },
  },
  {
    path: 'evmaplarcgis',
    component: EvMaplArcgisComponent,
    pathMatch: 'full',
    canActivate: [MsalGuard]
    //canActivate: [AuthGuard],
    //data: { roles: [Role.GDP_SystemAdmin, Role.GDP_ReadOnly] },
  },
  {
    path: 'evmaplarcgisv2',
    component: EvMaplArcgisV2Component,
    pathMatch: 'full',
    canActivate: [MsalGuard]
    //canActivate: [AuthGuard],
    //data: { roles: [Role.GDP_SystemAdmin, Role.GDP_ReadOnly] },
  },
  {
    path: 'ndp',
    component: ShowMapComponent,
    pathMatch: 'full',
    canActivate: [MsalGuard]
  },
  {
    path: 'project/:projectid',
    canActivate: [MsalGuard],
    children: [
      {
        path: '',
        component: ProjectPageComponent,
      },
      {
        path: 'keymetrics',
        component: KeyMetricsComponent,
      },
      {
        path: 'automatedfeed',
        children: [
          {
            path: '',
            component: AfeedProcessListPageComponent,
          },
          /*{
            path: 'externalPlantLayout',
            component: AfeedProcessListPageComponent,
            resolve: {
              resolver: ExternalLinkResolver,
            },
            data: {
              externalUrl: 'https://fjord.invisionapp.com/share/PJZC4M4QW5V#/screens/439880839'
            }
          },*/
          {
            path: 'externalScheduleLayout',
            component: AfeedProcessListPageComponent,
            resolve: {
              resolver: ExternalLinkResolver,
            },
            data: {
              externalUrl:
                'https://fjord.invisionapp.com/share/PJZC4M4QW5V#/screens/439880849',
            },
          },
          {
            path: 'pfd',
            children: [
              {
                path: '',
                component: ListPfdPageComponent,
              },
              {
                path: ':processid',
                children: [
                  {
                    path: '',
                    component: CreatePfdComponent,
                  },
                  {
                    path: ':diagramid',
                    component: PfdDiagramViewComponent,
                    pathMatch: 'full',
                  },
                ],
              },
            ],
          },
          {
            path: 'layout',
            loadChildren: () => import('../components/Layout/layout.module').then(m => m.LayoutModule)
          },
          {
            path: 'gbom',
            loadChildren: () => import('../components/GBoM/gbom.module').then(m => m.GbomModule)
          }
        ]
      },
    ],
  },
  {
    path: 'createproject',
    component: CreateProjectComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    pathMatch: 'full',
    canActivate: [MsalGuard]
  },
  { path: '**',
    redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
    useHash: false,
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
