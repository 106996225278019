import { catchError, retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import config from '../app-config.json';
import { CommonService } from './common.service';
import { Project } from '../models/project';
import { ToasterService } from './toaster.service';


@Injectable({
  providedIn: 'root',
})
export class ProjectService {

  constructor(
    private commonService: CommonService,
    private http: HttpClient,
    private toaster: ToasterService
  ) { }

  getProjects(): Observable<Project[] | any> {
    let url = this.commonService.getUrl(config.getProjects, []);

    return this.http.get<Project[]>(url).pipe(
      catchError((err) => {
        this.commonService.handleHttpError(err, 'Cannot retrieve list of projects!');
        return throwError(err);
      })
    );
  }

  getProjectById(id: string): Observable<Project | any> {
    let url = this.commonService.getUrl(config.getProjectById, [id]);

    return this.http.get<Project>(url).pipe(
      catchError((err) => {
        this.commonService.handleHttpError(err);
        return throwError(err);
      })
    );
  }

  createProject(project: Project): Observable<any> {
    let url = this.commonService.getUrl(config.createProject, []);

    return this.http.post<any>(url, project).pipe(
      catchError((err) => {
        if (err && err.status && err.statusText) {
          this.toaster.show('error', 'Error ' + err.status + ': ' + err.statusText);
        } else {
          this.toaster.show('error', 'An error has occured. Please try again!', '');
        }
        return throwError(err);
      })
    );
  }

  /**
   * Get all KPI metrics for the current project:
   * - global metrics defined by the client (user)
   * - local key metrics corresponding to each optimization scenario (PFD process)
   * TODO: work with data from server (it's mock data for now)
   * @param projectId ID of current project
   */
  getProjectKeyMetrics(projectId: string) {
    let url = this.commonService.getUrl(config.getProjectKPIs, [projectId]);
    return this.http.get<any>(url).pipe(
      catchError((err) => {
        retry(3);
        if (err && err.error && err.error.message) {
          this.toaster.show('error', err.error.message);
        } else {
          this.toaster.show('error', 'An error has occured. Please try again!', '');
        }
        return throwError(err);
      })
    ).toPromise();
  }

}
