
export class DataIntegrityCheck {
  StreamName: string;
  InputEquipmentName: string;
  InputEquipmentType: string;
  OutputEquipmentName: string;
  OutputEquipmentType: string;
  IsValid: boolean;
  AllResultsAreValid: boolean;
}
