import { AuthError } from 'msal';
import { Injectable } from '@angular/core';
import { ToasterService } from './toaster.service';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import config from '../app-config.json';
import { AppConfig } from './../../app.config';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient,
    private toaster: ToasterService) { }

  /**
 * Returns resourceUri + builds the url
 *
 * @param {string} url URL from app-config.json.
 * @param {string[]} params List with paramaters to substitute .
 * @param {string} [queryString] QuerryString.
 * If the querry string is optional and you dont want to use it, leave it empty (queryString="")
 *
 * @return {string} Full url
 */
  getUrl(url: string, params: string[], queryString?: string): string {
    for (let i = 0; i < params.length; ++i) {
      url = url.replace(/{[a-zA-Z0-9()]+}/, params[i]);
    }

    //If queryString has value it subtitutes in it place else cuts the QuerryString ending from the url
    if (queryString) {
      url = url.replace(/\?/g, "").replace(/\[/g, "?").replace(/\]/g, "=") + queryString;
    }
    else url = url.replace(/[[a-zA-Z0-9()]+]/, "").replace(/\?/g, "").replace(/\[/g, "");

    return AppConfig.configurations.resources.GDPApi.resourceUri + url;
  }

  handleHttpError(err: any, msg: string = '') {
    if (err instanceof AuthError) {
      this.toaster.show('error', 'AUTHENTICATION ERROR! Please try again!');
      return
    }
  }

  getPowerBiToken() {
    let url = this.getUrl(config.getPowerBIEmbedData, []);

    return this.http.get<any>(url).pipe(
      catchError((err) => {
        retry(3)
        this.handleHttpError(err);
        return throwError(err);
      })
    );
  }

}
