<div class="begin-page">
  <h1>{{ projectName }}</h1>
  <h2>Generative Process Flow Diagram</h2>
  <p class="half-width">
    Create a new process flow diagram set, or edit a saved one with the help of generative design.
    You can repeat this process as many times as you need to, and evaluate your success metrics as they are optimized.
  </p>
</div>

<app-toaster-container></app-toaster-container>

<div class="container-fluid grey-container">
  <div class="row">
    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let pfd of pfds; let i=index">
      <app-tile *ngIf="i===0" [tileInput]="pfd"
                [buttonText]="'CREATE PROCESS FLOW DIAGRAM(S)'"
                [targetPath]="'null'"
                [tileType]="'new'">
      </app-tile>
      <app-tile *ngIf="i!==0" [tileInput]="pfd" 
                [buttonText]="'LOAD PROCESS FLOW DIAGRAM(S)'"
                [targetPath]="pfd.id"
                [tileType]="'process'">
      </app-tile>
    </div>
  </div>
</div>
