/**
 * Page for handling stage 1 of Process Flow Diagram (PFD) generation:
 * providing name and input files for PFD.
 * */
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { CacheService } from '../../../../../services/cache.service';
import { ModalComponent } from '../../../../ReusableComponents/modal/modal.component';
import { Pfd, ProcessInput, ProcessInputKey } from '../../../../../models/process';
import { ProcessService } from '../../../../../services/process.service';
import { ToasterService } from 'src/app/services/toaster.service';


@Component({
  selector: 'pfd-files-upload',
  templateUrl: './files-upload.component.html',
  styleUrls: ['./files-upload.component.scss']
})
export class FilesUploadComponent implements OnInit {
  projectId: string;
  processId: string;
  sub: any;
  projectName: string;

  form: UntypedFormGroup;

  pfd: Pfd;

  pfdName: string;
  baseCaseFileName: string;
  summerCaseFileName: string;
  winterCaseFileName: string;
  allowedFileExtensions: string[] = ['.xml'];
  tempGuid: string;

  private _nrOfPfdInputsSet: number = 0;
  get nrOfPfdInputsSet(): number {
    return this._nrOfPfdInputsSet;
  }
  set nrOfPfdInputsSet(value: number) {
    // whenever the number of PFD inputs changes the type of the CREATE button should update
    this._nrOfPfdInputsSet = value;
    if (this._nrOfPfdInputsSet === 3)
      this.createButtonType = 'new';
    else
      this.createButtonType = 'disabled';
  }

  readonly maxPfdStages: number = Pfd.MAXSTAGES;
  currentPfdStage: number = 1;
  isCancelButtonVisible: boolean = true;
  createButtonText: string = 'CREATE';
  createButtonType: string = 'disabled';

  submitted = false;

  modalDialog: MatDialogRef<ModalComponent>;

  constructor(
    private cacheService: CacheService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private processService: ProcessService,
    private router: Router,
    public matDialog: MatDialog,
    private toaster: ToasterService) { }

  ngOnInit(): void {
    // get project and process ID's
    this.sub = this.route.params.subscribe(params => {
      this.projectId = params['projectid'];
      this.processId = params['processid'];
    });

    this.cacheService.getFromCacheObs(this.projectId, 'projectName').subscribe({
      next: (response: string) => {
        this.projectName = response;
      },
      error: (err) => {
        // TODO: FE logging
      }
    });

    // build form (@TODO: extend with stuff for files later)
    this.form = this.formBuilder.group({
      //name: [this.pfdName, Validators.required] //@TODO solve form the angular way
    }, { updateOn: 'submit' });

    // get PFD process and its inputs from DB
    if (this.processId !== 'null') {
      this.getProcessProperties();
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.toaster) {
      this.toaster.unsubscribe();
    }
  }

  /**
   * Callback function for cancel button. Either prompt user to save changes, or navigate to PFD list page.
   * */
  goBackToGeneralPfdList = (): void => {
    this.ngOnDestroy();
    if (this.wasPfdInputChanged()) {
      this.openQuitProhibitDialog();
    } else {
      this.navigateBack();
    }
  }

  /**
   * Submits the form data (PFD name and input files).
   * */
  saveButtonCallback = (): void => {
    this.onSubmit();
  }

  /**
   * Proceeds with PFD input validation, which will lead to stage 2.
   * */
  validateData = (): void => {
    if (this.wasPfdInputChanged()) {
      this.openValidationProhibitDialog();
    } else {
      this.proceedWithValidation();
    }
  }

  /**
   * Opens dialog for prohibiting to leave page without saving changes.
   * */
  openQuitProhibitDialog() {
    let dialogConfig = ModalComponent.defaultConfig();
    dialogConfig.data.description = `
      You modified the data for the current PROCESS FLOW DIAGRAM.
      Are you sure you want to leave this page without saving your changes first?
    `;
    dialogConfig.data.hasCancelButton = true;
    dialogConfig.data.cancelButtonText = 'NO, STAY HERE';
    dialogConfig.data.cancelButtonCallback = this.closeDialog;
    dialogConfig.data.okButtonText = 'YES, QUIT ANYWAY';
    dialogConfig.data.okButtonCallback = this.navigateBack;
    this.modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }

  /**
   * Opens dialog for prohibiting to start PFD validation without saving changes.
   * */
  openValidationProhibitDialog() {
    let dialogConfig = ModalComponent.defaultConfig();
    dialogConfig.data.description = `
      You modified the data for the current PROCESS FLOW DIAGRAM.
      Are you sure you want to proceed with the diagram generation without saving your changes first?
    `;
    dialogConfig.data.hasCancelButton = true;
    dialogConfig.data.cancelButtonText = 'NO, STAY HERE';
    dialogConfig.data.cancelButtonCallback = this.closeDialog;
    dialogConfig.data.okButtonText = 'YES, PROCEED';
    dialogConfig.data.okButtonCallback = this.proceedWithValidation;
    this.modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }

  /**
   * Opens a confirmation dialog to announce the user that changes have been succesfully saved in the database.
   * */
  openConfirmationDialog() {
    let dialogConfig = ModalComponent.defaultConfig();
    dialogConfig.data.title = 'INFORMATION';
    dialogConfig.data.description = 'The PROCESS FLOW DIAGRAM data you provided was succesfully saved.';
    dialogConfig.data.hasCancelButton = false;
    dialogConfig.data.okButtonText = 'OK';
    dialogConfig.data.okButtonCallback = this.closeDialog;
    this.modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }

  /**
   * Navigates back to the page where all PFD's are listed.
   * */
  navigateBack = (): void => {
    this.ngOnDestroy();
    let url = this.router.url;
    url = url.substr(0, url.lastIndexOf('/'))
    this.router.navigateByUrl(url).then(() => {
      this.closeDialog();
    });
  }

  /**
   * Closes the dialog that is currently opened.
   * */
  closeDialog = (): void => {
    // close only if it was opened
    if (this.modalDialog) {
      this.modalDialog.close();
    }
  }

  /**
   * Sets PFD stage to 2, starts data validation process in backend, and navigates to validation stage page.
   * */
  proceedWithValidation = (): void => {
    this.processService.startValidation(this.projectId, this.processId)
      .then(() => {
        this.ngOnDestroy();
        this.setPfdState(2).then(() => {
          this.closeDialog();
          let url = this.router.url;
          url = url.substr(0, url.lastIndexOf('/')) + '/' + this.pfd.ProcessId;
          this.router.navigateByUrl(url);
        }).catch((err) => {
          this.toaster.show('error', 'Error:' + err.error.Message, '');
        });
      })
      .catch((err) => {
        this.toaster.show('error', 'VALIDATION FAILED:' + err.error.Message, '');
      });
  }

  /**
   * Submits form data: creates new PFD is name was provided, and updates PFD inputs if they have changed.
   * */
  onSubmit() {
    this.submitted = true;
    // *************************************************************************************
    // ****************** run both: PFD creation + PFD input files update ******************
    if (this.pfd === undefined && this.pfdName && this.wasPfdInputChanged()) {
      // PFD process is not yet defined, but name is set and input file is also provided
      // Creating + Updating PFD.
      this.createPfd().then((result) => {
        this.pfd.ProcessId = result.processId;
        this.processId = result.processId;
        this.setPfdState(1).then(() => {
          this.updatePfd().then(() => {
            this.openConfirmationDialog();
          }).catch((err) => { this.toaster.show('error', 'PFD INPUT FILES UPDATE FAILED:' + err, ''); });
        }).catch((err) => { this.toaster.show('error', 'SETTING THE PFD STATE TO 1 FAILED:' + err, ''); });
      }).catch((err) => {
        // do something with 'err'. TODO: make consistent across all HTTP calls
        this.toaster.show('error', 'CREATING THE PFD PROCESS FAILED:' + err, '');
      }
      );
      // *************************************************************************************
      // ****************************** run only: PFD creation *******************************
    } else if (this.pfd === undefined && this.pfdName) {
      // Creating PFD.
      this.createPfd().then((result) => {
        this.pfd.ProcessId = result.processId;
        this.processId = result.processId;
        this.setPfdState(1).then(() => {
          this.openConfirmationDialog();
        }).catch((err) => { this.toaster.show('error', 'SETTING THE PFD STATE TO 1 FAILED:' + err, ''); });
      }).catch((err) => {
        this.toaster.show('error', 'CREATING THE PFD PROCESS FAILED:' + err, '');
      }
      );
      // *************************************************************************************
      // ************************* run only: PFD input files update **************************
    } else if (this.pfdName && this.wasPfdInputChanged()) {
      //  Updating PFD.
      this.updatePfd().then(() => {
        this.openConfirmationDialog();
      }).catch((err) => { this.toaster.show('error', 'PFD INPUT FILES UPDATE FAILED:' + err, ''); });
    }
  }

  /**
   * Checks if PFD data (name and input files) were changed by the user. Returns true if anything was modified.
   * */
  wasPfdInputChanged(): boolean {
    if (!this.pfd && (this.pfdName || this.baseCaseFileName || this.summerCaseFileName || this.winterCaseFileName))
      return true
    if (!this.pfd)
      return false

    if (this.baseCaseFileName && !this.hasPfdInputForCase(ProcessInputKey.BaseCase, this.baseCaseFileName))
      return true
    if (this.summerCaseFileName && !this.hasPfdInputForCase(ProcessInputKey.SummerCase, this.summerCaseFileName))
      return true
    if (this.winterCaseFileName && !this.hasPfdInputForCase(ProcessInputKey.WinterCase, this.winterCaseFileName))
      return true
    return false
  }

  /**
   * Checks if PFD input for the given case if the file provided.
   * TODO: check actual file content instead of file name only, when upload/download will work.
   * @param key: tells which case does the input file belong to (either base, summer or winter).
   * @param fileName: the file to check if it is set or not
   */
  hasPfdInputForCase(key: ProcessInputKey, fileName: string): boolean {
    if (!this.pfd || !this.pfd.Inputs)
      return false
    let keyIndex = -1;
    for (let i = 0; i < this.pfd.Inputs.length; i++)
      if (this.pfd.Inputs[i].Key === key)
        keyIndex = i;
    if (keyIndex === -1) {
      return false;
    } else {
      if (this.pfd.Inputs[keyIndex].Value === fileName) {
        return true
      } else {
        return false
      }
    }
  }

  /**
   * Retrieves PFD data and its inputs from the database.
   * TODO: either get PFD from outside this component or from DB by ID
   * */
  private getProcessProperties(): void {
    this.processService.getProcessById(this.projectId, this.processId).subscribe({
      next: (response: Pfd) => {
        this.pfd = response;
        this.pfdName = this.pfd.ProcessName;
        this.getProcessInputs(this.projectId, this.processId);
      }
    });
  }

  /**
   * Retrieves PFD input files from database and sets the appropriate properties of this page.
   * @param projectId: project ID this PFD belongs to
   * @param processId: ID of the current PFD
   */
  private getProcessInputs(projectId: string, processId: string): void {
    this.processService.getProcessInputs(projectId, processId).subscribe({
      next: (response: ProcessInput[]) => {
        this.pfd.Inputs = response;
        this.nrOfPfdInputsSet = 0;
        // set each input file name to the corresponding one from the inputs
        for (let i = 0; i < response.length; i++) {
          switch (response[i].Key) {
            case ProcessInputKey.BaseCase:
              this.baseCaseFileName = response[i].Value;
              this.nrOfPfdInputsSet++;
              break;
            case ProcessInputKey.SummerCase:
              this.summerCaseFileName = response[i].Value;
              this.nrOfPfdInputsSet++;
              break;
            case ProcessInputKey.WinterCase:
              this.winterCaseFileName = response[i].Value;
              this.nrOfPfdInputsSet++;
              break;
          }
        }
      }
    });
  }

  /**
   * Creates a new PFD and saves it in the database.
   * */
  private createPfd() {
    this.pfd = new Pfd();
    this.pfd.ProjectId = this.projectId;
    this.pfd.ProcessName = this.pfdName;
    this.pfd.ProcessDescription = '';
    this.pfd.Status = 1;
    this.pfd.TempProcessId = this.tempGuid;
    return this.processService.createProcess(this.pfd).toPromise();
  }

  /**
   * Updates the input files of the current PFD in the database.
   * */
  private updatePfd() {
    if (this.pfd.Inputs === undefined) {
      this.pfd.Inputs = [];
    }
    this.nrOfPfdInputsSet = 0;
    if (this.baseCaseFileName) { // set base case value
      this.nrOfPfdInputsSet++;
      this.updatePfdInputCase(ProcessInputKey.BaseCase, this.baseCaseFileName);
    }
    if (this.summerCaseFileName) { // set summer case value
      this.nrOfPfdInputsSet++;
      this.updatePfdInputCase(ProcessInputKey.SummerCase, this.summerCaseFileName);
    }
    if (this.winterCaseFileName) { // set winter case value
      this.nrOfPfdInputsSet++;
      this.updatePfdInputCase(ProcessInputKey.WinterCase, this.winterCaseFileName);
    }
    return this.processService.updateProcessInputs(this.pfd).toPromise();
  }

  /**
   * Updates the appropriate input of the PFD, to the given file name.
   * @param key: determines the case for which the file will be updated (Base|Summer|Winter)
   * @param fileName: contains the name of the new input file
   */
  updatePfdInputCase(key: ProcessInputKey, fileName: string) {
    let keyIndex = -1;
    for (let i = 0; i < this.pfd.Inputs.length; i++)
      if (this.pfd.Inputs[i].Key === key)
        keyIndex = i;
    if (keyIndex === -1) { // this key was not defined yet
      this.pfd.Inputs.push({
        InputType: "File",
        Key: key,
        Value: fileName
      });
    } else {
      this.pfd.Inputs[keyIndex].Value = fileName;
    }
  }

  /**
   * Sets the stage of the current PFD in the database.
   * @param stateval
   */
  private setPfdState(stateval: number) {
    let result = this.processService.setProcessState(this.pfd.ProjectId, this.pfd.ProcessId, stateval);
    this.pfd.Status = stateval;
    return result;
  }
}
