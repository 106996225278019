import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import * as maplibregl from 'maplibre-gl';
import { LngLatLike, LngLat } from 'maplibre-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

@Component({
  selector: 'app-evprojects',
  templateUrl: './evprojects.component.html',
  styleUrls: ['./evprojects.component.scss']
})
export class EVProjectsComponent implements OnInit {
  map: maplibregl.Map;
  draw: any;
  states: string[] = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"];
  currentCoordinates: any;
  currentZoomLevel: number;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.initializeMap();
  }

  initializeMap(): void {
    this.map = new maplibregl.Map({
      container: 'map',
      style: {
        version: 8,
        sources: {
          'esri': {
            type: 'raster',
            tiles: [
              'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
            ],
            tileSize: 256
          }
        },
        layers: [
          {
            id: 'esri-layer',
            type: 'raster',
            source: 'esri',
            minzoom: 0,
            maxzoom: 22
          }
        ]
      },
      interactive: true,
    });

    // Initialize search functionality
    const searchInput = document.getElementById('search') as HTMLInputElement;
    const searchCity = document.getElementById('searchCity') as HTMLInputElement;
    const zipCode = document.getElementById('zipCode') as HTMLInputElement;
    const stateSelect = document.getElementById('state') as HTMLSelectElement;

    const keyupHandler = () => {
      const query = `${searchInput.value} ${searchCity.value} ${zipCode.value} ${stateSelect.value}`;
      this.searchLocation(query.trim());
    };

    const changeHandler = () => {
      keyupHandler();
    };

    stateSelect.addEventListener('change', changeHandler);

    document.addEventListener('keyup', (event) => {
      if (event.target === searchInput || event.target === searchCity || event.target === zipCode || event.target === stateSelect) {
        if (event.key === 'Enter') {
          keyupHandler();
        }
      }
    });

    // Listen for map moveend event to get coordinates and zoom level
    this.map.on('moveend', () => {
      const { lng, lat } = this.map.getCenter();
      const zoom = this.map.getZoom();

      this.currentCoordinates = [lng, lat];
      this.currentZoomLevel = zoom;
      console.log('Coordinates:', this.currentCoordinates);
      console.log('Zoom Level:', this.currentZoomLevel);
    });
  }

  private searchLocation(query: string): void {
    fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${query}`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          const result = data[0];
          const [lon, lat] = [result.lon, result.lat];
          this.map.flyTo({ center: [lon, lat], zoom: 14 });
        }
      });
  }

  onConfirm(): void {    
    // Navigate to the next page while passing coordinates and zoom level as query parameters
    const center = this.map.getCenter();
    const zoom = this.map.getZoom();

    this.router.navigate(['/evmaplarcgisv2'], { queryParams: { coordinates: JSON.stringify([center.lng, center.lat]), zoom: zoom } });
  }
}
