import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as maplibregl from 'maplibre-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { ActivatedRoute } from '@angular/router';
import { LngLatLike, LngLat } from 'maplibre-gl';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';

@Component({
  selector: 'app-ev-mapl-arcgis-v2',
  templateUrl: './ev-mapl-arcgis-v2.component.html',
  styleUrls: ['./ev-mapl-arcgis-v2.component.scss']
})
export class EvMaplArcgisV2Component implements OnInit {
  panelOpenState = false;
  map: maplibregl.Map;
  draw: any;
  activeFillColor: string = '#0FF000'; // Default active fill color
  polygonsLoaded: boolean = false;
  coordinates: LngLatLike;
  drawnPolygons: any[] = [];
  // drawnPolygons: any[] = [
  //   {
  //     id: '1f1c45f68c7759d9c36d8d9d0c564b4a',
  //     type: 'Feature',
  //     geometry: {
  //       type: 'Polygon',
  //       color: '#00ff00',
  //       coordinates: [
  //         [
  //           [-117.28928762030124, 34.068414129401276],
  //           [-117.28928762030124, 34.06760092490697],
  //           [-117.2891266877603, 34.067654250030714],
  //           [-117.28863852571965, 34.06767202506465],
  //           [-117.28862243246536, 34.06852522230528],
  //           [-117.28859024595735, 34.068587434268],
  //           [-117.28870826315386, 34.06859187797829],
  //           [-117.28928762030124, 34.068414129401276]
  //         ]
  //       ] // Example coordinates for polygon 1
  //     },
  //   },
  //   {
  //     id: '44c461cfa7ba437ee2a5c9f8ad5c089b',
  //     type: 'Feature',
  //     geometry: {
  //       type: 'Polygon',
  //       color: '#00ff00',
  //       coordinates: [
  //         [
  //           [-117.28929298471914, 34.06748094325519],
  //           [-117.28929834913734, 34.06671216789886],
  //           [-117.28858488153915, 34.06671216789886],
  //           [-117.28859024595735, 34.067587593620544],
  //           [-117.289078407998, 34.06759203738278],
  //           [-117.28929298471914, 34.06748094325519]
  //         ]
  //       ] // Example coordinates for polygon 1
  //     },
  //   }
  // ];
  zoom: number;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.coordinates = JSON.parse(params['coordinates']);
      this.zoom = +params['zoom'];

      this.initializeMap();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initializeMap();
      this.loadPolygons(this.drawnPolygons);
    }, 0);
  }

  initializeMap(): void {
    this.map = new maplibregl.Map({
      container: 'map',
      style: {
        version: 8,
        sources: {
          'esri': {
            type: 'raster',
            tiles: [
              'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
            ],
            tileSize: 256
          }
        },
        layers: [
          {
            id: 'esri-layer',
            type: 'raster',
            source: 'esri',
            minzoom: 0,
            maxzoom: 22
          }
        ]
      },
      interactive: false,
      center: [this.coordinates[0], this.coordinates[1]],
      zoom: this.zoom,
    });

    // Initialize Mapbox Draw plugin
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      userProperties: true,
      controls: {
        polygon: true,
        trash: true
      },
      styles: [
        {
          'id': 'gl-draw-polygon-fill',
          'type': 'fill',
          'filter': ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
          'paint': {
            'fill-color': this.activeFillColor,
            'fill-outline-color': '#000',
            'fill-opacity': 0.5
          }
        },
        {
          'id': 'gl-draw-polygon-stroke-active',
          'type': 'line',
          'filter': ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
          'layout': {
            'line-cap': 'round',
            'line-join': 'round'
          },
          'paint': {
            'line-color': this.activeFillColor,
            'line-width': 2
          }
        },
        {
          'id': 'gl-draw-polygon-and-line-vertex-halo-active',
          'type': 'circle',
          'filter': ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
          'paint': {
            'circle-radius': 5,
            'circle-color': '#FFF'
          }
        },
        {
          'id': 'gl-draw-polygon-and-line-vertex-active',
          'type': 'circle',
          'filter': ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
          'paint': {
            'circle-radius': 3,
            'circle-color': this.activeFillColor
          }
        }
      ]
    });

    // Add Draw control to the map
    this.map.addControl(this.draw);

    // Listen for draw events
    this.map.on('draw.create', event => {
      const newPolygon = event.features[0];
      if (newPolygon.geometry.type === 'Polygon') {
        this.drawnPolygons.push(newPolygon);
      }
    });
  }

  loadPolygons(polygonsData: { type: string, geometry: { type: string, color: string, coordinates: number[][][] } }[]): void {
    polygonsData.forEach(polygonData => {
      const { type, geometry } = polygonData;
      const { color, coordinates } = geometry;
      const geojson = {
        type: type,
        geometry: {
          type: 'Polygon',
          coordinates: coordinates
        },
        properties: {}
      };
      // Add the GeoJSON feature to the draw control
      this.draw.add(geojson);
    });

    // Log loaded polygons
    const style = this.map.getStyle();
    if (style && style.layers) {
      const polygonLayers = style.layers.filter(layer => layer.type === 'fill');
      polygonLayers.forEach(layer => {
        console.log('Loaded polygon:', layer.id);
      });
    } else {
      console.log('Map style or layers not available.');
    }
  }

  selectColor(color: string): void {
    // Change active fill color
    this.activeFillColor = color;
    // Change draw style to the selected color
    this.draw.changeMode('draw_polygon', {
      'polygon': {
        'activeFillColor': color // Set active fill color
      }
    });
  }

  // Function to edit a polygon by its ID
  editPolygon(id: string): void {
    // Implement logic to edit the polygon based on its ID
    console.log('Editing polygon with ID:', id);
  }

  // Function to delete a polygon by its ID
  deletePolygon(id: string): void {
    // Implement logic to delete the polygon based on its ID
    console.log('Deleting polygon with ID:', id);
    this.drawnPolygons = this.drawnPolygons.filter(polygon => polygon.id !== id);

    // Remove the drawn polygon from the map
    if (this.draw) {
      try {
        this.draw.delete([id]);
      } catch (error) {
        console.error('Error deleting polygon:', error);
      }
    } else {
      console.error('Mapbox Draw is not initialized.');
    }
  }
}
