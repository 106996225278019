import { Component, OnInit } from '@angular/core';
import { NgxPowerBiService } from 'ngx-powerbi';
import { ElementRef, ViewChild } from '@angular/core';

import * as pbi from 'powerbi-client';
import { CommonService } from './../../../services/common.service';


@Component({
  selector: 'app-show-map',
  templateUrl: './show-map.component.html',
  styleUrls: ['./show-map.component.scss']
})
export class ShowMapComponent implements OnInit {
  @ViewChild('reportContainer') $reportContainer: ElementRef;
  powerBiConf: any;

  constructor(private commonService: CommonService,
    private powerBiService: NgxPowerBiService
  ) { }

  ngOnInit(): void {
    this.loadPowerBI();
  }

  loadPowerBI() {
    this.commonService.getPowerBiToken().subscribe({
      next: (response: any) => {
        this.powerBiConf = response;
        var models = pbi.models;

        var embedConfiguration = {
          type: 'report',
          id: response.ReportId,
          embedUrl: this.powerBiConf.EmbedUrl,
          tokenType: models.TokenType.Embed,
          accessToken: this.powerBiConf.EmbedToken,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false
          }
        };
        this.powerBiService.embed(this.$reportContainer.nativeElement, embedConfiguration);
      }
    });

  }

}
