<div class="begin-page">
  <h1>List of projects</h1>
  <p class="half-width">Select a project from the list below to continue on the optimization journey or create a new one.</p>
</div>

<app-toaster-container></app-toaster-container>

<div class="container-fluid grey-container">
  <div class="row">
    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let project of projects; let i=index">
      <app-tile *ngIf="i===0" [tileInput]="project"
                [buttonText]="'CREATE NEW PROJECT'"
                [targetPath]="'/createproject'"
                [tileType]="'new'"
                [minContentHeight]="180">
      </app-tile>
      <app-tile *ngIf="i!==0" [tileInput]="project" 
                [buttonText]="'OPEN ' + (project.name | uppercase)"
                [targetPath]="'/project/' + (project.id)"
                [tileType]="'project'"
                [minContentHeight]="180">
      </app-tile>
    </div>
  </div>
</div>
