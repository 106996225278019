import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Breadcrumb } from '../components/ReusableComponents/breadcrumb/model/breadcrumb.model';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  // Subject emitting the breadcrumb hierarchy
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

  // Observable exposing the breadcrumb hierarchy
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  private breadcrumbs: Breadcrumb[] = [];

  constructor(private router: Router) {
    this.router.events.pipe(
      // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(event => {
      // Emit the new breadcrumb hierarchy
      this._breadcrumbs$.next(this.breadcrumbs);
    });
  }

  /**
   * Constructs the parts of the breadcrumbs based on a given URL and the labels.
   * There will be as many breadcrumbs as labels.
   * e.g., if
   *        labels = ['Label 1', 'Label 2', 'Label 3']
   *        url = '/main/part1/part2/part3/part4/part5'
   *      then
   *        breadcrumbs = {
   *          'Label 1' -> '/main/part1/part2/part3'
   *          'Label 2' -> '/main/part1/part2/part3/part4'
   *          'Label 3' -> '/main/part1/part2/part3/part4/part5'
   *        }
   * @param labels List of labels to display for the breadcrumbs.
   * @param url link to break up into parts
   */
  constructBreadcrumb(labels: string[], url?: string) {
    if (url === undefined) {
      url = this.router.url;
    }    
    this.breadcrumbs = [];
    let urlParts = url.split('/');
    labels.forEach((label, index) => {
      let subUrl = urlParts.slice(0, urlParts.length - labels.length + index + 1).join('/');
      this.breadcrumbs.push({
        label: label,
        url: subUrl
      });
    });
  }

  /**
   * Updates the url of an existing breadcrumb with the given label.
   * @param label the label of the breadcrumb for which the URL will be replaced, label should be an existing one otherwise there will be no effect
   * @param url new URL for the breadcrumb
   */
  updateBreadcrumb(label: string, url: string) {
    for (let b of this.breadcrumbs) {
      if (b.label === label) {
        b.url = url;
        break;
      }
    }
  }

}
