import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { ProcessService } from '../../../../services/process.service';

@Component({
  selector: 'create-pfd',
  templateUrl: './create-pfd.component.html',
  styleUrls: ['./create-pfd.component.scss']
})
export class CreatePfdComponent implements OnInit, OnDestroy {
  projectId: string;
  processId: string;
  state: number;
  paramSub: any;
  navigationSub: any;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private processService: ProcessService) { }

  ngOnInit(): void {
    this.projectId = this.route.snapshot.paramMap.get('projectid');
    this.processId = this.route.snapshot.paramMap.get('processid');

    if (this.processId !== 'null') {
      this.getPfdState();
    }
    
    // ensures page reload if navigation is performed to the same URL
    // there are modifications in routing module too
    // source: https://medium.com/engineering-on-the-incline/reloading-current-route-on-click-angular-5-1a1bfc740ab2
    this.navigationSub = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalize PFD stage
      this.processId = this.route.snapshot.paramMap.get('processid');
      if (e instanceof NavigationEnd) {
        if (this.processId !== 'null') {
          this.getPfdState();
        }
      }
    });
    
  }

  ngOnDestroy() {
    if (this.navigationSub) {  
      this.navigationSub.unsubscribe();
    }
  }

  async getPfdState() {
    this.processService.getProcessState(this.projectId, this.processId).then((response) =>{
      this.state = response.state;
    });
  }
}
