import { ChangeContext } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gdp-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent implements OnInit {
  @Input() id: string = 'PipeLength';
  @Input() title: string = 'Pipe length';
  @Input() minValue: number = 10;
  @Input() maxValue: number = 300;
  @Input() lowValue: number = 80;
  @Output() lowValueChange = new EventEmitter<number>();
  @Input() highValue: number = 170;
  @Output() highValueChange = new EventEmitter<number>();
  @Input() changeEvent: Function = (id: string, lowValue: number, highValue: number) => {}

  constructor() {
  }

  ngOnInit(): void {
  }

  sliderChange(event: ChangeContext) {
    this.changeEvent(this.id, this.lowValue, this.highValue);
    this.lowValueChange.emit(this.lowValue);
    this.highValueChange.emit(this.highValue);
  }

}
