<table class="map-container">
  <tbody>
    <tr class="d-flex">
      <td rowspan="2" class="col-4">
      </td>
      <td class="col-8">
        <button mat-raised-button (click)="onConfirm()">CONFIRM TO CONTINUE</button>
      </td>
    </tr>
    <tr class="d-flex" >
      <td class="col-4">
        <div class="search-input-container">
          <label class="gdp-disabled no-bottom" for="name">Site Address</label>
          <mat-form-field floatLabel="never">
            <input matInput id="search" type="text" placeholder="Enter Site Address..."  autocomplete="off" />
          </mat-form-field>
        </div>
        <div class="search-input-container">
          <label class="gdp-disabled no-bottom" for="name">Search City</label>
          <mat-form-field floatLabel="never">
            <input matInput id="searchCity" type="text" placeholder="Enter City..." autocomplete="off">
          </mat-form-field>
        </div>
        <div class="search-input-container">
          <mat-form-field>
            <mat-label>Select State</mat-label>
            <select matNativeControl id="state">
              <option value="">-- Select State --</option>
              <option *ngFor="let state of states" [value]="state">{{ state }}</option>
            </select>
          </mat-form-field>
        </div>
        <div class="search-input-container">
          <label class="gdp-disabled no-bottom" for="name">Zip Code</label>
          <mat-form-field floatLabel="never">
            <input matInput id="zipCode" type="text" autocomplete="off">
          </mat-form-field>
        </div>
      </td>
      <td class="col-8">
        <div id="map"></div>
      </td>
    </tr>
  </tbody>
</table>