/**
 * Defines a configurable modal component (pop-up window).
 * Sources:
 *  https://medium.com/swlh/how-to-create-a-modal-dialog-component-in-angular-8-88028d909be0
 *  https://levelup.gitconnected.com/how-to-create-a-reusable-modal-dialog-component-in-angular-8-241cc738d260
 *  https://blog.angular-university.io/angular-material-dialog/
 * */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'gdp-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
  ) { }

  ngOnInit(): void {
  }

  static defaultConfig(): MatDialogConfig {
    let dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '250px';
    dialogConfig.width = '560px';
    dialogConfig.backdropClass = 'backdropBackground';
    dialogConfig.data = {
      title: 'ATTENTION',
      description: 'Are you sure you want to exit?',
      hasCancelButton: true,
      cancelButtonText: 'CANCEL',
      cancelButtonType: '',
      cancelButtonCallback: () => {return null},// must be provided from outside// must be provided if (hasCancelButton === true)
      okButtonText: 'OK',
      okButtonType: 'new',
      okButtonCallback: () => {return null},// must be provided from outside // this must be provided from outside
    }
    return dialogConfig
  }

}
