import { NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { HeaderService } from 'src/app/services/header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public projectId: string;
  public currentPage: string;

  headerTitle: string

  constructor(
    private router: Router,
    private headerService: HeaderService) {
    this.currentPage = '';
  }

  ngOnInit(): void {
    this.router.events.pipe(
      // detect navigation events and update header tabs based on current URL
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      const urlParts = this.router.url.split('/');
      // the parts of the URL will be according to the routes defined in "app-routing.module.ts"
      if (urlParts.length >= 3 && urlParts[1] === 'project') {
        this.projectId = urlParts[2];
        if (urlParts.length >= 4) {
          this.currentPage = urlParts[3];
          if(urlParts[4] === 'gbom') {
            this.currentPage = urlParts[4];
            this.headerService.headertitle.subscribe((result) => {
              this.headerTitle = result;
            });
          }
        }
      } else if (urlParts[1] === '' || urlParts[1] === 'industry') {
        this.projectId = '';
        this.currentPage = 'industry';
      } 
       else {
        this.projectId = '';
        this.currentPage = '';
      }
    });
  }

}

