import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { CacheService } from '../../../../services/cache.service';
import { Pfd } from '../../../../models/process';
import { ProcessService } from '../../../../services/process.service';
import { TileInput } from '../../../../models/tile.input';

@Component({
  selector: 'app-list-pfd-page',
  templateUrl: './list-pfd-page.component.html',
  styleUrls: ['./list-pfd-page.component.scss']
})
export class ListPfdPageComponent implements OnInit {
  pfds: TileInput[] = [];
  projectId: string;
  subscription: any;
  projectName: string;

  constructor(
    private route: ActivatedRoute,
    private cacheService: CacheService,
    private processService: ProcessService) {
    this.pfds[0] = new TileInput();
    this.pfds[0].name = 'New Process Flow Diagram';
    this.pfds[0].description = '';
  }

  ngOnInit(): void {
    this.subscription = this.route.params.subscribe(params => {
      this.projectId = params['projectid'];
    });

    this.cacheService.getFromCacheObs(this.projectId, 'projectName').subscribe({
      next: (response: string) => {
        this.projectName = response;
      },
      error: (err) => { }
    });

    this.getPfdProcesses();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getPfdProcesses() {
    let pfdType = "Automated PFD";
    this.processService.getProcesses(this.projectId, pfdType).subscribe({
      next: async (response: Pfd[]) => {
        if (!response) { // response is empty
          return
        }
        for (let i = 0; i < response.length; i++) {
          this.pfds[i + 1] = new TileInput();
          this.pfds[i + 1].name = response[i].ProcessName.toUpperCase();
          this.pfds[i + 1].id = response[i].ProcessId;
          this.pfds[i + 1].description = {
            'state': response[i].Status,
            'maxState': Pfd.MAXSTAGES,
            'createTime': response[i].CreateTimeStamp
          }
        }
      }
    });
  }
}
