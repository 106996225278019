import { Component, ViewChild, OnInit } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-control-geocoder';
import 'leaflet-draw';
import 'leaflet-geometryutil';
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch';

@Component({
  selector: 'app-ev-layout',
  templateUrl: './ev-layout.component.html',
  styleUrls: ['./ev-layout.component.scss'],
})
export class EvLayoutComponent implements OnInit {
  panelOpenState = false;
  private map: any;
  private drawControl: any;
  private drawnItems: any;
  private drawingEnabled: boolean = false;
  private selectedColor: string = 'green'; // Default color

  constructor() { }

  ngOnInit(): void {
    this.initMap();
  }

  private initMap(): void {
    const satellite = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Tiles © Esri',
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 20,
      attribution: '&copy; OpenStreetMap contributors'
    });

    this.map = L.map('map', {
      center: [34.06800975, -117.28795104202172],
      layers: [satellite],
      zoom: 18,
      scrollWheelZoom: false,  // Disable mouse wheel zoom
      dragging: false,        // Disable dragging (pan)
      doubleClickZoom: false, // Disable double-click zoom
      boxZoom: false,         // Disable box zoom
      touchZoom: false        // Disable touch zoom
    });

    tiles.addTo(this.map);

    const baseMaps = {
      "Map View": tiles,
      "Satellite View": satellite
    };

    L.control.layers(baseMaps).addTo(this.map);

    this.drawnItems = new L.FeatureGroup();
    this.map.addLayer(this.drawnItems);

    this.drawControl = new L.Control.Draw({
      edit: {
        featureGroup: this.drawnItems
      },
      draw: {
        polygon: {
          shapeOptions: {
            color: 'green'
          }
        },
        polyline: true,
        rectangle: true,
        circle: false,
        marker: false,
        circlemarker: false
      }
    });

    this.map.removeControl(this.drawControl);

    this.map.on(L.Draw.Event.CREATED, (event: any) => {
      const layer = event.layer;
      this.drawnItems.addLayer(layer);

      const latLngs = layer.getLatLngs()[0]; // Assuming it's a simple polygon
      const coordinates = latLngs.map((latLng: any) => [latLng.lng, latLng.lat]);
      console.log('Polygon Coordinates:', coordinates);
    });

    L.control.scale().addTo(this.map);

    // GeoSearch Control
    const provider = new OpenStreetMapProvider();
    const searchControl = GeoSearchControl({
      provider: provider,
      style: 'bar',
      autoComplete: true,
      autoCompleteDelay: 250,
      retainZoomLevel: false,
      animateZoom: true,
      keepResult: true
    });

    this.map.addControl(searchControl);

    this.map.zoomControl.disable();

    this.map.on('geosearch/showlocation', (result: any) => {
      const location = result.location;
      this.map.setView([location.y, location.x], 18);
      console.log(result);
    });
  }

  // setPolygonColor(color: string): void {
  //   this.selectedColor = color;
  // }

  // toggleDrawing(): void {
  //   if (this.drawingEnabled) {
  //     this.map.removeControl(this.drawControl);
  //   } else {
  //     this.map.addControl(this.drawControl);
  //   }
  //   this.drawingEnabled = !this.drawingEnabled;
  // }

  setPolygonColorAndToggle(color: string): void {
    this.selectedColor = color;

    // Update draw control with new color
    this.map.removeControl(this.drawControl);
    this.drawControl = new L.Control.Draw({
      edit: {
        featureGroup: this.drawnItems
      },
      draw: {
        polygon: {
          shapeOptions: {
            color: this.selectedColor,
            fillOpacity: 0.2,
          }
        },
        polyline: false,
        rectangle: false,
        circle: false,
        marker: false,
        circlemarker: false
      }
    });

    this.drawingEnabled = !this.drawingEnabled;

    if (this.drawingEnabled) {
      this.map.addControl(this.drawControl);
    } else {
      this.map.removeControl(this.drawControl);
    }
  }
}
