import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DownloadService } from 'src/app/services/download.service';
import { ProcessService } from 'src/app/services/process.service';

@Component({
  selector: 'gdp-usecase-modal',
  templateUrl: './usecase-modal.component.html',
  styleUrls: ['./usecase-modal.component.scss']
})

export class UsecaseModalComponent implements OnInit, OnDestroy {
  url: any;
  downloadSpinner = false;
  sampleFileVisibility: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UsecaseModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private downloadService: DownloadService,
    private processService: ProcessService
  ) { }

  ngOnInit(): void { }

  ngOnDestroy() {
  }

  static defaultConfig(): MatDialogConfig {
    let dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.id = 'usecase-modal-component';
    dialogConfig.height = '520px';
    dialogConfig.width = '920px';
    dialogConfig.backdropClass = 'backdropBackground';
    dialogConfig.data = {
      title: 'DEMO',
      descriptionTitle: 'Description',
      description: [
        {
          "subtitle": "Subtitle 1",
          "content": "Description 1"
        },
        {
          "subtitle": "Subtitle 1",
          "content": "Description 1"
        }
      ],
      videoButtonText: 'Demo Video',
      videoButtonType: 'new',
      videoButtonCallback: () => { return null }, // must be provided from outside
      launchButtonText: 'LAUNCH',
      launchButtonType: 'new',
      launchButtonCallback: () => { return null }, // must be provided from outside
      stageZeroDeckButtonText: 'Stage 0 Deck',
      stageZeroDeckButtonType: 'new',
      stageZeroDeckButtonCallback: () => { return null },// must be provided from outside
      manyDocumentsButtonText: [],
      manySampleFiles: []
    }
    return dialogConfig
  }

  openDocumentInTab(link: string) {
    window.open(link, "_blank");
  }

  downloadDocument = (): void => {
    this.getDownloadFilePathAndSASToken(this.modalData.scriptKey);
  }

  downloadFirstDocument = (): void => {
    this.getDownloadFilePathAndSASToken(this.modalData.firstdocumentKey);
  }

  downloadSecondDocument = (): void => {
    this.getDownloadFilePathAndSASToken(this.modalData.seconddocumentKey);
  }

  downloadDocumentSampleFile = (filekey: string): void => {
    this.getDownloadFilePathAndSASToken(filekey);
  }

  getDownloadFilePathAndSASToken(regFile: any) {
    this.downloadSpinner = true;
    this.processService.getDownloadFilePathAndSASToken(regFile).then((result) => {
      (this.downloadService.downloadFile(result.sasToken, result.path)).then(res => {
        this.downloadSpinner = false;
      });
    });
  }

  displaySampleFiles = (): void => {
    if (this.sampleFileVisibility == true) {
      this.sampleFileVisibility = false;
    }
    else if (this.sampleFileVisibility == false) {
      this.sampleFileVisibility = true;
    }
  }

}