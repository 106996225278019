import { ProcessKPI } from '../../../../../models/processKPI';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

import { CacheService } from '../../../../../services/cache.service';
import { DownloadService } from 'src/app/services/download.service';
import { Pfd, PfdDiagram } from '../../../../../models/process';
import { PfdService } from '../../../../..//services/pfd.service';
import { ProcessService } from '../../../../../services/process.service';
import { ToasterService } from '../../../../../services/toaster.service';
//import * as Chart from 'chart.js';
//import { LocalKPI } from 'src/app/models/project';


@Component({
  selector: 'pfd-diagram-list',
  templateUrl: './pfd-diagram-list.component.html',
  styleUrls: ['./pfd-diagram-list.component.scss']
})
export class PfdDiagramListComponent implements OnInit, OnDestroy {
  private projectId: string;
  private processId: string;
  private sub: any;
  projectName: string;
  interval: any;

  readonly maxPfdStages: number = Pfd.MAXSTAGES;
  currentPfdStage: number = 3;
  pfdDiagrams: PfdDiagram[];
  processKPIs: ProcessKPI[];
  pfd: Pfd;
  checkedDiagramsList: string[] = [];
  enableExportButton: string = "disabled";
  downloadSpinner: boolean = false;

  /*public chartConfig: any;
  public localKeyMetricNames: string[];
  public localKeyMetricValues: { label: string, data: number[] }[];
  public chartColors: string[];
  public selectedIndex: number;
  public deletedIndexes: boolean[];*/

  constructor(
    private cacheService: CacheService,
    private downloadService: DownloadService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toaster: ToasterService,
    private pfdService: PfdService,
    private processService: ProcessService) {
    //this.selectedIndex = 0;
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.projectId = params['projectid'];
      this.processId = params['processid'];
    });

    this.cacheService.getFromCacheObs(this.projectId, 'projectName').subscribe({
      next: (response: string) => {
        this.projectName = response;
      },
      error: (err) => { }
    });

    this.getProcessProperties();
    this.getPfdGenerationStatus();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.toaster) {
      this.toaster.unsubscribe();
    }
  }

  private getProcessProperties(): void {
    this.processService.getProcessById(this.projectId, this.processId).subscribe({
      next: (response: Pfd) => {
        this.pfd = response;
      }
    });
  }

  getPfdGenerationStatus() {
    this.spinner.show();

    this.interval = setInterval(() => {
      this.processService.getPfdGenerationStatus(this.projectId, this.processId).then((result) => {
        if (result["runtimeStatus"] == "Completed") {
          clearInterval(this.interval);
          if (result['output'] === 'Error') {
            this.pfdDiagrams = []
            this.spinner.hide();
            this.toaster.show('error', 'GENERATION FAILED: Something went wrong on the server side. Please try again later.', '');
          } else {
            this.getGeneratedPFDs();
            //this.getKPIsAndChartOptions();
          }
        }
      });
    }, 1000);
  }

  getGeneratedPFDs() {
    this.pfdService.getGeneratedPFDs(this.projectId, this.processId).then((result) => {
      this.pfdDiagrams = result["diagrams"];
      for (let i = 0; i < this.pfdDiagrams.length; i++) {
        this.pfdDiagrams[i].filename = (this.pfdDiagrams[i].filename).substr(0, this.pfdDiagrams[i].filename.indexOf("."));
      }
      this.pfdDiagrams.map(d => d.processId = this.processId);
      this.spinner.hide();
    });
  }

  goToPfdDiagramView(event: any, d: PfdDiagram) {
    if (event.srcElement.localName !== 'td') {
      return;
    }
    this.setPfdState(4).then(() => {
      let url = this.router.url;
      this.router.navigate([url, d.id]);
    });
  }

  arrowClicked(event: any) {
    //arrow clicked
  }

  backButtonCallback = (): void => {
    this.setPfdState(2).then(() => {
      this.router.navigateByUrl(this.router.url);
    });
  }

  goBackToGeneralPfdList = (): void => {
    let url = this.router.url;
    url = url.substr(0, url.lastIndexOf('/'));
    this.router.navigateByUrl(url);
  }

  private async setPfdState(stateval: number) {
    let result = await this.processService.setProcessState(this.projectId, this.processId, stateval);
  }

  checkPfdDiagram(checked: any, diagramId: string /*d: PfdDiagram*/) {
    if (checked) {
      this.checkedDiagramsList.push(diagramId);
      this.enableExportButton = "new";
    }
    else {
      this.checkedDiagramsList.forEach((element, index) => {
        if (element == diagramId) this.checkedDiagramsList.splice(index, 1);
      });

      if (this.checkedDiagramsList.length == 0) this.enableExportButton = "disabled";
    }
  }

  //Download Diagram as PDF
  downloadDocument = (): void => {
    for (let i = 0; i < this.checkedDiagramsList.length; i++) {
      this.downloadFilePathAndSASToken(this.checkedDiagramsList[i]);
    }
  }

  downloadFilePathAndSASToken(diagramId: string) {
    this.processService.getPFDDiagramPDFSASToken(this.projectId, this.processId, diagramId).then((result) => {
      this.downloadSpinner = true;
      this.enableExportButton = "disabled";
      (this.downloadService.downloadFile(result.sasToken, result.path)).then(res => {
        this.downloadSpinner = false;
        this.enableExportButton = "new";
      })
    });
  }

}


  /////////////////////////////////KPIs////////////////////////////////////////////////////
/*
  getKPIsAndChartOptions() {
    this.pfdService.getProcessKPIs(this.projectId, this.processId).then(
      (response) => {
        let clientDesiredOutcome = response.find(x =>
          (!x['OptimizationId'] && x['OptimizationName'].toLocaleLowerCase() === 'client desired outcome'));
        this.localKeyMetricNames = this.getKPInamesInOrder(clientDesiredOutcome['KPIValues']);

        this.localKeyMetricValues = [];
        this.localKeyMetricValues.push({
          label: clientDesiredOutcome['OptimizationName'],
          data: this.getKPIvaluesInNamesOrder(this.localKeyMetricNames, clientDesiredOutcome['KPIValues'])
        });

        // loop through optimization scenarios (PFD process) metrics list
        for (let i = 0; i < response.length; i++) {
          let optScen = response[i];
          if (optScen['OptimizationId'] && optScen['OptimizationName'].toLocaleLowerCase() !== 'client desired outcome') {
            this.localKeyMetricValues.push({
              label: optScen['OptimizationName'],
              data: this.getKPIvaluesInNamesOrder(this.localKeyMetricNames, optScen['KPIValues'])
            });
          }
        }

        this.localKeyMetricNames = this.localKeyMetricNames.map(x => x.toUpperCase());
        this.deletedIndexes = Array(this.localKeyMetricNames.length).fill(false);

        // define the key metrics chart options using the data above
        this.chartColors = this.getChartColors(this.localKeyMetricValues.length);
        this.defineChartConfig();
      }
    ).catch((err) => {
      this.toaster.show('error', 'Error:' + err, '');
    });
  }

  legendClickCallback = (event: any, index: number) => {
    if (index === this.selectedIndex || event.srcElement.localName === 'mat-icon' ||
      this.deletedIndexes[index]) {
      return
    }

    this.selectedIndex = index;
    this.legendHoverCallback(event, -1, 0); // index -1 means that all datasets will be shown
  }

  legendHoverCallback = (event: any, index: number, hide: number) => {
    if (this.deletedIndexes[index]) { // do not alter dataset if it was deleted
      return
    }
    // get current chart
    let charts = Chart.instances;
    let k = Object.keys(charts)
    let radarChart = charts[k[0]]; // we know it is the first one, since there is only one chart

    for (let i = 0; i < radarChart.data.datasets.length; i++) {
      if (this.deletedIndexes[i]) { // if it was deleted, leave it alone
        continue
      }
      let meta = radarChart.getDatasetMeta(i);
      if (i !== index) {
        meta.hidden = !!hide; // hide or show dataset depending on the variable
      }
    }

    radarChart.update();
  }

  closeClickCallback = (event: any, index: number) => {
    if (event.srcElement.localName !== 'mat-icon') {
      return
    }
    if (this.selectedIndex === -1) { // none of the datasets is selected
      // we are adding the dataset back now
      this.selectedIndex = index;
    } else {
      if (index === this.selectedIndex) {
        this.selectedIndex = -1; // we are removing the selected dataset
      }
    }
    // get current chart
    let charts = Chart.instances;
    let k = Object.keys(charts)
    let radarChart = charts[k[0]]; // we know it is the first one, since there is only one chart

    this.deletedIndexes[index] = !this.deletedIndexes[index];
    for (let i = 0; i < radarChart.data.datasets.length; i++) {
      let meta = radarChart.getDatasetMeta(i);
      meta.hidden = this.deletedIndexes[i]; // hide/show based on the deletedIndexes
    }
    radarChart.update();
  }

  defineChartConfig() {
    this.chartConfig = {
      type: 'radar',
      labels: this.localKeyMetricNames,
      datasets: this.getChartDatasets(),
      colors: this.chartColors,
      options: {
        startAngle: -30,
        scale: {
          gridLines: { // these are the circle/polygon gridlines
            circular: true,
            color: '#525460',
            lineWidth: 1,
            zeroLineColor: 'red'
          },
          angleLines: { // these are the radius gridlines
            color: '#525460',
            lineWidth: 1,
          },
          pointLabels: {
            fontColor: '#01f1ff',
            fontSize: 11
          },
          ticks: {
            beginAtZero: false,
            display: false,
            maxTicksLimit: 11,
            min: 0,
            max: 100,
            stepSize: 10,
          },

        }, // end of scale
        legend: {
          display: false // hide legend, since buttons are used to control the chart
        }
      } // end of options
    }
  }

  getChartDatasets() {
    let Color = Chart.helpers.color;
    let chartData = [];
    for (let i = 0; i < this.localKeyMetricValues.length; i++) {
      chartData.push({
        data: this.localKeyMetricValues[i].data,
        label: this.localKeyMetricValues[i].label,
        order: -i,
        borderColor: Color(this.chartColors[i]).alpha(0.9).rgbString(),
        backgroundColor: Color(this.chartColors[i]).alpha(0.5).rgbString(),
        borderWidth: 2,
        pointRadius: 2,
        pointBorderWidth: 2,
        pointBorderColor: this.chartColors[i],
        pointBackgroundColor: this.chartColors[i],
        pointHoverRadius: 8,
        pointHoverBorderWidth: 3,
        pointHoverBorderColor: 'white',
        pointHoverBackgroundColor: this.chartColors[i],
        hoverBackgroundColor: 'black',
      });
    }
    return chartData
  }

  getChartColors(num_colors: number) {
    // define random distinct colors, where first 3 are the ones from the UX design
    // source: https://apexcharts.com/docs/options/theme/
    let COLORS = [
      '#016aff', '#188659', '#fd4654', '#FEB019'
    ];
    return COLORS.slice(0, num_colors);
  }

  getKPInamesInOrder(kpiValues: { 'KPIType': string, 'KPIValue': number }[]): string[] {
    let s: string[] = [];
    // get KPI keys and sort the alphabetically (ensures always the same order)
    let kpiKeys = Object.keys(LocalKPI).sort();
    kpiKeys.forEach((kpiKey) => {
      let kpiName = LocalKPI[kpiKey];
      ///console.log('kpikey', kpiKey, kpiName);
      let x = kpiValues.find(x => x['KPIType'].toLowerCase() === kpiName.toLowerCase());
      s.push(x['KPIType']);
    });
    return s
  }

  getKPIvaluesInNamesOrder(orderedNames: string[],
    kpiValues: { 'KPIType': string, 'KPIValue': number }[]): number[] {
    let v: number[] = [];
    orderedNames.forEach((kpiKey) => {
      let x = kpiValues.find(x => x['KPIType'].toLowerCase() === kpiKey.toLowerCase());
      v.push(x['KPIValue']);
    });
    return v
    
  }//////////////////////////////////////////////////////////////////////////
*/