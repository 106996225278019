
export class Process {
  ProcessId: string;
  ProjectId: string;
  Type?: string;
  ProcessName: string;
  ProcessDescription: string;
  CreatedBy?: string;
  CreateTimeStamp?: string;
  UpdateTimeStamp?: string;
  Status?: number;
  static MAXSTAGES?;
  Inputs?: ProcessInput[];
  TempProcessId?: string;
}

export class ProcessInput {
  InputType: "File";
  Key: ProcessInputKey;
  Value: string;
}

export enum ProcessInputKey {
  BaseCase = "Base Case",
  SummerCase = "Summer Case",
  WinterCase = "Winter Case",
  ELO3DModel = "ELO 3D Model",
  ELOEquipmentInputCSV = "ELOEquipmentInputCSV",
  ELOPipesInputCSV = "ELOPipesInputCSV",
  Gbom3DModel = "Gbom 3D Model",
  GbomInputXLS = "GbomInputXLS",
  GbomProjectSpecifications = "Gbom Project Specifications",
}

export class Pfd extends Process {
  readonly Type = "Automated PFD";
  static readonly MAXSTAGES?= 4;
}

export class Layout extends Process {
  ProcessLocation: string;
  static readonly Type = "Layout Optimization";
  static readonly MAXSTAGES?= 6; // TODO!!!: change this later
}

export class Gbom extends Process {
  ProcessLocation: string;
  static readonly Type = "GBOM";
}

// mock classes for now
export class PfdDiagram {
  id: string;
  processId?: string;
  filename: string;
  lastEdited: string;
  layout: string;
  status: string;
}

export enum DiagramStatus {
  INPROGRESS = 'In progress',
  CREATED = 'Created',
  REVISED = 'Revised',
}
