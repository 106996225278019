<div class="">
  <div class="unauthorized">
    <span>Unauthorized</span>
  </div>

  <div class="logoAlign">
    <a href="/" class="warningLogo"></a>
  </div>

  <div class="statusCode">
    <span>Status Code: 403</span>
  </div>
</div>
