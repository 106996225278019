<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"><p class="white-spinner"> Generating PFD </p></ngx-spinner>

<div class="begin-page">
  <h1>{{ projectName }}</h1>
  <h2>{{ pfd?.ProcessName }}</h2>
  <p class="half-width">
    This is the list of the generated PFDs. Click on each Page/Drawing to review, edit or export.
    Once completed the PFD's can be exported as a bulk too.
  </p>
</div>

<div class="container-fluid grey-container column-stretch">
  <div class="column-left">
    <h3>GENERATED PFD(S)</h3>
    <hr class="green">
    <div>Designs generated.</div>
    <table class="clickable-list">
      <tr *ngFor="let diagram of pfdDiagrams; let i=index"
          [ngClass]="{'gdp-disabled': diagram.status === 'In progress'}"
          (click)="goToPfdDiagramView($event, diagram)">
        <td>
          <input type="checkbox" id="checkbox-{{ i }}" class="regular-checkbox"
                 (change)="checkPfdDiagram($event.currentTarget.checked, diagram.id)" />
          <label for="checkbox-{{ i }}" class="checkbox"></label>
        </td>
        <td>{{ diagram.filename }}</td>
        <td>
          <label class="gdp-disabled no-bottom">LAYOUT</label>
          <br />
          {{ diagram.layout }}
        </td>
        <td>
          <label class="gdp-disabled no-bottom">LAST EDITED</label>
          <br />
          {{ (diagram.lastEdited === '-') ? diagram.lastEdited : (diagram.lastEdited | date: 'medium') }}
        </td>
        <td>
          <label class="gdp-disabled no-bottom">STATUS</label>
          <br />
          {{ diagram.status | uppercase }}
        </td>
        <td><div class="arrow" (click)="arrowClicked($event)">&#10132;</div></td>
      </tr>
    </table>
  </div>
  <div class="column-right">
    <h3>SUMMARY</h3>
    <hr class="green">
    <div class="attributes-content">COMING SOON...</div>

    <!-- <span class="metricsSubTitle">SUCCESS METRICS</span>
    <div class="chart-legend">
      <div *ngFor="let metric of localKeyMetricValues; let i = index"
          (click)="legendClickCallback($event, i)"
          (mouseover)="legendHoverCallback($event, i, 1)"
          (mouseleave)="legendHoverCallback($event, i, 0)"
          [style.border-color]="chartColors[i]"
          [ngClass]="[(selectedIndex === i && !deletedIndexes[i]) ? 'highlightSelectedChartElement' : 'chartElementDefStyle']"
          [style.text-decoration]="deletedIndexes[i] ? 'line-through' : 'none'">
          {{metric.label | uppercase}}
          <button>
              <mat-icon inline=true (click)="closeClickCallback($event, i)">close</mat-icon>
          </button>
      </div>
    </div>

    <div class="chart-viewer">
        <canvas *ngIf="chartConfig" baseChart #myChart id="chart"
            [chartType]="chartConfig.type"
            [labels]="chartConfig.labels"
            [datasets]="chartConfig.datasets"
            [options]="chartConfig.options"
        ></canvas>
    </div> -->

  </div>
</div>


<gdp-bottom-navigation [maxStages]="maxPfdStages"
                       [currentStage]="currentPfdStage"
                       [isBackButtonVisible]="true"
                       [backButtonText]="'BACK TO GENERATE'"
                       [backButtonType]="'new'"
                       [backButtonCallback]="backButtonCallback"
                       [isCancelButtonVisible]="true"
                       [cancelButtonText]="'CANCEL'"
                       [cancelButtonType]="'disabled'"
                       [cancelButtonCallback]="goBackToGeneralPfdList"
                       [isSaveButtonVisible]="true"
                       [saveButtonText]="'SAVE'"
                       [saveButtonType]="'disabled'"
                       [saveButtonCallback]=""
                       [isNextButtonVisible]="true"
                       [nextButtonText]="'EXPORT'"
                       [nextButtonType]="enableExportButton"
                       [nextButtonCallback]="downloadDocument"
                       [downloadSpinner]="downloadSpinner">
</gdp-bottom-navigation>
