import { Injectable, EventEmitter  } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  public headertitle:EventEmitter<string> = new EventEmitter();

  constructor() { }

  setHeaderTitle(title: string) {
    this.headertitle.emit(title);
  }
}
