export interface Project {
  ProjectId?: string;
  ProjectName: string;
  ProjectDetails: string;
  CreateTimeStamp?: string;
  UpdateTimeStamp?: string;
  CreatedBy?: string;
  Country: string;
  Region: string;
  State: string;
  City: string;
  StartDate: string;
  EndDate: string;
  KPIs?: ProjectKPI;
}

export enum GlobalKPI {
  KPI1 = "Resource Cost",
  KPI2 = "Material Cost",
  KPI3 = "Schedule Optimization",
  KPI4 = "Constructability",
  KPI5 = "Operability",
  KPI6 = "Maintainability",
  KPI7 = "Safety",
  KPI8 = "Construction Emissions",
  KPI9 = "Process Optimization",
}

interface ProjectKPI {
  [GlobalKPI.KPI1]: number;
  [GlobalKPI.KPI2]: number;
  [GlobalKPI.KPI3]: number;
  [GlobalKPI.KPI4]: number;
  [GlobalKPI.KPI5]: number;
  [GlobalKPI.KPI6]: number;
  [GlobalKPI.KPI7]: number;
  [GlobalKPI.KPI8]: number;
  [GlobalKPI.KPI9]: number;
}

export enum LocalKPI {
  KPI1 = "%PROCESS FLOW DIAGRAMS (PFDS)",
  KPI2 = "%REVISED PFDs"
}