<div class="begin-page">
  <h1>{{ projectName }}</h1>
  <h2>
    <span *ngIf="processId === 'null'">Create New </span>
    <span>Process Flow Diagram Set</span>
  </h2>
  
  <p class="half-width">
    To start this process, upload your simulation source file for each case (Base, Summer and Winter case).
  </p>
</div>

<app-toaster-container></app-toaster-container>

<form (ngSubmit)="onSubmit()" [formGroup]="form">

  <div class="form-group">
    <div class="container-fluid grey-container">
      <div class="underline">PROCESS FLOW DIAGRAM DESCRIPTION</div>
      <div class="text-input-container">
        <label class="gdp-disabled" for="name">PFD Name</label>
        <span class="red">*</span>
        <br />
        <input type="text" name="name" class="text-input"
               [ngClass]="{'gdp-disabled': pfd}"
               placeholder="Please type here..."
               [(ngModel)]="pfdName" [ngModelOptions]="{standalone: true}"
               required maxlength="25"
               #nameInput />
        <div class="is-invalid" *ngIf="(submitted && !pfdName) || (nameInput.invalid && nameInput.dirty)">
          <span>&#9888;</span> Name is required
        </div>
      </div>

      <div class="underline">SOURCE INFORMATION</div>
      <table class="upload-field-container">
        <tr>
          <td>
            <label class="gdp-disabled" for="basecase">Base Case</label>
            <br />
            <gdp-file-upload id="basecase" 
                             [(fileName)]="baseCaseFileName"
                             [allowedFileExtensions]="allowedFileExtensions"
                             [(Guid)]="tempGuid"
                             [processId]="processId">
            </gdp-file-upload>
          </td>
          <td>
            <label class="gdp-disabled" for="summercase">Summer Case</label>
            <br />
            <gdp-file-upload id="summercase"
                             [(fileName)]="summerCaseFileName"
                             [allowedFileExtensions]="allowedFileExtensions"
                             [(Guid)]="tempGuid"
                             [processId]="processId">
            </gdp-file-upload>
        </tr>
        <tr>
          <td>
            <label class="gdp-disabled" for="wintercase">Winter Case</label>
            <br />
            <gdp-file-upload id="wintercase"
                             [(fileName)]="winterCaseFileName"
                             [allowedFileExtensions]="allowedFileExtensions"
                             [(Guid)]="tempGuid"
                             [processId]="processId">
            </gdp-file-upload>
          </td>
        </tr>
      </table>
    </div>
  </div>
</form>

<gdp-bottom-navigation [maxStages]="maxPfdStages"
                       [currentStage]="currentPfdStage"
                       [isBackButtonVisible]="false"
                       [backButtonText]=""
                       [backButtonType]=""
                       [backButtonCallback]=""
                       [isCancelButtonVisible]="isCancelButtonVisible"
                       [cancelButtonText]="'CANCEL'"
                       [cancelButtonType]=""
                       [cancelButtonCallback]="goBackToGeneralPfdList"
                       [isSaveButtonVisible]="true"
                       [saveButtonText]="'SAVE'"
                       [saveButtonType]="wasPfdInputChanged() ? '' : 'disabled'"
                       [saveButtonCallback]="saveButtonCallback"
                       [isNextButtonVisible]="true"
                       [nextButtonText]="createButtonText"
                       [nextButtonType]="createButtonType"
                       [nextButtonCallback]="validateData">
</gdp-bottom-navigation>
