import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';

import { CommonService } from './common.service';
import { DataIntegrityCheck } from '../models/data-integrity-check';
import config from '../app-config.json';

@Injectable({
  providedIn: 'root'
})
export class DataIntegrityCheckService {

  constructor(
    private http: HttpClient,
    private commonService: CommonService) {
  }

  async getTableOfPPipes(projectId: string, processId: string) {
    let url = this.commonService.getUrl(config.getDataIntegrityCheckResult, [projectId, processId]);

    let result = await this.http.get<any>(url).pipe(
      retry(3)
    ).toPromise();

    let dataIntegrityChecks: DataIntegrityCheck[] = [];
    let loneStreams = result["Lone_Streams"];
    let allResultsAreValid = loneStreams.length > 0 ? false : true;

    for (let i = 0; i < Object.keys(result).length - 2; i++) {
      dataIntegrityChecks[i] = new DataIntegrityCheck();
      dataIntegrityChecks[i].StreamName = result[i][0];
      dataIntegrityChecks[i].InputEquipmentName = result[i][1];
      dataIntegrityChecks[i].InputEquipmentType = result[i][2];
      dataIntegrityChecks[i].OutputEquipmentName = result[i][3];
      dataIntegrityChecks[i].OutputEquipmentType = result[i][4];
      dataIntegrityChecks[i].IsValid = true;
      dataIntegrityChecks[i].AllResultsAreValid = allResultsAreValid;
    }

    for (let i = 0; i < loneStreams.length; ++i) {
      dataIntegrityChecks[loneStreams[i]].IsValid = false;
    }

    return dataIntegrityChecks;
  }
}
