import { Component, OnInit } from '@angular/core';
import * as maplibregl from 'maplibre-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { MapCompassControl } from './map-compass-control';

@Component({
  selector: 'app-ev-mapl-arcgis',
  templateUrl: './ev-mapl-arcgis.component.html',
  styleUrls: ['./ev-mapl-arcgis.component.scss']
})
export class EvMaplArcgisComponent implements OnInit {
  panelOpenState = false;
  draw: MapboxDraw;
  map: maplibregl.Map;
  mapWidth: any;
  mapHeight: any;
  currentColor: string;

  ngOnInit() {
    this.currentColor = "#1EEE38";

    this.map = new maplibregl.Map({
      container: 'map',
      style: {
        version: 8,
        sources: {
          'esri': {
            type: 'raster',
            tiles: [
              'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
            ],
            tileSize: 256
          }
        },
        layers: [
          {
            id: 'esri-layer',
            type: 'raster',
            source: 'esri',
            minzoom: 0,
            maxzoom: 22
          }
        ]
      },
      interactive: false,
      center: [-117.28795104202172, 34.06800975],
      zoom: 17,
    });

    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true
      },
      styles: this.getDrawStyles()
    });

    this.map.addControl(this.draw);

    this.map.addControl(new maplibregl.ScaleControl(), 'bottom-left');
    this.map.addControl(new MapCompassControl(), 'top-left');

    this.map.on('load', () => {
      // Explicitly disable each interaction handler
      this.map.scrollZoom.disable();
      this.map.dragPan.disable();
      this.map.doubleClickZoom.disable();
      this.map.boxZoom.disable();
      this.map.touchZoomRotate.disable();
      this.map.touchPitch.disable();
      this.map.keyboard.disable();
      this.map.dragRotate.disable();

      this.logMapWidth();
    });

    // Disable map dragging after adding the draw control
    this.map.on('mousedown', (e) => {
      e.preventDefault();
    });

    this.map.on('draw.create', this.logPolygonVertices.bind(this));
  }

  drawPolygon(color: string) {
    this.currentColor = color;
    this.draw.changeMode('draw_polygon', {
      feature: {
        properties: {
          color: color
        }
      }
    });
    console.log("Current active polygon color:", this.currentColor)
  }

  deletePolygon() {
    const selectedFeatures = this.draw.getSelected().features;
    if (selectedFeatures.length) {
      this.draw.delete(selectedFeatures[0].id);
    } else {
      alert('Please select a polygon to delete');
    }
  }

  logPolygonVertices(e: any) {
    const data = e.features[0];
    if (data && data.geometry && data.geometry.type === 'Polygon') {
      const coordinates = data.geometry.coordinates[0];
      const vertices = coordinates.slice(0, -1);
      const zoomLevel = this.map.getZoom();
      console.log('Polygon vertices for global coordinates:', vertices);
      this.localRecCoordinates(vertices);
    }
  }

  logMapWidth(): void {
    const mapContainer = this.map.getContainer();
    this.mapWidth = mapContainer.clientWidth;
    this.mapHeight = mapContainer.clientHeight;
    const zoomLevel = this.map.getZoom();
    console.log('Zoom level:', zoomLevel);
    console.log('Map canvas width:', this.mapWidth);
  }

  localRecCoordinates(coor: any): void {
    var coords = coor.map(coord => {
      const longitude = coord[0];
      const latitude = coord[1];

      const bounds = this.map.getBounds();
      const minLongitude = bounds.getWest();
      const maxLongitude = bounds.getEast();
      const minLatitude = bounds.getSouth();
      const maxLatitude = bounds.getNorth();

      // Normalize longitude and latitude
      const normalizedX = (longitude - minLongitude) / (maxLongitude - minLongitude);
      const normalizedY = (latitude - minLatitude) / (maxLatitude - minLatitude);

      // Map to picture coordinates
      const x = normalizedX * this.mapWidth;
      const y = normalizedY * this.mapHeight;

      return { x, y };
    });
    
    console.log('Converted Local Coordinates:', coords);
  }

  getDrawStyles() {
    return [
      {
        'id': 'gl-draw-polygon-fill',
        'type': 'fill',
        'filter': ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
        'paint': {
          'fill-color': this.currentColor,
          'fill-outline-color': '#000',
          'fill-opacity': 0.5
        }
      },
      {
        'id': 'gl-draw-polygon-stroke-active',
        'type': 'line',
        'filter': ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': this.currentColor,
          'line-width': 2
        }
      },
      {
        'id': 'gl-draw-polygon-and-line-vertex-halo-active',
        'type': 'circle',
        'filter': ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
        'paint': {
          'circle-radius': 5,
          'circle-color': '#FFF'
        }
      },
      {
        'id': 'gl-draw-polygon-and-line-vertex-active',
        'type': 'circle',
        'filter': ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
        'paint': {
          'circle-radius': 3,
          'circle-color': this.currentColor
        }
      }
    ];
  }
}
