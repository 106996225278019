import { TileInput } from './models/tile.input';

export const PROCESSES: TileInput[] = [
  {
    name: "Simulation Optimization",
    description: "Optimization of process stream components including flare relief and the creation of MSDs, DPDTs, and MSDSs.",
    isActive: false,
  },
  {
    name: "Diagram Generation",
    description: "Automated generation of PFDs utilizing equipment connectivity information which could be an output from Simulation Optimization.",
    isActive: true,
  },
  {
    name: "HAZID / HAZOP Optimization",
    description: "Identify and review hazards based on historical data providing causes, consequences and safeguerds for the hazards identified.",
    isActive: false,
  },
  {
    name: "Line Sizing Optimization",
    description: "Reiterative reanalysis of line sizes, wall thickness and materials to determine the optimal line sizing based on the desired scoring metrics outcomes.",
    isActive: false,
  },
  {
    name: "Equipment Sizing Optimization",
    description: "Reiterative reanalysis of Pumps, Columns, Vessels, and Exchanges to determine the optimal sizing based on the desired scoring metrics outcomes.",
    isActive: false,
  },
  {
    name: "Automated P&ID Generation",
    description: "Automated generation of P&IDs providing the recommended systems controls based on the HAZID/HAZOP Optimization.",
    isActive: false,
  },
  {
    name: "Equipment Layout Optimization",
    description: "Review initial conceptual layout to optimize the existing equipment location based on constraints related to Safety, Maintenance, and Operational requirements.",
    isActive: true,
  },
  {
    name: "Pump Selection Optimization",
    description: "Reiterative reanalysis of Pump Sizes, Power Ratings, and Quantities to determine the optimal pump selection based on the desired scoring metrics outcomes.",
    isActive: false,
  },
  {
    name: "Pipe Stress Optimimzation",
    description: "Reiterative reanalysis of pipe routings at the micro level to minimize localized stresses to equipment and supports through various operational ranges, providing insights to material and resource cost impacts per routing.",
    isActive: false,
  },
  {
    name: "Generative Bill of Materials",
    description: "Utilizing past BoM history provide material recommendations and quantities for the project based on the desired scoring metrics outcomes.",
    isActive: true,
  },
  {
    name: "Cost Engineering Optimization",
    description: "Utilizing comprehensive requirements reviews, past cost history analysis, and emerging trends to complete a cost assessment of the project minimizing risk and quality issues.",
    isActive: false,
  },
  {
    name: "Schedule Layout Optimization",
    description: "Optimize the project schedule based on the availability labor, equipment, and materials optimizing the constructability of the schedule relying primarily on historical data for creating the schedule.",
    isActive: true,
  },
];
