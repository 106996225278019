import { Component, OnInit } from '@angular/core';

import { ProcessService } from '../../../services/process.service';
import { TileInput } from '../../../models/tile.input';

@Component({
  selector: 'app-afeed-process-list-page',
  templateUrl: './afeed-process-list-page.component.html',
  styleUrls: ['./afeed-process-list-page.component.scss']
})
export class AfeedProcessListPageComponent implements OnInit {

  tileInputs: TileInput[];

  constructor(private processService: ProcessService) { }

  ngOnInit(): void {
    this.getProcesses();
  }

  private getProcesses(): void {
      this.processService.getProcessesMock().subscribe(processes => {
        this.tileInputs = processes;
        this.tileInputs.map(t => t.name = t.name.toLocaleUpperCase())
      });
  }

  getApplicationLink(name: string) {
    let targetPath: string;
    switch (name.toLowerCase()) {
      case 'equipment layout optimization': {
        targetPath = 'layout'; //'externalPlantLayout';
        break;
      }
      case 'generative bill of materials': {
        targetPath = 'gbom';
        break;
      }
      case 'schedule layout optimization': {
        targetPath = 'externalScheduleLayout';
        break;
      }
      default: {
        targetPath = 'pfd';
        break;
      }
    }

    return targetPath;
  }
}
