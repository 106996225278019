import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';

import config from './app-config.json';
import { CacheService } from './services/cache.service';
import { CommonService } from './services/common.service';
import { ToasterService } from './services/toaster.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'GenerativeDesignStudio';
  isIframe = false;
  loggedIn = false;
  toasterIsDisplayed = false;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private toaster: ToasterService,
    private cacheService: CacheService,
    private http: HttpClient,
    private commonService: CommonService,
    private msalBroadcastService: MsalBroadcastService
  ) { }


  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    // subscribe to the inProgress$ observable to check if interaction is complete
    // and user is signed in, before rendering the UI
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)  // stop in progress if component destroyed (i.e., user exits)
      )
      .subscribe(() => {
        this.checkoutAccount();
      })
  }


  async checkoutAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();
    let accounts = this.authService.instance.getAllAccounts();

    // user is logged in either: (i) if active account exists, or (ii) there is at least one account
    this.loggedIn = (!!activeAccount) || (accounts.length > 0);

    if (!this.loggedIn) {
      this.login();
    }

    if (!activeAccount && accounts.length > 0) {
      // If no active account set but there are accounts signed in, sets first account as active
      // To use active account set here, subscribe to inProgress$ first in your component (done)
      // Note: Basic usage demonstrated. App may require more complicated account selection logic
      this.authService.instance.setActiveAccount(accounts[0]);
    }

    await this.getUserRole();
  }


  login() {
    // log in with redirect/popup, will navigate to Microsoft login
    const isIE =
      window.navigator.userAgent.indexOf('MSIE ') > -1 ||
      window.navigator.userAgent.indexOf('Trident/') > -1;

    if (!isIE) {
      this.loginRedirect();
    } else {
      this.loginPopup();
    }
  }


  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }


  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService.loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }


  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
    if (this.toaster) {
      this.toaster.unsubscribe();
    }
  }


  async getUserRole() {

    let url = this.commonService.getUrl(config.getAthorizationRole, []);

    let response = this.http.get<any>(url).pipe(
      catchError((err) => {
        this.toaster.show('error', 'Authorization role error!', '');
        ///console.log('Autorization error:', err);
        return throwError(err)
      })
    ).toPromise();

    response.then((authorization) => {
      ///console.log(authorization.role);
      let user = {
        role: authorization.role,
        permissions: [
          'can_read'
        ]
      };
      this.cacheService.saveToCache('user', JSON.stringify(user));
    });
  }
}
