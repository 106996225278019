<div id="modal-content-wrapper">

  <header id="modalHeader">
    <div id="modal-title">{{ modalData.title | uppercase }} DEMO</div>
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  
  <div class="useCaseModalBody">
    <div>
      <span class="descriptionText" *ngIf="sampleFileVisibility === false"> Description </span>
      <div *ngIf="sampleFileVisibility === true" class="sample-files-header">
        <span class="descriptionText" > Download Sample Files </span> 
         <button mat-icon-button class="description-button" (click)="displaySampleFiles()">
            <mat-icon>close</mat-icon>
         </button>
      </div>
      <hr class="green underline">
    </div>

    <div class="descriptionContent" *ngIf="sampleFileVisibility === false">
      <div *ngFor="let description of modalData.description">
        <span class="boldText" *ngIf="description.subtitle">{{ description.subtitle }}: </span>
        <div [innerHTML]="description.content"></div>
      </div>
    </div>

    <div class="descriptionContent" *ngIf="sampleFileVisibility === true">    
      <table class="table text-design">
        <tbody>
          <tr *ngFor="let x of modalData.manySampleFiles" [ngClass]="{'gdp-disabled': downloadSpinner === true}">  
            <img class="downloadIcon" src="../../../../assets/imgs/fillIcon.png"/>
            <a [routerLink]="" (click)="downloadDocumentSampleFile(x.fileKey)" >{{x.title}}</a> 
          </tr>
        </tbody>
      </table>
    </div>

    <div class="videoContent">
      <div class="downloadableElements">
        <img class="downloadIcon" src="../../../../assets/imgs/fillIcon.png" *ngIf="downloadSpinner === false"/>
        <div class="progress" *ngIf="downloadSpinner === true" >
          <div></div>
        </div>
        <div [ngClass]="{'gdp-disabled': downloadSpinner === true}"> 
        <a [routerLink]="" (click)="downloadDocument()" [ngClass]="{'gdp-disabled' : !modalData.scriptKey}" >Download Script</a>             
        <a [routerLink]="" (click)="downloadFirstDocument()" *ngIf="modalData.firstdocumentKey" class="fit-link">Download RPA Estimator</a>    
        <a [routerLink]="" (click)="downloadSecondDocument()" *ngIf="modalData.seconddocumentKey" class="fit-link" >Download Scheduling Cost Calculator</a>
        <a [routerLink]="" (click)="displaySampleFiles()" *ngIf="modalData.sampleFiles" class="fit-link" >Download Sample Files</a>
      </div>
      </div>

      <footer>
        <div class="full-width modalButton">
          <gdp-button [buttonText]="modalData.videoButtonText"
                      [buttonType]="modalData.videoButtonType"
                      [buttonCallback]="modalData.videoButtonCallback">
          </gdp-button>
        </div>
        <div class="full-width modalButton">
          <gdp-button [buttonText]="modalData.launchButtonText"
                      [buttonType]="modalData.launchButtonType"
                      [buttonCallback]="modalData.launchButtonCallback">
          </gdp-button>
        </div>
        <div class="full-width modalButton">
          <gdp-button [buttonText]="modalData.stageZeroDeckButtonText"
                      [buttonType]="modalData.stageZeroDeckButtonType"
                      [buttonCallback]="modalData.stageZeroDeckButtonCallback">
          </gdp-button>
        </div>
        <div *ngIf="modalData.manyDocumentsButtonText.length != 0" class="artifacts">
          <span class="descriptionText">Additional Artifacts</span>
          <hr class="green">
        </div>
        <div *ngFor="let x of modalData.manyDocumentsButtonText" class="full-width modalButton">
          <gdp-button (click)="openDocumentInTab(x.link)"
                      [buttonText]="x.title">
          </gdp-button>
        </div>
      </footer>
    </div>
  </div>
</div>
