import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { UsecaseModalComponent } from '../../../ReusableComponents/usecase-modal/usecase-modal.component';

@Component({
  selector: 'app-industry-list-extended',
  templateUrl: './industry-list-extended.component.html',
  styleUrls: ['./industry-list-extended.component.scss']
})
export class IndustryListExtendedComponent implements OnInit {
  subscription: any;
  category: string;
  subCategory: string;
  industryViewData: any = [];
  usecasesData: any = [];

  selectedIndustryName: any;
  selectedIndustryImg: any;
  clickedKey: string;
  subcategories = [];
  cases: any = "";
  baseSubCases = [];
  relatedSubCases = [];
  emptyList: boolean = true;
  usecasemodalDialog: MatDialogRef<UsecaseModalComponent>;

  videoStreamingSource: string;
  scriptKey: String;
  firstdocumentKey: string;
  seconddocumentKey: string;
  videoName: string;
  downloadSpinner: boolean = false;
  sampleFiles: [];


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.industryViewData = require('../../../../../assets/resources/industryViewData.json');
    this.usecasesData = require('../../../../../assets/resources/industryUsecasesData.json');

    this.category = this.route.snapshot.paramMap.get('category');
    this.subCategory = this.route.snapshot.paramMap.get('subCategory');

    this.getData(this.subCategory, "");
  }

  getData(subCategory: any, validation: string) {
    if (validation == "rgb(45,47,60)") return;

    this.industryViewData.industry.forEach((industry: { key: string; name: any; imageName: any; subcategories: any[]; }) => {
      if (industry.key == this.category) {
        this.selectedIndustryName = industry.name;
        this.selectedIndustryImg = industry.imageName
        this.subcategories = industry.subcategories;

        this.subcategories.forEach((subcategory) => {
          if (subcategory.cases.base == null) {
            subcategory.color = "rgb(45,47,60)";
          }
          else {
            subcategory.color = "rgb(32,64,78)";
          }

          if (subcategory.key == subCategory) {
            subcategory.color = "#52f3ff";
            this.cases = subcategory;
            this.baseSubCases = subcategory.cases.base;

            for (let i = 0; i < subcategory.cases.base.length; i++) {
              this.baseSubCases[i].hasVideo =
                this.usecasesData[this.baseSubCases[i].key].videoLink !== null;

              for (let j = 0; j < this.baseSubCases[i].subcases.length; j++) {
                this.baseSubCases[i].subcases[j].hasVideo =
                  this.usecasesData[this.baseSubCases[i].subcases[j].key].videoLink !== null;
              }
            }

            this.relatedSubCases = subcategory.cases.related;

            if (subcategory.cases.related == null || undefined) {
              this.emptyList = false;
            }
            else {
              this.emptyList = Object.keys(subcategory.cases.related[0]).length == 0 ? false : true;

              for (let i = 0; i < subcategory.cases.related.length; i++) {
                this.relatedSubCases[i].hasVideo =
                  this.usecasesData[this.relatedSubCases[i].key].videoLink !== null;

                for (let j = 0; j < this.relatedSubCases[i].subcases.length; j++) {
                  this.relatedSubCases[i].subcases[j].hasVideo =
                    this.usecasesData[this.relatedSubCases[i].subcases[j].key].videoLink !== null;
                }
              }
            }
          }
        });
      }
    });
  }

  openUsecaseDialog = (key: string): void => {
    this.clickedKey = key;
    let dialogConfig = UsecaseModalComponent.defaultConfig();

    dialogConfig.data.title = this.usecasesData[key].name;
    dialogConfig.data.description = this.usecasesData[key].description;
    dialogConfig.data.downloadSpinner = this.downloadSpinner;
    dialogConfig.data.imageName = "assets/imgs/" + this.usecasesData[key].imageName;
    dialogConfig.data.scriptKey = this.usecasesData[key].scriptKey;
    dialogConfig.data.firstdocumentKey = this.usecasesData[key].firstdocumentKey;
    dialogConfig.data.seconddocumentKey = this.usecasesData[key].seconddocumentKey;
    dialogConfig.data.sampleFiles = this.usecasesData[key].sampleFiles;

    dialogConfig.data.videoSource = this.videoStreamingSource;
    this.scriptKey = dialogConfig.data.scriptKey;
    this.firstdocumentKey = dialogConfig.data.firstdocumentKey;
    this.seconddocumentKey = dialogConfig.data.seconddocumentKey;
    this.sampleFiles = dialogConfig.data.sampleFiles;

    dialogConfig.data.videoButtonCallback = this.openDemoVideo;
    if (this.usecasesData[this.clickedKey].videoLink === null) {
      dialogConfig.data.videoButtonType = 'noclick';
    }

    dialogConfig.data.launchButtonText = 'Launch ' + this.usecasesData[key].name;
    if (this.usecasesData[this.clickedKey].demosite == "") {
      dialogConfig.data.launchButtonType = 'noclick';
    }
    if (this.usecasesData[key].type === 'link') {
      dialogConfig.data.launchButtonCallback = this.launchDemoPage;
    } else if (this.usecasesData[key].type === 'internal') {
      dialogConfig.data.launchButtonCallback = this.navigateToView;
    } else {
      console.error('Invalid use case type in Front Door');
    }

    dialogConfig.data.stageZeroDeckButtonCallback = this.openStageZeroDeck;
    if (this.usecasesData[this.clickedKey].stagezerodeck == "") {
      dialogConfig.data.stageZeroDeckButtonType = 'noclick';
    }

    if (this.usecasesData[this.clickedKey].target != "") {
      for (let i = 0; i < this.usecasesData[this.clickedKey].target.length; i++) {
        dialogConfig.data.manyDocumentsButtonText.push(this.usecasesData[this.clickedKey].target[i]);
      }
    }

    if (this.usecasesData[this.clickedKey].sampleFiles != undefined && this.usecasesData[this.clickedKey].sampleFiles != "") {
      for (let i = 0; i < this.usecasesData[this.clickedKey].sampleFiles.length; i++) {
        dialogConfig.data.manySampleFiles.push(this.usecasesData[this.clickedKey].sampleFiles[i]);
      }
    }

    this.usecasemodalDialog = this.matDialog.open(UsecaseModalComponent, dialogConfig);
  }

  openDemoVideo = (): void => {
    this.openNewTab(this.usecasesData[this.clickedKey].videoLink);
  }

  launchDemoPage = (): void => {
    this.openNewTab(this.usecasesData[this.clickedKey].demosite);
  }

  openStageZeroDeck = (): void => {
    this.openNewTab(this.usecasesData[this.clickedKey].stagezerodeck);
  }

  /**
   * Opens a new tab corresponding to the given link.
   */
  openNewTab(link: string): void {
    window.open(link, "_blank");
  }

  /**
   * Navigates to a new view inside the application.
   */
  navigateToView = (): void => {
    this.router.navigateByUrl(this.usecasesData[this.clickedKey].demosite).then(() => {
      this.closeUseCaseDialog();
    });
  }

  /** Closes the dialog that is currently opened.  * */
  closeUseCaseDialog = (): void => {
    if (this.usecasemodalDialog) {
      this.usecasemodalDialog.close();
    }
  }
}

