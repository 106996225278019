<div class="file-upload" 
     dropzone
     (dropped)="uploadProgress==-1 ? onDragAndDropFileUpload($event) : ''"
     (hovered)="dragOver=$event"
     [ngClass]="{ 'is-drop-over': (dragOver && uploadProgress==-1) || (uploadProgress >= 0 && uploadProgress < 100),
                  'no-file-selected': (fileName === undefined),
                  'file-selected': (fileName !== undefined) }">
    <div *ngIf="fileName === undefined; else elseBlock">{{ boxMessage }} or click here to 
      <label class="label underline-text">browse
        <input type="file" class="no-display" [accept]="allowedFileExtensions"
          (change)="onBrowseFileUpload($event)">
      </label>
    </div>
    <ng-template #elseBlock>{{fileName}}</ng-template>
</div>
<div *ngIf="uploadProgress >= 0" class="upload-progress" 
  [ngClass]="{'upload-progress': !isInterrupted,
              'upload-failed': isInterrupted}">
  <div><div [ngStyle]="{'width': uploadProgress + '%'}"><span>UPLOADING (%{{ uploadProgress }})</span></div></div>
  <div *ngIf="isInterrupted">
    <mat-icon inline>warning</mat-icon>
    Check your file!
  </div>
</div>
