<div class="vertical-white-space"></div>
<div class="container-fluid bottomnav">
  <div *ngIf="isBackButtonVisible"
       class="button-width">
    <gdp-button [buttonText]="backButtonText"
                [buttonType]="backButtonType"
                [buttonCallback]="backButtonCallback">
    </gdp-button>
  </div>
  <div *ngIf="isCancelButtonVisible"
       class="button-width">
    <gdp-button [buttonText]="cancelButtonText"
                [buttonType]="cancelButtonType"
                [buttonCallback]="cancelButtonCallback">
    </gdp-button>
  </div>

  <div class="stepper-container">
    <div class="stepper-wrapper">
      <ul class="stepper" *ngIf="currentStage > 0">
        <li *ngFor="let item of [].constructor(maxStages); let i = index"
            [ngClass]="{'active': currentStage > i}"></li>
      </ul>
    </div>
  </div>

  
  <div class="progress" *ngIf="downloadSpinner === true">
      <div></div>
  </div>
  <div *ngIf="isSaveButtonVisible"
       class="button-width">
    <gdp-button [buttonText]="saveButtonText"
                [buttonType]="saveButtonType"
                [buttonCallback]="saveButtonCallback">
    </gdp-button>
  </div>
  <div *ngIf="isNextButtonVisible"
       class="button-width">
    <gdp-button [buttonText]="nextButtonText"
                [buttonType]="nextButtonType"
                [buttonCallback]="nextButtonCallback">
    </gdp-button>
  </div>

</div>
