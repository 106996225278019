import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'gdp-bottom-navigation',
  templateUrl: './bottom-navigation.component.html',
  styleUrls: ['./bottom-navigation.component.scss']
})
export class BottomNavigationComponent implements OnInit {
  // stepper to monitor stages
  @Input() maxStages: number;
  @Input() currentStage: number;

  // footer navigation bar may contain up to 4 buttons: Back, Cancel, Save, Next
  // they all have the same properties: text, type, is visible, callback function
  // possible button types: 'new', 'disabled' and normal not needed to be provided (check the ReusableComponents/button)
  @Input() isBackButtonVisible: boolean;
  @Input() backButtonText: string;
  @Input() backButtonType: string;
  @Input() backButtonCallback: Function;

  @Input() isCancelButtonVisible: boolean;
  @Input() cancelButtonText: string;
  @Input() cancelButtonType: string;
  @Input() cancelButtonCallback: Function;

  @Input() isSaveButtonVisible: boolean;
  @Input() saveButtonText: string;
  @Input() saveButtonType: string;
  @Input() saveButtonCallback: Function;

  @Input() isNextButtonVisible: boolean;
  @Input() nextButtonText: string;
  @Input() nextButtonType: string;
  @Input() nextButtonCallback: Function;

  @Input() downloadSpinner: boolean;
  
  constructor() { }

  ngOnInit(): void {
  }


}
