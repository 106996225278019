/**
 * Service class to download a file from the blob storage. A valid SAS token is required and an existing full path to a blob.
 * The class uses the Azure blob service package, and is not subject to unit testing.
 */
import { BlobServiceClient } from '@azure/storage-blob';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor() { }

  async downloadFile(sasToken: string, blobStoragePath: string) {
    const accountPath = blobStoragePath.slice(0, blobStoragePath.indexOf("/", 9));
    const storagePath = blobStoragePath.slice(blobStoragePath.indexOf("/", 9) + 1, blobStoragePath.lastIndexOf("/"));
    const fileName = blobStoragePath.slice(blobStoragePath.lastIndexOf("/") + 1);
    const blobServiceClient = new BlobServiceClient(`${accountPath}${sasToken}`);

    // Get blob content from position 0 to the end
    // In browsers, get downloaded data by accessing downloadBlockBlobResponse.blobBody
    const containerClient = blobServiceClient.getContainerClient(storagePath);
    const blobName = fileName;
    const blobClient = containerClient.getBlobClient(blobName);
    const downloadBlockBlobResponse = await blobClient.download();
    return await blobToBuffer(await downloadBlockBlobResponse.blobBody);

    function blobToBuffer(blob): Observable<string> {
      const sub = new Subject<string>();
      var fileReader = new FileReader();

      fileReader.onloadend = (ev) => {
        const content: string = ev as unknown as string;
        sub.next(content);
        sub.complete();
      };
      fileReader.readAsArrayBuffer(blob);
      fileReader.onprogress = (ev) => {
        //TO DO:  if we want to show progress
      };

      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob)
      a.href = objectUrl
      a.download = blobName;
      a.click();
      URL.revokeObjectURL(objectUrl);

      return sub.asObservable();
    }
  }
}