<div class="vertical-white-space"></div>
<div class="container-fluid bottomnav">
  <div *ngIf="isCancelButtonVisible"
       class="button-width">
    <gdp-button [buttonText]="cancelButtonText"
                [buttonType]="cancelButtonType"
                [buttonCallback]="cancelButtonCallback">
    </gdp-button>
  </div>

  <div class="middle-container">
  </div>

  <div *ngIf="isSaveButtonVisible"
       class="button-width">
    <gdp-button [buttonText]="saveButtonText"
                [buttonType]="saveButtonType"
                [buttonCallback]="saveButtonCallback">
    </gdp-button>
  </div>

</div>
