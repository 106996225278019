import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { TileInput } from '../../../models/tile.input';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss']
})
export class TileComponent implements OnInit {

  @Input() tileInput: TileInput;
  @Input() targetPath: string;
  @Input() buttonText: string;
  @Input() minContentHeight = 150;
  @Input() tileType: string = 'regular';


  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  getButtonText(): string {
    return (this.buttonText) ? this.buttonText : ('START ' + this.tileInput.name.toUpperCase());
  }

  closeButtonTile(event: MouseEvent) {
    event.cancelBubble = true;
    if (event.stopPropagation) event.stopPropagation();
    this.tileInput.description.isOpen = false;
  }

  openIndustrySelectedTile(subCat: any) {
    let category = this.tileInput.description.key;
    let selectedSubcategory = subCat.key;
    let url ="/industry/" + category + "/" + selectedSubcategory;
    this.router.navigateByUrl(url);
  }
}
