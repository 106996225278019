import * as maplibregl from 'maplibre-gl';

export class MapCompassControl {
  private compass: HTMLElement;
  private compassIcon: HTMLElement;

  onAdd(map: maplibregl.Map): HTMLElement {
    this.compass = document.createElement('div');
    this.compass.className = 'maplibre-ctrl maplibre-ctrl-group';
    this.compass.innerHTML = `
      <button class="compass-button" aria-label="Reset North">
        <span class="compass-icon"></span>
      </button>
    `;

    this.compassIcon = this.compass.querySelector('.compass-icon') as HTMLElement;

    this.compass.addEventListener('click', () => {
      map.resetNorth();
    });

    map.on('rotate', () => {
      const bearing = map.getBearing();
      if (this.compassIcon) {
        this.compassIcon.style.transform = `rotate(${bearing}deg)`;
      }
    });

    return this.compass;
  }

  onRemove(): void {
    this.compass.parentNode?.removeChild(this.compass);
  }
}