<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"><p class="white-spinner"> Generating Data </p></ngx-spinner>

<div class="begin-page">
    <h1>{{ projectName }}</h1>
    <h2>{{ pfd?.ProcessName }}</h2>
    <p class="half-width">
        Your configuration consists of the following piping and equipment
    </p>
</div>

<app-toaster-container></app-toaster-container>

<div class="container-fluid grey-container column-stretch">
    <div class="column-left">
        <h3>TABLE OF PIPES AND EQUIPMENT</h3>
        <hr class="green underline">

        <div class="table-container">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th scope="col">STREAM NAME</th>
                    <th scope="col">INPUT EQUIPMENT NAME</th>
                    <th scope="col">INPUT EQUIPMENT CLASS/TYPE</th>
                    <th scope="col">OUTPUT EQUIPMENT NAME</th>
                    <th scope="col">OUTPUT EQUIPMENT CLASS/TYPE</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of dataIntegrityCheckResult" [style.backgroundColor] = "!data.IsValid ? '#fd4654' : ''">
                    <td>{{data.StreamName}}</td>
                    <td>{{data.InputEquipmentName}}</td>
                    <td>{{data.InputEquipmentType}}</td>
                    <td>{{data.OutputEquipmentName}}</td>
                    <td>{{data.OutputEquipmentType}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="column-right">
    </div>
</div>

<gdp-bottom-navigation [maxStages]="maxPfdStages"
                    [currentStage]="currentPfdStage"
                    [isBackButtonVisible]="true"
                    [backButtonText]="'BACK TO CREATE'"
                    [backButtonType]=""
                    [backButtonCallback]="backButtonCallback"
                    [isCancelButtonVisible]="isCancelButtonVisible"
                    [cancelButtonText]="'CANCEL'"
                    [cancelButtonType]=""
                    [cancelButtonCallback]="goBackToGeneralPfdList"
                    [isSaveButtonVisible]="false"
                    [saveButtonText]="'SAVE'"
                    [saveButtonType]=""
                    [saveButtonCallback]="saveButtonCallback"
                    [isNextButtonVisible]="true"
                    [nextButtonText]="'GENERATE PFD(S)'"
                    [nextButtonType]="AllResultsAreValid?'':'disabled'"
                    [nextButtonCallback]="startGeneration">
</gdp-bottom-navigation>



