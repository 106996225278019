import { ActivatedRoute } from '@angular/router';
import { BlobServiceClient } from '@azure/storage-blob'; // https://docs.microsoft.com/en-us/javascript/api/@azure/storage-blob/?view=azure-node-latest
import { Component, EventEmitter, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { Guid } from "guid-typescript";
import { TransferProgressEvent } from '@azure/core-http';
import { Subscription } from 'rxjs';

import { ProcessService } from 'src/app/services/process.service';
import { ToasterService } from '../../../services/toaster.service';

@Component({
  selector: 'gdp-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})

export class FileUploadComponent implements OnInit, OnDestroy {
  projectId: string;
  @Input() processId: string;

  @Input() Guid: string;  // temporary process ID
  @Output() GuidChange = new EventEmitter<string>();
  @Input() fileName: string
  @Output() fileNameChange = new EventEmitter<string>();
  @Input() saveButtonFlag: boolean
  @Output() saveButtonFlagChange = new EventEmitter<boolean>();


  @Input() boxMessage: string = "Drag and drop files here ";
  @Input() allowedFileExtensions: string[] = ['.xml', '.csv'];
  @Input() maxFileSize: number = 500; // in MB's
  dragOver: boolean;
  currentFile: any;
  uploadProgress: number = -1;
  isInterrupted: boolean = false; // is upload interrupted

  sub: Subscription;
  blobServiceClient: BlobServiceClient;
  istemp: string; // is the file temporary
  storagePath: string;

  @Input() buttonCallback: Function = () => { };


  /* // we are not using content type for the moment, only checking the file extension, but this could be useful in the future
  // conversion from file extension to upload content type
  // extend with your 'ext: contentType' as necessarry (https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types)
  private readonly extensionToContentType = {
    '.xml': 'text/xml',
    '.csv': 'text/csv',
    '.txt': 'text/plain',
    '.xls': 'application/vnd.ms-excel',
    '.3dm': 'x-world/x-3dmf',
    '.sldprt': 'application/octet-stream',
    '.3ds': 'application/x-3ds',
    '.dxf': 'application/dxf',
    '.dwg': 'application/acad',
    '.iges': 'application/iges',
    '.obj': 'application/octet-stream',
    '.step': 'application/step',
    '.stl': 'application/sla',
    '.skp': 'application/x-koan'
    ,'.wmv':	'video/x-ms-wmv'
  }
  */

  constructor(
    private route: ActivatedRoute,
    private toaster: ToasterService,
    private processService: ProcessService
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.projectId = params['projectid'];
    });
    //let allowedContents = Object.entries(this.extensionToContentType)
    //  .filter(([k, _]) => this.allowedFileExtensions.includes(k))
    //  .map(([_, v]) => v);
  }

  ngOnDestroy() {
    if (this.toaster) {
      //this.toaster.unsubscribe(); // do not unsubscribe here, since unsubscribing is global and this component does not exist by itself
    }
    this.sub.unsubscribe();
  }

  onDragAndDropFileUpload(event) {
    this.currentFile = event[0];
    this.uploadFile();
  }

  onBrowseFileUpload(event) {
    this.currentFile = event.target.files[0];
    this.uploadFile();
  }

  uploadFile() {
    this.isInterrupted = false;
    this.istemp = 'false';  // assume we are NOT uploading to temporary storage
    if (!this.allowedFileExtensions.includes('.' + this.currentFile.name.split('.').pop().toLowerCase())) {
      this.toaster.show('error', 'UPLOAD FAILED! The allowed file formats include only: ' +
        this.allowedFileExtensions.join(', '), '');
    } else if (this.currentFile.size > this.maxFileSize * 1024 * 1024) { // convert MB to bytes
      this.toaster.show('error', 'UPLOAD FAILED! The maximum allowed file size is ' +
        this.maxFileSize + ' MB.');
    } else {
      if (this.processId === 'null') {
        if (!this.Guid) {   // create new GUID if it was not already created
          this.Guid = Guid.create().toString();
          this.GuidChange.emit(this.Guid);
        }
        this.istemp = 'true';
      } else {
        this.Guid = this.processId;
      }
      this.getSasTokenAndUpload();
    }
  }

  getSasTokenAndUpload() {
    this.processService.generateFileStorageSASToken(this.projectId, this.Guid, this.currentFile.name, this.istemp
    ).then((result) => {
      const accountPath = result.path.slice(0, result.path.indexOf("/", 9));
      this.storagePath = result.path.slice(result.path.indexOf("/", 9) + 1, result.path.lastIndexOf("/"));
      this.blobServiceClient = new BlobServiceClient(`${accountPath}${result.sasToken}`);
      this.sendFileToBlobStorage();
    }).catch((err: any) => {
      // this.toaster.show('error', 'UPLOAD FAILED! Could not access file storage system.', '');
      this.toaster.show('error', 'UPLOAD FAILED!', '');
    });
  }

  async sendFileToBlobStorage() {
    this.uploadProgress = 0;
    const containerClient = this.blobServiceClient.getContainerClient(this.storagePath);
    const content = this.currentFile;
    const blockBlobClient = containerClient.getBlockBlobClient(this.currentFile.name);
    this.saveButtonFlagChange.emit(false);
    blockBlobClient.uploadData(content, {
      blockSize: 4 * 1024 * 1024, // 4MB block size
      maxSingleShotSize: 4 * 1024 * 1024, // blob size threshold, default 256MB, below this no concurrency. https://github.com/Azure/azure-sdk-for-js/issues/4719
      concurrency: 20, // 20 concurrency
      onProgress: (ev: TransferProgressEvent) => {
        this.uploadProgress = Math.round((ev.loadedBytes / this.currentFile.size) * 100)
        if (this.uploadProgress == 100) {
          this.saveButtonFlagChange.emit(true);
        }
      },
      blobHTTPHeaders: { blobContentType: content.type },
    }).then(() => {
      //`Upload of blob ${this.currentFile.name} was succesfull to ` + this.storagePath
      this.toaster.show('success', 'UPLOAD COMPLETE!', '');
      this.fileName = this.currentFile.name;
      this.fileNameChange.emit(this.fileName);
      setTimeout(() => {
        this.uploadProgress = -1, 5000 // reset progress after waiting a bit

      });
    }).catch((err: any) => {
      this.isInterrupted = true;
      // this.toaster.show('error', 'UPLOAD FAILED! Something went wrong. Please try again!', '');
      this.toaster.show('error', 'UPLOAD FAILED! Please try again!', '');
    });
  }

}
