<div class="gdp-grid-tile"
  [ngClass]="{
    'gdp-disabled': !tileInput.isActive, 
    'tile-new': tileType === 'new', 
    'tile-app': tileType === 'application',
    'tile-industry': tileType === 'industry'}">

  <!-- <div class="gdp-grid-tile"
  [ngClass]="{
    'gdp-disabled': !tileInput.isActive, 
    'tile-new': tileType === 'new', 
    'tile-app': tileType === 'application',
    'tile-industry': tileType === 'industry',
    'tile-valuelever': tileType === 'valuelever'}"> -->

  <div class="header header-{{ tileType }}" [ngClass]="{'longName': (tileType === 'industry' && tileInput.name.length > 21 && tileInput.description.isOpen)}">
    <div>{{ tileInput.name }}
      <div *ngIf="tileType === 'layout' || tileType === 'gbom'">
        {{ tileInput.description.location }}
      </div>
      <div class="date gdp-disabled" *ngIf="tileType === 'layout'">
        Last edited: {{ tileInput.description.createTime | date: 'MMM d, yyyy' }}
      </div>
      <div class="date gdp-disabled" *ngIf="tileType === 'gbom'">
        Last Edited: {{ tileInput.description.createTime | date: 'MMMM d, yyyy' }}
      </div>  
    </div>
    <div class="secondHeader" *ngIf="tileType === 'industry' && tileInput.description.isOpen">
      <button class="closeButton">
        <mat-icon (click)="closeButtonTile($event)">close</mat-icon>
      </button>
   </div>
    <!-- <img [src]="tileInput.description.imgPath" *ngIf="tileType === 'valuelever'"> -->
  </div>

  <div class="content" [style.min-height.px]="minContentHeight" [ngSwitch]="tileType">

    <div *ngSwitchCase="'new'"></div>

    <div *ngSwitchCase="'project'">
      <table class="full-width">
        <tr>
          <td class="gdp-disabled">Start Date:</td>
          <td>{{ tileInput.description.startDate | date: 'MM/dd/yyyy' }}</td>
        </tr>
        <tr>
          <td class="gdp-disabled">End date:</td>
          <td>{{ tileInput.description.endDate | date: 'MM/dd/yyyy' }}</td>
        </tr>
      </table>
      <p class="line-clamp">{{ tileInput.description.text }}</p>
      <div class="gdp-disabled to-bottom">Created: {{ tileInput.description.createTime | date: 'MMM d, yyyy' }}</div>
    </div>

    <div *ngSwitchCase="'process'">
      <table class="full-width">
        <tr>
          <td class="gdp-disabled">Stage:</td>
          <td>{{ tileInput.description.state }} out of {{ tileInput.description.maxState }}</td>
        </tr>
        <tr>
          <td class="gdp-disabled">Created on:</td>
          <td>{{ tileInput.description.createTime | date: 'MMM d, y, h:mm:ss a' }}</td>
        </tr>
      </table>
    </div>

    <div *ngSwitchCase="'application'">
      <div>{{ tileInput.description.text }}</div>
      <img class="gdp-app" [src]="tileInput.description.imgPath" alt="chemicals">
    </div>

    <div *ngSwitchCase="'industry'">
      <div *ngIf="!tileInput.description.isOpen">
        <img class="gdp-app" [src]="tileInput.description.imgPath" alt="tileInput.description.imgName">
        <div class='arrow'>
          <mat-icon inline=true>arrow_forward</mat-icon>
        </div>
      </div>
      <div class="tileContent" *ngIf="tileInput.description.isOpen">
        <ul class="no-bullets">
          <li *ngFor="let subCat of tileInput.description.subcategories" [ngClass]="{ 'blueColor': (subCat.cases),
                               'greyColor': (subCat.cases.base === null && subCat.cases.related === null)}"
            (click)="openIndustrySelectedTile(subCat)">
            {{ subCat.name }}
          </li>
        </ul>
      </div>
    </div>

    <!-- <div *ngSwitchCase="'valuelever'">
    </div> -->

    <div *ngSwitchCase="'layout'" class="layout">
      <img [src]="tileInput.description.imgPath" alt="layout_image">
      <div>{{ tileInput.description.text }}</div>
    </div>

    <div *ngSwitchCase="'gbom'" class="gbom">
      <img [src]="tileInput.description.imgPath" alt="gbom_image">
      <div>{{ tileInput.description.text }}</div>
    </div>

    <div *ngSwitchDefault>{{ tileInput.description }}</div>    
  </div>


  <div class="footer" *ngIf="targetPath">
    <div *ngIf="tileType=='layout'" class="gdp-disabled date">Created: {{ tileInput.description.createTime | date: 'MMM d, yyyy' }}</div>
    <div *ngIf="tileType=='gbom'" class="gdp-disabled date">Created on: {{ tileInput.description.createTime | date: 'MMMM d, yyyy' }}</div>
    <gdp-button class="full-width"
      routerLink="{{ targetPath }}"
      [buttonText]="getButtonText()"
      [buttonType]="tileType">
    </gdp-button>
  </div>
</div>
