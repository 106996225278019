import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'gdp-footer-buttons',
  templateUrl: './footer-buttons.component.html',
  styleUrls: ['./footer-buttons.component.scss']
})
export class FooterButtonsComponent implements OnInit {

  @Input() isCancelButtonVisible: boolean;
  @Input() cancelButtonText: string;
  @Input() cancelButtonType: string;
  @Input() cancelButtonCallback: Function;

  @Input() isSaveButtonVisible: boolean;
  @Input() saveButtonText: string;
  @Input() saveButtonType: string;
  @Input() saveButtonCallback: Function;

  constructor() { }

  ngOnInit(): void {
  }

}
