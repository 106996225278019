import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'gdp-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() buttonText: string;
  @Input() buttonType: string;
  @Input() buttonCallback: Function = () => { };

  constructor() { }

  ngOnInit(): void {
  }

}
