import { TileInput } from './models/tile.input';

export const APPS: TileInput[] = [
  {
    name: "Automated FEED",
    description: "FEED stands for Front End Engineering Design. The FEED is basic engineering which comes after the Conceptual Design or feasibility study. FEED focuses on Technical as well as Financial aspects of the project.",
    isActive: true,
  },
  {
    name: "Checkpoint optimization",
    description: "Checkpoint optimization Description",
    isActive: false,
  },
];
