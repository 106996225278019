import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { concatMap, finalize, tap } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    private loadingSubject = new BehaviorSubject<boolean>(false);
    loading$: Observable<boolean> = this.loadingSubject.asObservable();
    loadingCounter: number = 0; // how many times loadingOn() is called, to disable spinner only when counter is 0 again

    constructor() { }

    showLoaderUntilCompleted<T>(obs$: Observable<T>): Observable<T> {
        return of(null).pipe(
            tap(() => this.loadingOn()),
            concatMap(() => obs$),
            finalize(() => this.loadingOff())
        );
    }

    loadingOn() {
        if (this.loadingCounter === 0) {
            this.loadingSubject.next(true);
        }
        this.loadingCounter += 1;
    }

    loadingOff() {
        this.loadingCounter -= 1;
        if (this.loadingCounter === 0) {
            this.loadingSubject.next(false);
        }
    }

}