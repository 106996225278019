<table class="map-container">
  <tbody>
    <tr class="d-flex">
      <td rowspan="2" class="col-4"></td>
      <td class="col-8">
        <!-- <button (click)="deletePolygon()">Delete Polygon</button> -->
        <!-- <div id="search-box">
          <input type="text" id="location-input" placeholder="Enter location" />
          <button id="search-button">Search</button>
        </div> -->
        <div class="search-input-container">
          <!-- <label class="gdp-disabled no-bottom" for="name">Search Location</label>
          <mat-form-field floatLabel="never">
            <input matInput id="location-input" type="text" formControlName="name"
              placeholder="Enter Location..."  autocomplete="off" />
          </mat-form-field> -->
          <button mat-raised-button>CONFIRM TO CONTINUE</button>
      </div>
      </td>
    </tr>
    <tr class="d-flex" >
      <td class="col-4">
        <mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <u>PREFFERRED EV CHARGING AREA</u>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="polygon-list">
            <div *ngFor="let polygon of drawnPolygons; let i = index" class="polygon-item">
              <div class="list-item">
                <i class="material-icons green-icon">crop_square</i>
                <span class="list-label">Preferred Area No.{{ i + 1 }}:</span>
              </div>
              <div class="button-group">
                <span (click)="editPolygon(polygon.id)" class="edit-button">Edit</span>
                <span (click)="deletePolygon(polygon.id)" class="delete-button">Delete</span>
              </div>
            </div>
          </div>
          <div class="add-preferred-area-container">
            <div>Add Preferred Area</div>
            <div class="add-preferred-area" (click)="selectColor('#ff0000')"><mat-icon matDatepickerToggleIcon>add_box</mat-icon></div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <u>EXISTING SCE LIVE FRONT SWITCH</u>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="add-preferred-area-container">
            <div>Add SCE Live Front Switch Location</div>
            <div class="add-preferred-area" (click)="selectColor('#00ff00')"><mat-icon matDatepickerToggleIcon>add_box</mat-icon></div>
          </div>
        </mat-expansion-panel>
      </td>
      <td class="col-8">
        <div id="map"></div>
      </td>
    </tr>
  </tbody>
</table>