/**
 * Page containing form for creating a new project.
 * Source: reactive forms in Angular, https://angular.io/guide/reactive-forms
 */
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { formatDate } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ModalComponent } from '../../ReusableComponents/modal/modal.component';
import { Project, GlobalKPI } from '../../../models/project';
import { ProjectService } from '../../../services/project.service';
import { TileInput } from '../../../models/tile.input';


@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateProjectComponent implements OnInit {
  public projectForm: UntypedFormGroup;
  public project: Project;
  public location: any;
  public tileInputs: TileInput[];

  // number of characters and maximum length allowed in description textarea
  public descriptionLength = new BehaviorSubject(0);
  public descriptionLengthMax = 500;

  modalDialog: MatDialogRef<ModalComponent>;

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private formBuilder: UntypedFormBuilder,
    private matDialog: MatDialog,
    private projectService: ProjectService,
    private router: Router) {
    // get lists for location dropdowns (countries, regions, states, cities)
    this.getLocationAttributeListMock();

    // get applications, to be shown under the form in tile components
    this.tileInputs = this.getAppTiles();

    // initialize project form data
    this.projectForm = this.formBuilder.group({
      name: ['', Validators.required],
      country: ['USA', Validators.required],
      region: ['North America', Validators.required],
      state: ['Texas', Validators.required],
      city: ['Houston', Validators.required],
      startDate: ['', Validators.required],
      endDate: [{ value: '', disabled: true }, Validators.required],
      description: ['']
    });

    // listen to description textarea content length change
    this.projectForm.get('description').valueChanges.subscribe((v) => this.descriptionLength.next(v.length));
    this.projectForm.get('startDate').valueChanges.subscribe((v) => {
      if (v) {
        this.projectForm.get('endDate').enable();
      }
    });
  }

  ngOnInit(): void {
    // overwrite weekday options for calendar (datepicker) element
    this.dateAdapter.getFirstDayOfWeek = () => { return 1 } // first day should be Monday
    this.dateAdapter.getDayOfWeekNames = () => { return ['Su', 'M', 'T', 'W', 'Th', 'F', 'S'] };
  }

  /**
   * Process form data and create project on backend using the HTTP API.
   * @param projectData form data created with formBuilder in the constructor of this class
   */
  submitForm(projectData) {
    //this.projectForm.reset();
    this.project = {
      ProjectName: projectData.name,
      ProjectDetails: projectData.description,
      Country: projectData.country,
      Region: projectData.region,
      State: projectData.state,
      City: projectData.city,
      StartDate: this.date2string(new Date(projectData.startDate)),
      EndDate: this.date2string(new Date(projectData.endDate)),

      KPIs: { // TODO: define actual KPIs on the Form (LATER), hardcoded for now
        [GlobalKPI.KPI1]: 60,
        [GlobalKPI.KPI2]: 90,
        [GlobalKPI.KPI3]: 100,
        [GlobalKPI.KPI4]: 60,
        [GlobalKPI.KPI5]: 90,
        [GlobalKPI.KPI6]: 80,
        [GlobalKPI.KPI7]: 90,
        [GlobalKPI.KPI8]: 90,
        [GlobalKPI.KPI9]: 90,
      }
    }

    ///console.warn('Your project data is ready to be submitted:', this.project);
    this.projectService.createProject(this.project).subscribe({
      next: (response: any) => {
        this.router.navigateByUrl('/projects');
      },
      error: (err) => { }
    });
  }

  /**
   * Goes back to general project list page if form was not touched, opens popup dialog otherwise.
   * This is the callback for the Cancel button.
   */
  goBackToProjectList = (): void => {
    if (this.projectForm.pristine) {
      this.navigateBackToProjectList();
    } else {
      this.openQuitProhibitDialog();
    }
  }

  /**
   * Save button callback, for submitting the form data.
   */
  saveProjectData = (): void => {
    if (this.projectForm.valid) {
      this.submitForm(this.projectForm.value);
    }
  }

  /**
   * Converts a date to ISO 8601 format, as required by the backend.
   * @param d date object to be converted
   */
  date2string(d: Date): string {
    let d1 = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
    return formatDate(d1, 'yyyy-MM-ddTHH:mm:ssZ', 'en-US', '+00:00');
  }

  /**
  * Opens dialog for prohibiting to leave page without saving changes.
  * */
  openQuitProhibitDialog() {
    let dialogConfig = ModalComponent.defaultConfig();
    dialogConfig.data.description = `
      You modified the form data for the project you want to create.
      Are you sure you want to leave this page without saving your changes first?
    `;
    dialogConfig.data.hasCancelButton = true;
    dialogConfig.data.cancelButtonText = 'NO, STAY HERE';
    dialogConfig.data.cancelButtonCallback = this.closeDialog;
    dialogConfig.data.okButtonText = 'YES, QUIT ANYWAY';
    dialogConfig.data.okButtonCallback = this.navigateBackToProjectList;
    this.modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }

  /**
   * Closes the dialog that is currently opened.
   * */
  closeDialog = (): void => {
    // close only if it was opened
    if (this.modalDialog) {
      this.modalDialog.close();
    }
  }

  /**
   * Navigates back to the page where all created projects are listed.
   * */
  navigateBackToProjectList = (): void => {
    this.router.navigateByUrl('/projects').then(() => {
      this.closeDialog();
    });
  }

  /**
   * Mocks location data (countries, regions, states, cities) for project form.
   */
  getLocationAttributeListMock() {
    this.location = {
      countries: ['USA'],
      regions: ['North America'],
      states: ['Texas'],
      cities: ['Houston']
    }
  }

  /**
   * Get applications as Tiles included in this project
   */
  getAppTiles() {
    let APPS: TileInput[] = [
      {
        name: 'Automated FEED',
        description: {
          'text': 'The Front End Engineering and Design primary scopes of work within the FEED process',
          'imgPath': "assets/imgs/chemicals.png"
        },
        isActive: true,
      },
    ];
    return APPS;
  }

}
