import { ProcessKPI } from '../models/processKPI';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Observable, of, pipe, throwError } from 'rxjs';

import config from '../app-config.json';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { PfdDiagram } from '../models/process';
import { ToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root'
})
export class PfdService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private toaster: ToasterService
  ) { }

  async getGeneratedPFDs(projectId: string, processId: string): Promise<{ diagrams: PfdDiagram[] }> {
    let url = this.commonService.getUrl(config.getGeneratedPFDs, [projectId, processId]);

    return await this.http.get<{ diagrams: PfdDiagram[] }>(url).pipe(
      retry(3)
    ).toPromise();
  }

  getPfdDiagramById(projectId: string, processId: string, diagramId: string): Observable<{ svg: string }> {
    let url = this.commonService.getUrl(config.getPFDDiagram, [projectId, processId, diagramId]);
    return this.http.get<any>(url).pipe(
      catchError((err) => {
        retry(3)
        if (err && err.error && err.error.message) {
          this.toaster.show('error', err.error.message, '');
        } else {
          this.toaster.show('error', 'An error has occured, please try again.', '');
        }
        return throwError(err);
      })
    );
  }

  getPfdDiagramByIDMock(): Observable<{ svg: string }> {
    let svgData = {
      svg: `<?xml version="1.0" encoding="UTF-8"?>
      <svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink="http://www.w3.org/1999/xlink"
        width="873pt" height="648pt" viewBox="0 0 873 648" version="1.1">
        <g clip-path="url(#clip1)" clip-rule="nonzero"></g>
        <circle cx="200" cy="200" r="40" stroke="green" stroke-width="4" fill="lightblue" />
        <rect x="300" y="200" width="300" height="100" stroke="rgb(0,0,200)" stroke-width="10" fill="rgb(0,150,255)" />
      </svg>`};
    return of(svgData);
  }

  getProcessKPIs(projectId: string, processId: string) {
    let url = this.commonService.getUrl(config.getProcessKPIs, [projectId, processId]);

    return this.http.get<any>(url).toPromise().then((res) => {
      pipe(
        catchError((err) => {
          retry(3);
          if (err && err.error && err.error.message) {
            this.toaster.show('error', err.error.message);
          } else {
            this.toaster.show('error', 'An error has occured: ' + err.status + ' ' + err.statusText + '. Please try again later!', '');
          }
          return throwError(err);
        })
      )

      let created = res.find(x => x.KPIName == "%created");
      let revised = res.find(x => x.KPIName == "%revised");
      let createdDesired = res.find(x => x.KPIName == "%createdDesired");
      let revisedDesired = res.find(x => x.KPIName == "%revisedDesired");

      return [
        {
          'OptimizationId': '',
          'OptimizationName': 'Client desired outcome',
          'KPIValues': [
            { 'KPIType': '%PROCESS FLOW DIAGRAMS (PFDS)', 'KPIValue': Number(createdDesired.KPIValue) },
            { 'KPIType': '%REVISED PFDs', 'KPIValue': Number(revisedDesired.KPIValue) }
          ], // end of kpi-values
        },
        {
          'OptimizationId': '1',
          'OptimizationName': 'Optimization scenario',
          'KPIValues': [
            { 'KPIType': '%PROCESS FLOW DIAGRAMS (PFDS)', 'KPIValue': Number(created.KPIValue) },
            { 'KPIType': '%REVISED PFDs', 'KPIValue': Number(revised.KPIValue) }
          ], // end of kpi-values
        },
      ]
    });
  }
}
