import { catchError, retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, pipe, throwError } from 'rxjs';

import config from '../app-config.json';

import { Process, ProcessInput } from '../models/process';
import { TileInput } from '../models/tile.input';
import { PROCESSES } from '../mock_processes';
import { APPS } from '../mock_prooject_apps';
import { ToasterService } from './toaster.service';
import { CommonService } from './common.service';
import { EquipmentGroup, EquipmentType } from '../components/Layout/models/equipment.model';
import { EquipmentTypeAttribute } from '../models/diagramEquipment';

@Injectable({
  providedIn: 'root'
})
export class ProcessService {
  constructor(
    private commonService: CommonService,
    private http: HttpClient,
    private toaster: ToasterService
  ) { }

  createProcess(process: Process): Observable<{ 'processId': string }> {
    let url = this.commonService.getUrl(config.createProcess, []);

    return this.http.post<{ 'processId': string }>(url, process).pipe(
      catchError((err) => {
        retry(3);
        if (err && err.error && err.error.message) {
          this.toaster.show('error', err.error.message);
        } else {
          this.toaster.show('error', 'An error has occured: ' + JSON.stringify(err) + '. Please try again later!', '');
        }
        return throwError(err);
      })
    );
  }

  getProcessById(projectId: string, processId: string): Observable<Process | any> {
    let url = this.commonService.getUrl(config.getProcessById, [projectId, processId]);

    return this.http.get<Process>(url).pipe(
      catchError((err) => {
        retry(3);
        if (err && err.error && err.error.message) {
          this.toaster.show('error', err.error.message);
        } else {
          this.toaster.show('error', 'An error has occured: ' + err.status + ' ' + err.statusText + '. Please try again later!', '');
        }
        return throwError(err);
      })
    );
  }


  getProcesses(projectId: string, pfdType: string): Observable<Process[] | any> {
    let url = this.commonService.getUrl(config.getProcesses, [projectId], pfdType);

    return this.http.get<Process[]>(url).pipe(
      retry(3),
      catchError((err) => {
        if (err && err.error && err.error.message) {
          this.toaster.show('error', err.error.message);
        } else {
          this.toaster.show('error', 'An error has occured: ' + JSON.stringify(err) + '. Please try again later!', '');
        }
        return throwError(err);
      })
    );
  }

  getProcessInputs(projectId: string, processId: string): Observable<ProcessInput[]> {
    let url = this.commonService.getUrl(config.getProcessInputs, [projectId, processId]);

    return this.http.get<ProcessInput[]>(url).pipe(
      retry(3)
    );
  }

  async getProcessState(projectId: string, processId: string): Promise<any> {
    let url = this.commonService.getUrl(config.getProcessState, [projectId, processId]);

    let result = await this.http.get<any>(url).pipe(
      retry(3)
    ).toPromise();
    return result;
  }

  async setProcessState(projectId: string, processId: string, stateval: number): Promise<any> {
    let url = this.commonService.getUrl(config.setProcessState, [projectId, processId, stateval.toString()]);

    return await this.http.put<any>(url, {}).pipe(
      retry(3)
    ).toPromise();
  }

  updateProcessInputs(process: Process): any {
    let url = this.commonService.getUrl(config.updateProcessInputs, []);

    return this.http.post<any>(url, process).pipe(
      catchError((err) => {
        retry(3);
        if (err && err.error && err.error.message) {
          this.toaster.show('error', err.error.message);
        } else {
          this.toaster.show('error', 'An error has occured: ' + JSON.stringify(err) + '. Please try again later!', '');
        }
        return throwError(err);
      })
    );
  }

  getProcessesMock(): Observable<TileInput[]> {
    // @TODO: retrieve processes from server with HTTP request
    return of(PROCESSES);
  }

  getAPPS(): Observable<TileInput[]> {
    // @TODO: retrieve processes from server with HTTP request
    return of(APPS);
  }

  async startValidation(projectId: string, processId: string) {
    let url = this.commonService.getUrl(config.startValidation, [projectId, processId]);

    return this.http.post<ProcessInput>(url, {}).toPromise().then(() => {
      pipe(
        catchError((err) => {
          retry(3);
          if (err && err.error && err.error.message) {
            this.toaster.show('error', err.error.message);
          } else {
            this.toaster.show('error', 'An error has occured: ' + err.status + ' ' + err.statusText + '. Please try again later!', '');
          }
          return throwError(err);
        })
      )
    });
  }

  async getDataIntegrityValidationStatus(projectId: string, processId: string) {
    let url = this.commonService.getUrl(config.getDataIntegrityValidationStatus, [projectId, processId]);

    return this.http.get<any>(url).pipe(
      retry(3)
    ).toPromise();
  }

  async startPfdGeneration(projectId: string, processId: string) {
    let url = this.commonService.getUrl(config.startPfdGeneration, [projectId, processId]);

    return this.http.post<ProcessInput>(url, {}).toPromise().then(() => {
      pipe(
        catchError((err) => {
          retry(3);
          if (err && err.error && err.error.message) {
            this.toaster.show('error', err.error.message);
          } else {
            this.toaster.show('error', 'An error has occured: ' + err.status + ' ' + err.statusText + '. Please try again later!', '');
          }
          return throwError(err);
        })
      )
    });
  }

  async getPfdGenerationStatus(projectId: string, processId: string) {
    let url = this.commonService.getUrl(config.getPfdGenerationStatus, [projectId, processId]);

    return this.http.get<any>(url).pipe(
      retry(3)
    ).toPromise();
  }

  setPfdDiagramStatus(projectId: string, processId: string, diagramId: string, newState: string) {
    let url = this.commonService.getUrl(config.setPfdDiagramStatus, [projectId, processId, diagramId, newState]);

    return this.http.post<any>(url, {}).pipe(
      catchError((err) => {
        if (err && err.error && err.error.message) {
          this.toaster.show('error', err.error.message, '');
        } else {
          this.toaster.show('error', 'An error has occured, please try again.', '');
        }
        return throwError(err);
      })
    ).toPromise();
  }

  generateFileStorageSASToken(projectId: string, processId: string, filename: string, istemp: string): Promise<any> {
    let url = this.commonService.getUrl(config.getBlobItemSASToken, [projectId, processId, filename], istemp);
    let result = this.http.get<any>(url).pipe(
      retry(3),
      catchError((err) => {
        if (err && err.error && err.error.message) {
          this.toaster.show('error', err.error.message, '');
        } else {
          this.toaster.show('error', 'An error has occured, please try again.', '');
        }
        return throwError(err);
      })
    ).toPromise();
    return result;
  }

  getDownloadFilePathAndSASToken(regFileName: string): Promise<any> {
    let url = this.commonService.getUrl(config.getDownloadFilePathAndSASToken, [regFileName]);
    let result = this.http.get<any>(url).pipe(
      retry(3),
      catchError((err) => {
        if (err && err.error && err.error.message) {
          this.toaster.show('error', err.error.message, '');
        } else {
          this.toaster.show('error', 'An error has occured, please try again.', '');
        }
        return throwError(err);
      })
    ).toPromise();
    return result;
  }

  getPFDDiagramPDFSASToken(projectId: string, processId: string, diagramId: string): Promise<any> {
    let url = this.commonService.getUrl(config.getPFDDiagramPDFSASToken, [projectId, processId, diagramId]);
    let result = this.http.get<any>(url).pipe(
      retry(3),
      catchError((err) => {
        if (err && err.error && err.error.message) {
          this.toaster.show('error', err.error.message, '');
        } else {
          this.toaster.show('error', 'An error has occured, please try again.', '');
        }
        return throwError(err);
      })
    ).toPromise();
    return result;
  }

  /**
* Returns a list with all equipments / or with one queried equipment
*
* @param {string} projectId project Id
* @param {string} processId process Id
* @param {string} diagramId diagram Id
* @param {string} [equipmentQS] equipment name. In case of use the func return 1 equipment. This param
* works as a query string. Ps. use getUrl()->queryString param in order to embed in the url
*
* @return {Observable<EquipmentTypeAttribute[] | EquipmentTypeAttribute>}
*/
  getPFDEquipmentDetails(projectId: string, processId: string, diagramId: string, equipmentQS?: string): Observable<EquipmentTypeAttribute[] | EquipmentTypeAttribute> {
    let url = this.commonService.getUrl(config.getPFDEquipmentDetails, [projectId, processId, diagramId], equipmentQS);

    return this.http.get<EquipmentTypeAttribute[] | EquipmentTypeAttribute>(url).pipe(
      catchError((err) => {
        if (err && err.error && err.error.message) {
          this.toaster.show('error', err.error.message);
        } else {
          this.toaster.show('error', 'An error has occured. Please try again later!', '');
        }
        return throwError(err);
      })
    );
  }


  async startELOEquipmentValidation(projectId: string, processId: string) {
    let url = this.commonService.getUrl(config.startELOEquipmentValidation, [projectId, processId]);

    return this.http.post<ProcessInput>(url, {}).toPromise().then(() => {
      pipe(
        catchError((err) => {
          if (err && err.error && err.error.message) {
            this.toaster.show('error', err.error.message);
          } else {
            this.toaster.show('error', 'An error has occured: ' + err.status + ' ' + err.statusText + '. Please try again later!', '');
          }
          return throwError(err);
        })
      )
    });
  }

  async getELOEquipmentValidationStatus(projectId: string, processId: string) {
    let url = this.commonService.getUrl(config.getELOEquipmentValidationStatus, [projectId, processId]);

    return this.http.get<any>(url).toPromise();
  }

  getELOEquipments(projectId: string, processId: string): Promise<EquipmentType[]> {
    let url = this.commonService.getUrl(config.getELOEquipments, [projectId, processId]);

    let result = this.http.get<any>(url).pipe(
      catchError((err) => {
        if (err && err.error && err.error.message) {
          this.toaster.show('error', err.error.message, '');
        } else {
          this.toaster.show('error', 'An error has occured, please try again.', '');
        }
        return throwError(err);
      })
    ).toPromise();
    return result;
  }

  getELOGroups(projectId: string, processId: string): Promise<EquipmentGroup[]> {
    let url = this.commonService.getUrl(config.getELOGroups, [projectId, processId]);
    let result = this.http.get<EquipmentGroup[]>(url).pipe(
      catchError((err) => {
        if (err && err.error && err.error.message) {
          this.toaster.show('error', err.error.message, '');
        } else {
          this.toaster.show('error', 'An error has occured with equipment groups, please try again.', '');
        }
        return throwError(err);
      })
    ).toPromise();
    return result;
  }


  createELOGroup(projectId: string, processId: string, equipmentGroup: EquipmentGroup) {
    let url = this.commonService.getUrl(config.createELOGroup, [projectId, processId]);
    const eqGroup = {
      GroupName: equipmentGroup.GroupName,
      Equipments: equipmentGroup.Equipments.map(x => x.EquipmentId),  // alter equipment list
      MaintenanceClearanceDimensions: equipmentGroup.MaintenanceClearanceDimensions,
      OperationClearanceDimensions: equipmentGroup.OperationClearanceDimensions,
      AllowedDimensions: equipmentGroup.AllowedDimensions
    }
    return this.http.post<any>(url, eqGroup).pipe(
      catchError((err) => {
        return throwError(err);
      })
    ).toPromise();
  }

  updateELOGroup(projectId: string, processId: string, equipmentGroup: EquipmentGroup) {
    let url = this.commonService.getUrl(config.updateELOGroup, [projectId, processId]);
    const eqGroup = {
      GroupId: equipmentGroup.GroupId,
      GroupName: equipmentGroup.GroupName,
      Equipments: equipmentGroup.Equipments.map(x => x.EquipmentId),  // alter equipment list
      MaintenanceClearanceDimensions: equipmentGroup.MaintenanceClearanceDimensions,
      OperationClearanceDimensions: equipmentGroup.OperationClearanceDimensions,
      AllowedDimensions: equipmentGroup.AllowedDimensions
    }
    return this.http.put<any>(url, eqGroup).pipe(
      catchError((err) => {
        return throwError(err);
      })
    ).toPromise();
  }

  deleteELOGroup(groupId: string) {
    let url = this.commonService.getUrl(config.deleteELOGroup, [groupId]);

    return this.http.post<any>(url, {}).pipe(
      catchError((err) => {
        return throwError(err);
      })
    ).toPromise();
  }

  getELO3DModelSASTokenAndPath(projectId: string, processId: string, modelId: string): Promise<any> {
    let url = this.commonService.getUrl(config.getELOOptimizedModelsSASToken, [projectId, processId, modelId]);
    let result = this.http.get<any>(url).pipe(
      catchError((err) => {
        if (err && err.error && err.error.message) {
          this.toaster.show('error', err.error.message, '');
        } else {
          this.toaster.show('error', 'An error has occured, please try again.', '');
        }
        return throwError(err);
      })
    ).toPromise();
    return result;
  }

  sendFileToBlob(projectId: string, processId: string, file: any): Observable<any> {
    let url = this.commonService.getUrl(config.startGBOMFileUpload, [projectId, processId, file.name]);
    const formData: any = new FormData(); 
    formData.append(file.name, file); 

    let result = this.http.put<{}>(url, formData).pipe(
      catchError((err) => {
        console.error('Failed to send File into Blob storage', err);
        return throwError(err);
      })
    )
    return result;
  }

  getGBOMMaterials(processId: string): Observable<any> {
    let url = this.commonService.getUrl(config.getGBOMMaterials, [processId]);

    // used for local json responses
    //let url = '../../assets/rawdata.json';

    return this.http.get<any>(url).pipe(
      catchError((err) => {
        retry(3);
        if (err && err.error && err.error.message) {
          this.toaster.show('error', err.error.message);
        } else {
          this.toaster.show('error', 'An error has occured: ' + err.status + ' ' + err.statusText + '. Please try again later!', '');
        }
        return throwError(err);
      })
    );
  }

  getVinciBOM(): Observable<any> {

    // used for local json responses
    let url = '../../assets/VinciMaterialsCleansed.json';

    return this.http.get<any>(url).pipe(
      catchError((err) => {
        retry(3);
        if (err && err.error && err.error.message) {
          this.toaster.show('error', err.error.message);
        } else {
          this.toaster.show('error', 'An error has occured: ' + err.status + ' ' + err.statusText + '. Please try again later!', '');
        }
        return throwError(err);
      })
    );
  }

  async getGbomMaterialsStatus(projectId: string, processId: string, MessageId: string) {
    let url = this.commonService.getUrl(config.getGBOMStatusEndpoint, [projectId, processId, MessageId]);

    return this.http.get<any>(url).toPromise();
  }

  startGBOMDesignAutomation(projectId: string, processId: string, fileName: string) {
    let url = this.commonService.getUrl(config.startGBOMDesignAutomation, [projectId, processId, fileName]);

    return this.http.post<{}>(url, {}).pipe(
      catchError((err) => {
        if (err && err.error && err.error.message) {
          this.toaster.show('error', err.error.message);
        } else {
          this.toaster.show('error', 'An error has occured: ' + err.status + ' ' + err.statusText + '. Please try again later!', '');
        }
        return throwError(err);
      })
    )
  }

  getGBOMFileDownloadSASTokenAndPath(projectId: string, processId: string, fileName: string): Promise<any> {
    let url = this.commonService.getUrl(config.getGBOMFileDownloadSASToken, [projectId, processId, fileName]);
    let result = this.http.get<any>(url).pipe(
      catchError((err) => {
        if (err && err.error && err.error.message) {
          this.toaster.show('error', err.error.message, '');
        } else {
          this.toaster.show('error', 'An error has occured, please try again.', '');
        }
        return throwError(err);
      })
    ).toPromise();
    return result;
  }


}
