import { Component, OnDestroy, OnInit } from '@angular/core';
import { Toast } from 'src/app/models/toast';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-toaster-container',
  templateUrl: './toaster-container.component.html',
  styleUrls: ['./toaster-container.component.scss']
})
export class ToasterContainerComponent implements OnInit, OnDestroy {
  sub:any;
  toasts: Toast[] = [];

  constructor(private toaster: ToasterService) {}

  ngOnInit() {
    this.toaster.toast$
      .subscribe(toast => {
        this.toasts = [...this.toasts, toast];
        if (toast.delay !== -1) {
          setTimeout(() => {
            this.toasts = this.toasts.filter(v => v !== toast);
          }, toast.delay || 10000);
        }
      });
  }

  remove(index: number) {
    this.toasts.splice(index, 1);
  }

  delete(toast: Toast) {
    this.toasts = this.toasts.filter(v => v !== toast);
  }

  ngOnDestroy(){
    this.toaster.unsubscribe();
  }
}
