<div class="mainHolder">
    <div>
        <div class="backButton">
            <a [routerLink]="['/', 'industry', category]">
                <span class="leftArrow-background"> </span>
                <span class="backToHome">Back</span>
            </a>
        </div>
        <div class="tile">
            <div class="tileTitle">
                <div>{{selectedIndustryName}}</div>
                <img *ngIf="selectedIndustryImg" src="./../../../../../assets/imgs/industry/{{selectedIndustryImg}}" alt="">
            </div>
            <div class="tileCategory">
                <img class="arrow" src="./../../../../../assets/imgs/rightArrow.png" alt="">
                <ul class="tileCategoryList">
                    <li *ngFor="let subcategory of subcategories; let i=index"
                        [style.color]=subcategory.color
                        (click)="getData(subcategory.key, subcategory.color)">
                        {{subcategory.name | uppercase}}
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="subcaseHolder">
        <div class="categoryTitle">
            <span> {{cases.name}} USE CASES</span>
        </div>
        <div class="subCases">
            <ul>
                <li *ngFor="let case of baseSubCases">
                    <a (click)="openUsecaseDialog(case.key)" class="openModal">
                        <span 
                            [style.visibility]="case.hasVideo ? 'visible' : 'hidden'"
                            class="playButton-background">
                        </span>
                        <span class="alignToStartIcon">{{ usecasesData[case.key].name }}</span>
                    </a>
                    <ul>
                        <li *ngFor="let c of case.subcases">
                            <a (click)="openUsecaseDialog(c.key)" class="openModal">
                                <span 
                                    [style.visibility]="c.hasVideo ? 'visible' : 'hidden'"
                                    class="playButton-background">
                                </span>
                                <span class="alignToStartIcon">{{ usecasesData[c.key].name }}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <div *ngIf="emptyList" class="categoryTitle">
            <span> RELATED USE CASES</span>
        </div>
        <div *ngIf="emptyList" class="subCases">
            <ul>
                <li *ngFor="let case of relatedSubCases">
                    <a (click)="openUsecaseDialog(case.key)" class="openModal">
                        <span 
                            [style.visibility]="case.hasVideo ? 'visible' : 'hidden'"
                            class="playButton-background">
                        </span>
                        <span class="alignToStartIcon">{{ usecasesData[case.key].name }}</span>
                    </a>
                    <ul>
                        <li *ngFor="let c of case.subcases">
                            <a (click)="openUsecaseDialog(c.key)" class="openModal">
                                <span 
                                    [style.visibility]="c.hasVideo ? 'visible' : 'hidden'"
                                    class="playButton-background">
                                </span>
                                <span class="alignToStartIcon">{{ usecasesData[c.key].name }}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
