import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { ButtonComponent } from './button/button.component';
import { BreadcrumbComponent } from './breadcrumb/component/breadcrumb.component';
import { DropzoneDirective } from 'src/app/dropzone.directive';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { LoadingComponent } from './loading/loading.component';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { TileComponent } from './tile/tile.component';
import { ToasterComponent } from './toaster/toaster/toaster.component';
import { ToasterContainerComponent } from './toaster/toaster-container/toaster-container.component';


@NgModule({
  declarations: [
    ButtonComponent,
    BreadcrumbComponent,
    DropzoneDirective,
    FileUploadComponent,
    LoadingComponent,
    TileComponent,
    ToasterComponent,
    ToasterContainerComponent,
    RangeSliderComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    RouterModule,
    NgxSliderModule
  ],
  exports: [
    ButtonComponent,
    BreadcrumbComponent,
    DropzoneDirective,
    FileUploadComponent,
    LoadingComponent,
    TileComponent,
    ToasterComponent,
    ToasterContainerComponent,
    RangeSliderComponent,
  ]
})
export class ReusableComponentsModule { }
