<div class="begin-page" *ngIf="project">
  <h1>{{project.ProjectName}}</h1>
  <p class="half-width">{{project.ProjectDetails}}</p>
  <p>Start Date: {{project.StartDate | date: 'MM/dd/yyyy'}}</p>
  <p>End Date: {{project.EndDate | date: 'MM/dd/yyyy'}}</p>
  <p>Location: {{project.Country}}, {{project.Region}}, {{project.State}}, {{project.City}}</p>
  <p>Created on: {{project.CreateTimeStamp | date: 'medium'}}</p>
</div>

<div class="container-fluid grey-container">
  <div class="row">
    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let process of tileInputs">
      <app-tile [tileInput]="process"
                [buttonText]="'OPEN ' + (process.name | uppercase)"
                [targetPath]="'automatedfeed'">
      </app-tile>
    </div>
  </div>
</div>
