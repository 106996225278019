import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
   //It is advised to not pass on the actual Exception trace or Underlying code details in error message. i.e {err => console.error(err)}
   .catch(err => console.error('Unexpected Error occurred. Please try again later'));
